.pagination-special {
    .page-item:not(:first-child):not(:last-child):not(.disabled) {
        .page-link {
            font-weight: $font-weight-bold;

            &::after{
                @include position(absolute, auto, $pagination-padding-x, 0.4rem, $pagination-padding-x);
                @include opacity(0);

                transform: scaleX(0);
                transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                content: '';
                border-bottom: 2px solid $dark;
            }
        }

        &.active,
        &:hover,
        &:focus {
            .page-link {
                &::after{
                    @include opacity(1);
                    transform: scaleX(1);
                }
            }
        }
    }

    .page-item {
        .page-link {
            border: 0;

            &:hover,
            &:focus {
                background-color: transparent;
                color: $gray-800;
            }
        }

        &:first-child {
            margin-right: 1.5rem;
        }

        &:last-child {
            margin-left: 1.5rem;
        }

        &:first-child,
        &:last-child {
            .page-link {
                @include square(3.2rem);

                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $light;
                border-radius: 50%;
                font-size: 2rem;
                padding: 0;
                transition: $transition-base;

                &.page-link-light {
                    background-color: $light;
                }

                &.page-link-white {
                    background-color: $white;
                }

                &:hover,
                &:focus {
                    background-color: theme-color('primary');
                    color: $white;
                }
            }
        }
    }

    &.pagination-sm {
        .page-item:not(:first-child):not(:last-child) {
            .page-link::after {
                @include position(absolute, auto, $pagination-padding-x-sm, 0, $pagination-padding-x-sm);
            }
        }
    }
    &.pagination-lg {
        .page-item:not(:first-child):not(:last-child) {
            .page-link::after {
                @include position(absolute, auto, $pagination-padding-x-lg, 0.6rem, $pagination-padding-x-lg);
            }
        }
    }
}

.pager {
    ul {
        list-style-type: none;

        li {
            display: inline-block;

            a, span {
                display: inline;
                padding: .5rem;
            }

			&.current-page {
				color: $green;
				text-decoration:underline;
			}

        }
    }
}