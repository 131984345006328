.slick-slider {
    position: relative;

    .slick-track {
        margin-left: 0;
        margin-right: 0;
    }

    .slider-item {
        @include center-cover();

        position: relative;
        height: auto;
        outline: none;
    }

    .slick-dots {
        list-style: none;
        padding: 0;
        margin: 10px 0 0 0;
        text-align: center;

        li {
            @include square(10px);

            display: inline-block;
            margin: 0 5px;

            button {
                @include square(10px);
                @include border-radius(50%);

                appearance: none;
                outline: 0;
                border: 0;
                padding: 0;
                font-size: 0;
            }
        }
    }
}
