.part {
  .part-thumbnail {
    width: 25%;
    float: left;
    padding: 1rem;

    img {
      width: 100%;
    }

    img.active {
      filter: grayscale(100%);
    }
  }
}

.part-image-big {
  width: 60%;
  margin: auto;
  padding: 1rem;

  img {
    width: 100%;
  }
}

.overlay {
	position: absolute;
	width: 120px;
	bottom: 0;
	text-align: center;
}
	.overlay.winter {		
		color: white;
	}
	.overlay.summer {
		color: black;
	}