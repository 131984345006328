@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $size, $length in $spacers {
      .cnt-spacing#{$infix}-#{$size} {
        > * {
          margin: {
            top: $length;
            bottom: $length;
          };

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}