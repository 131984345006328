/** Import Bootstrap functions */
@import "../../bower_components/bootstrap/scss/functions";

// Color system

$white:    #fff; // Headings in invert mode
$gray-100: #f2f4f5;
$gray-200: #f2f4f5;
$gray-300: #f4f4f4; // Gray section, borders
$gray-400: #dedede; // text in invert mode
$gray-500: #adb5bd; // breadcrums, in header borders
$gray-600: #8d8d8d; // breadcrums, bil utrustning, år & kvalitet, lager nr: , page sizes buttons 
$gray-700: #666; // text color default, sub menu items on search, pagination
$gray-800: #303a42; // Naigation
$gray-900: #1b1b1b; // Headings
$black:    #000;
$gold:     #f8c350;


$blue:		 #2b7fc2;
$red:		 #d54233;
$orange:	 #ea862b;
$yellow:	 #ffd52f;
$green:		 #7eBa27;
$lightgreen: #e7f3d6;
$teal:       #20c997;
$cyan:       #17a2b8;
$secondary:  #131313;

$theme-colors: (
	primary: $green, 
	secondary: $secondary, 
    success: $green,
    info: $cyan,
    warning: $orange,
    danger: $red,
    light: $gray-100,
    dark: $gray-900,
	accent: $gold,
	light-primary: $lightgreen,
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900;
$yiq-text-light:            $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true;
$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// stylelint-disable
$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 7)
), $spacers);

// Body
//
// Settings for the `<body>` element.

$body-bg:                       $white;
$body-color:                    $gray-700;

// Links
//
// Style anchor elements.c

$link-color:                theme-color("secondary");
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;

// Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:              12;
$grid-gutter-width:         map-get($spacers, 5);
$container-padding:         $grid-gutter-width / 2;

// Components
//
// Define common padding and border radius sizes and more.

$box-shadow-sm:               0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow:                  0 0 1rem 0.3rem rgba($black, 0.04);
$box-shadow-lg:               0 1rem 3rem rgba($black, 0.175);

$component-active-color:      $white;
$component-active-bg:         theme-color("primary");

$border-width:                2px;
$border-color:                 $gray-300;

$border-radius:               0;//.4rem;
$border-radius-lg:            0;//.5rem;
$border-radius-sm:            0;//.35rem;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "verdana", sans-serif;
$font-family-alternative:     "BrandonGrotesque-Bold", sans-serif;
$font-family-secondary:       "open sans", sans-serif;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `18px` 
$font-size-lg:                ($font-size-base * 1);
$font-size-sm:                ($font-size-base * 0.9);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semi-bold:       600;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.3;
$h2-font-size:                $font-size-base * 1.8;
$h3-font-size:                $font-size-base * 1.6;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.2;
$h6-font-size:                $font-size-base;

$h1-font-size-xs:             $font-size-base * 1.8;
$h2-font-size-xs:             $font-size-base * 1.5;
$h3-font-size-xs:             $font-size-base * 1.4;
$h4-font-size-xs:             $font-size-base * 1.3;
$h5-font-size-xs:             $font-size-base * 1.1;
$h6-font-size-xs:             $font-size-base;

$headings-font-family:        inherit;
$headings-font-weight:        $font-weight-bold;
$headings-line-height:        1.2;
$headings-color:              $gray-900;
$list-inline-padding:         map-get($spacers, 3);

$blockquote-font-size:        $font-size-base;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-focus-width:       0;
$input-btn-focus-color:       transparent;
$input-btn-focus-box-shadow:  none;
$input-btn-border-width:      2px;

$input-btn-padding-y-sm:      0.7rem;
$input-btn-padding-x-sm:      1.3rem;

$input-btn-padding-y:         0.8rem;
$input-btn-padding-x:         2.1rem;

$input-btn-padding-y-lg:      1.4rem;
$input-btn-padding-x-lg:      1.9rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;

$btn-padding-y-sm:            0.5rem;
$btn-padding-x-sm:            1.55rem;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;

$btn-font-weight:             $font-weight-bold;
$btn-focus-box-shadow:        none;
$btn-box-shadow:              none;
$btn-active-box-shadow:       none;
$btn-focus-width:             0;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-border-width:             1px;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-padding-y:                0;
$dropdown-border-width:             1px;
$dropdown-divider-bg:               $border-color;

$dropdown-link-color:               $gray-900;
$dropdown-link-hover-color:         $component-active-color;
$dropdown-link-hover-bg:            $component-active-bg;

$dropdown-link-active-color:        $component-active-color;
$dropdown-link-active-bg:           $component-active-bg;

$dropdown-item-padding-y:           .5rem;
$dropdown-item-padding-x:           1.5rem;

// Forms

$input-group-addon-color:               $white;
$input-group-addon-bg:                  $blue;
$input-group-addon-border-color:        transparent;
$form-group-margin-bottom:              map-get($spacers, 4);
$input-focus-border-color:              $component-active-bg;

$custom-select-border-width:            $border-width;
$custom-select-color:                   $gray-900;
$custom-select-focus-box-shadow:        none;


// Navbar

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;

$navbar-nav-link-padding-x:         map-get($spacers, 5);

$navbar-toggler-padding-y:          0;
$navbar-toggler-padding-x:          0;
$navbar-toggler-border-radius:      0;

$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          theme-color('primary');
$navbar-dark-disabled-color:        rgba($white, 0.25);
$navbar-dark-toggler-border-color:  transparent;

$navbar-light-color:                $gray-800;
$navbar-light-hover-color:          $gray-800;
$navbar-light-active-color:         theme-color('primary');
$navbar-light-disabled-color:       rgba($gray-800, 0.3);
$navbar-light-toggler-border-color: transparent;

$nav-divider-margin-y: 0;

// Cards

$card-bg:                           transparent;
$card-border-width:                 0;
$card-border-radius:                0;

// Breadcrumbs

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           map-get($spacers, 2);
$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:			$green; 
$breadcrumb-active-color:			$green;
$breadcrumb-border-radius:          0;
$breadcrumb-divider:				"\2022"; /*bull*/


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-sm:       0;
$table-border-width:          0;

// Pagination

$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y:              .5rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1rem;

$pagination-color:                  $gray-800;
$pagination-bg:                     transparent;
$pagination-border-width:           1px;

$pagination-hover-color:            $gray-200;
$pagination-hover-bg:               $gray-800;
$pagination-hover-border-color:     $gray-800;

$pagination-active-color:           $gray-800;
$pagination-active-bg:              transparent;
$pagination-active-border-color:    transparent;

$pagination-disabled-color:         $gray-800;
$pagination-disabled-bg:            transparent;
$pagination-disabled-border-color:  $gray-800;


//Images
$header-background-image: url('../images/front-bakground-image.jpg');

$search-codekind-car: url('/Themes/DefaultClean/dist/images/carsmall.png');
$search-codekind-mc: url('/Themes/DefaultClean/dist/images/mc.png');
$search-codekind-rv: url('/Themes/DefaultClean/dist/images/rv.png');
$search-codekind-truck: url('/Themes/DefaultClean/dist/images/truck.png');
$search-codekind-tire: url('/Themes/DefaultClean/dist/images/tire.png');
$search-codekind-trailer: url('/Themes/DefaultClean/dist/images/trailer.png');

$search-bar-image: url('/Themes/DefaultClean/dist/images/search.png');

