.image-slider-nav {
    .slick-list {
        margin: 0 spacers(1) * -1;
    }

    .slick-slide {
        margin: 0 spacers(1);
    }

    .slider-item {
        cursor: pointer;
    }
}
