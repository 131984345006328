#dialog-notifications-success,
#dialog-notifications-error,
#dialog-notifications-warning {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  color: black;
  background-color: white;
  background-image: url('/Themes/DefaultClean/Content/images/loading.gif');
  border-style: solid black;
}