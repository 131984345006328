.footer {
	.footer-logo {
		display: flex;
		flex: 0 0 180px;
		background-image: url('../images/logotype.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: left center;
		height: 45px;
	}

	.footer-klarna-logo {
		display: flex;
		flex: 0 0 180px;
		background-image: url('../images/klarna.png');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: left center;
		height: 45px;
		width: 75px;
	}

	.nav-link {
		padding: 0.3rem 0;
	}

	&.section {
		padding: 0;
		border-top: theme-color('secondary') solid 3px;
		margin-top: 2rem;
	}

	.footer-small-columns {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.footer-large-column {
		.bg-secondary {
			height: 100%;
			padding: 3rem;
		}
	}

	.col-sm-12 {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		font-size: 90%;
	}

	@include media-breakpoint-down(sm) {
		.col-sm-4 {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}

		.bg-secondary {
			padding-top: 3rem;
			padding-bottom: 3rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.footer-small-columns {
			padding-top: 1rem;
			padding-bottom: 0;
		}

		.footer-large-column {
			.bg-secondary {
				height: auto;
				padding: 1rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.no-gutters {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
