.decor-module {
    .column {
        > * {
            position: relative;
            z-index: 10;
        }

        &::after {
            content: "";
            width: 5000px;
            height: 5rem;
            background-color: #eee;
            position: absolute;
            top: 2.4rem;
            left: -2500px;
            z-index: 0;
            display: none;
        }

        &::before {
            content: "";
            width: 5000px;
            height: 1px;
            position: absolute;
            top: 5rem;
            left: -2500px;
            border-top: 2px dashed $gray-400;
            z-index: 1;
            display: none;
        }

        &:nth-of-type(2) {
            &::after,
            &::before {
                display: block;
            }
        }

        @include media-breakpoint-down(sm) {
            &::after,
            &::before {
                display: block;
            }
        }
    }
}
