.header {
	width: 100%;
	height: auto;
	z-index: 100;
	transition: all 0.3s ease;
	background-image: $header-background-image;
	background-position: top;
	background-repeat: no-repeat;

	&.fixed-menu-open {
		transform: translate3d(-280px, 0px, 0px);
	}

	.topheaderlinks {
		background-color: #333;

		.container {
			padding: 0.5rem 1rem;
		}

		.divider {
			padding: 0 0.5rem;
		}

		.topheaderlinksinner {
			display: inline-block;
		}

		.topheaderemailphone {
			display: inline-block;
			float: right;

			i {
				margin-right: 3px;
			}

			a {
				margin-left: 10px;
			}
		}
	}

	.container {
		max-width: breakpoint-min('xl', $grid-breakpoints);
		padding: 2rem 1rem 3rem 3rem;

		@include media-breakpoint-down(sm) {
			padding: 1rem;
		}

		@include media-breakpoint-down(xs) {
			padding-left: 0.5rem;
			padding-top: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		.logo {
			flex: 0 0 180px;
			background-image: $logo-image;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: left center;

			@include media-breakpoint-down(sm) {
				flex: 0 0 160px;
			}

			@include media-breakpoint-down(xs) {
				flex: 0 0 120px;
			}
		}

		.navbar {
			letter-spacing: 2px;

			.navbar-nav {
				display: flex;
				flex-direction: row;
				width: 100%;

				.nav-item {
					margin-left: 0;
					display: block;
					float: none;

					.nav-link {
						position: relative;
						white-space: nowrap;
						font-size: 0.9rem;
						text-transform: uppercase;

						&::before {
							@include position(absolute, auto, $navbar-nav-link-padding-x, 0, $navbar-nav-link-padding-x);
							@include opacity(0);
							transform: scaleX(0);
							transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
							content: '';
							border-bottom: 2px solid $gray-800;
						}
					}

					.dropdown-menu {
						.nav-link {
							@extend .dropdown-item;
							text-transform: none;
						}
					}
				}

				> .nav-item:not(.dropdown) {
					&.active,
					&:hover,
					&:focus {
						.nav-link {
							&::before {
								@include opacity(1);
								transform: scaleX(1);
							}
						}
					}

					&.active {
						.nav-link {
							color: $gray-800;

							&::before {
								border-color: theme-color('primary');
							}
						}
					}
				}
			}
		}

		.right-nav {
			.nav-link {
				text-transform: uppercase;

				.icon {
					font-size: 165%;
					vertical-align: middle;
					line-height: 1;
				}

				.nav-link-text {
					padding-left: spacers(2);
				}

				span {
					vertical-align: middle;
				}

				&.separator {
					width: 2px;
					height: 100%;
					background-color: $border-color;
					padding: 0;
				}
			}
		}

		#navbar-toggler {
			cursor: pointer;
			padding: 0.5rem 1rem;
			height: 2.8rem;
			margin-left: 0.2rem;

			span {
				height: 1.5rem;
				width: 1.5rem;
				line-height: 1.5rem;
			}
		}
	}

	#searchContainer {
		.container {
			padding-top: 40px;
			max-width: 1200px;
			padding-left: 1rem;
			padding-right: 1rem;
			margin-left: auto;
			margin-right: auto;
		}

		.module:last-of-type {
			padding-bottom: 1rem;
		}
	}

	.shopping-cart {
		position: relative;
		line-height: 1rem;
		height: 2.8rem;

		.cart-qty {
			position: absolute;
			background-color: theme-color('accent');
			padding: 0px 5px;
			border-radius: 50%;
			font-size: 11px;
			font-weight: 600;
			top: 3px;
			left: 25px;
			z-index: 1;
			line-height: 1.5;
		}
	}

	.header-contacts {
		max-width: 1200px;
		padding: 0 1rem 0 3rem;
		margin-left: auto;
		margin-right: auto;
		font-size: 18px;

		a:first-of-type {
			float: left;
		}

		a:last-of-type {
			float: right;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 1rem 0 1rem;
		}

		@include media-breakpoint-down(xs) {
			padding: 0 0 0 0.5rem;
		}
	}
}
