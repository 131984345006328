.verdana {
    font-family: $font-family-sans-serif;
}

.brandon-grotesque {
    font-family: $font-family-alternative;
}

.open-sans {
    font-family: $font-family-secondary;
}