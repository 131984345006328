h5 {
    font-weight: 600;
    color: theme-color(primary);
}

@include media-breakpoint-down(xs) {
    h1 {
        font-size: $h1-font-size-xs;
    }

    h2 {
        font-size: $h2-font-size-xs;
    }

    h3 {
        font-size: $h3-font-size-xs;
    }

    h4 {
        font-size: $h4-font-size-xs;
    }

    h5 {
        font-size: $h5-font-size-xs;
    }

    h6 {
        font-size: $h6-font-size-xs;
    }
}

label {
    color: $headings-color;
    font-size: 0.9rem;
}

blockquote {
    padding: spacers(4) spacers(4) spacers(4) spacers(6);
    position: relative;
    font-style: italic;
    background-color: $gray-300;

    &:before {
        @include position(absolute, spacers(2), auto, auto, spacers(2));
        font-family: "Georgia";
        content: "“";
        vertical-align: middle;
        color: $gray-400;
        font-size: 4rem;
        line-height: 1;
    }
}

.uppercase {
    text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6, .btn {
	text-transform: uppercase;
}