.horizontal-overflow {
    position: relative;

    &:before, &:after {
		width: 50px;
		height: 100%;
        @include position(absolute, auto, auto, 0, auto);
        pointer-events: none;
        z-index: 10;
        transition: opacity 0.4s ease-in-out;
        -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        line-height: 100%;
        color: $black;
        display: flex;
        align-items: center;
        opacity: 1;
    }

    &:before {
        left: -#{$container-padding};
        content: '<';
        text-align: left;
        background: linear-gradient(to left, hsla(0, 0%, 100%, 0), $white 68%);
        padding-left: 0.5rem;
    }

    &:after {
        right: -#{$container-padding};
        content: '>';
        text-align: right;
        background: linear-gradient(to left, $white 32%, hsla(0, 0%, 100%, 0));
        justify-content: flex-end;
        padding-right: 0.5rem;
    }

    &.left-end {
        &:before { 
            opacity: 0;
        }
    }

    &.right-end {
        &:after { 
            opacity: 0;
        }
    }

    > ul {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        justify-content: unset;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track {
            display: none; 
        }

        li {
            margin-bottom: 0;
            flex-shrink: 0;

            &:first-child {
                a {
                    padding-left: 0 !important;
                }
            }
            &:last-child {
                margin-right: 1rem;
                a {
                    padding-right: 1rem;
                }
            }
        }
    }
}