:root,
html {
	font-size: $font-size-base;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;

	@include media-breakpoint-down(sm) {
		font-size: $font-size-sm;
	}
}

body {
	padding: 0;
	margin: 0;
	color: $gray-800;
}

.browserhappy {
	width: 100%;
	height: auto;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: $red;
	color: $white;
	border: 0;
	text-align: center;
	padding: 2rem 1rem;
	margin-bottom: 0;
	border-radius: 0;
	z-index: 110;
}

.wrapper {
	overflow: hidden;
	min-width: 320px;
}

.container {
	max-width: breakpoint-min('xl', $grid-breakpoints);
	width: 100%;

	&.container-md {
		max-width: breakpoint-max('md', $grid-breakpoints);
	}

	&.container-sm {
		max-width: breakpoint-max('sm', $grid-breakpoints);
	}

	&.container-xs {
		max-width: breakpoint-max('xs', $grid-breakpoints);
	}
}

.bar-notification {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	padding: 15px 25px 15px 10px;
	line-height: 16px;
	color: #fff;
	opacity: 0.95;
}

.bar-notification.success {
	background-color: #4bb07a;
}

.bar-notification.error {
	background-color: #e4444c;
}

.bar-notification.warning {
	background-color: #f39c12;
}

.bar-notification .content {
	margin: 0 10px 0 0;
}

.bar-notification .content a {
	color: #fff;
	text-decoration: underline;
}

.bar-notification .close {
	position: absolute;
	top: 0;
	right: 0;
	width: 32px;
	height: 32px;
	margin: 7px;
	background: #fff url('../images/close.png') center no-repeat;
	cursor: pointer;
}

.noscript {
	border-bottom: 1px solid #333;
	background-color: #ff9;
	padding: 30px 15px;
	text-align: center;
	line-height: 22px;
	color: #444;
}

.please-wait {
	background: url('../images/ajax-loader-small.gif') no-repeat;
	padding-left: 20px;
	font-size: 14px;
}

.ui-dialog {
	max-width: 90%;
	border: 1px solid #ddd;
	box-shadow: 0 0 2px rgba(0,0,0,0.15);
	overflow: hidden;
	background-color: #fff;
	/*override jQuery UI styles, do not delete doubled properties*/
	border-radius: 0;
	padding: 0;
	font: normal 14px Arial, Helvetica, sans-serif;
}

.ui-dialog:before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
}

.ui-dialog-titlebar {
	border-bottom: 1px solid #ddd;
	overflow: hidden;
	background-color: #eee;
	padding: 10px 15px;
	/*override jQuery UI styles, do not delete doubled properties*/
	border-width: 0 0 1px;
	border-radius: 0;
	background-image: none;
	padding: 10px 15px !important;
	font-weight: normal;
	cursor: auto !important;
}

.ui-dialog-titlebar > span {
	float: left;
	font-size: 18px;
	color: #444;
	/*override jQuery UI styles, do not delete doubled properties*/
	margin: 0 !important;
}

.ui-dialog-titlebar button {
	position: absolute;
	top: 0;
	right: 0;
	width: 42px;
	height: 42px;
	border: none;
	overflow: hidden;
	background: url('../images/close.png') center no-repeat;
	font-size: 0;
	/*override jQuery UI styles, do not delete doubled properties*/
	top: 0 !important;
	right: 0 !important;
	width: 42px !important;
	height: 42px !important;
	margin: 0 !important;
	border: none !important;
	border-radius: 0;
	background: url('../images/close.png') center no-repeat !important;
	padding: 0 !important;
}

.ui-dialog-titlebar button span {
	display: none !important;
}

.ui-dialog-content {
	padding: 15px;
	line-height: 20px;
	/*override jQuery UI styles, do not delete doubled properties*/
	background-color: #fff !important;
	padding: 15px 15px 20px 15px !important;
	color: #777;
}

.ui-dialog-content .page {
	min-height: 0;
}

.ui-dialog-content .page-title {
	min-height: 0;
	margin: 0 0 15px;
	padding: 0px 10px 10px 10px;
	text-align: center;
}

.ui-dialog-content .page-title h1 {
	font-size: 24px;
	line-height: 30px;
}

.ui-dialog-content .back-in-stock-subscription-page {
	text-align: center;
}

.ui-dialog-content .back-in-stock-subscription-page .tooltip {
	margin-bottom: 10px;
}

.ui-dialog-content .back-in-stock-subscription-page .button-1 {
	border: none;
	background-color: #4ab2f1;
	padding: 10px 15px;
	font-size: 15px;
	color: #fff;
	text-transform: uppercase;
}

.ui-dialog-content .back-in-stock-subscription-page .button-1:hover {
	background-color: #248ece;
}

.eu-cookie-bar-notification {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1050;
	width: 320px;
	height: 180px;
	margin: -90px 0 0 -160px;
	border: 1px solid #ccc;
	box-shadow: 0 0 2px rgba(0,0,0,0.15);
	background-color: #fff;
	padding: 20px;
	text-align: center;
}

.eu-cookie-bar-notification .text {
	margin-bottom: 20px;
	line-height: 20px;
}

.eu-cookie-bar-notification button {
	min-width: 60px;
	margin: 5px 0 10px;
	border: none;
	background-color: #4ab2f1;
	padding: 8px 12px;
	font-size: 14px;
	color: #fff;
}

.eu-cookie-bar-notification button:hover {
	background-color: #248ece;
}

.eu-cookie-bar-notification a {
	display: block;
	color: #4ab2f1;
}

.eu-cookie-bar-notification a:hover {
	text-decoration: underline;
}

.hidden {
	display: none;
}

.hover-pointer:hover {
	cursor: pointer;
}

.pager {
	width: 100%;
	text-align: right;
	padding: 5px 0;

	ul {
		margin-bottom: 0;
	}
}

.no-margins {
	margin: 0;
}

hr {
	height: 1px;
}

.search-box-black {
	background-image: $search-bar-image;
	background-repeat: no-repeat;
	background-position: 0.5rem;
	background-color: theme-color(secondary);
	border: solid 1px theme-color(secondary);
	color: color-yiq(theme-color(secondary));
	font-weight: $font-weight-medium;
	height: 2.8rem;
	outline: none;
}

.search-box-black:focus {
	border: 1px solid theme-color('primary');
	border-radius: 0.3em;
	outline: none;
}

.custom-select {
	border: none;
	background-color: $gray-300;
}

.navbar {
	.card {
		a {
			padding: .4rem .4rem .4rem 1rem;

			&:hover,
			&:focus,
			&.active {
				background-color: theme-color('light-primary');
				border-left: solid 3px theme-color('primary');
			}
		}
	}

	.default-list {
		li {

			a {
				padding-left: 1.5rem;
				display: block;
				color: $gray-800;
			}
		}
	}
}

.product-search-image-card {
	.product-img-link {
		width:100%;
		padding-bottom:70%;
		position:relative;
		display:block;

		.product-img-wrap {
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			text-align:center;

			.product-img {
				max-height:100%;
				max-width:100%;
			}
		}
	}
}

#offert-details-form {
	padding-top:2rem;
}

@include media-breakpoint-up(sm) {
	.pull-sm-right {
		float: right;
	}

	.pull-sm-left {
		float: left;
	}
}

@include media-breakpoint-up(md) {
	.pull-md-right {
		float: right;
	}

	.pull-md-left {
		float: left;
	}
}

@include media-breakpoint-up(lg) {
	.pull-lg-right {
		float: right;
	}

	.pull-lg-left {
		float: left;
	}
}

@include media-breakpoint-up(xl) {
	.pull-xl-right {
		float: right;
	}

	.pull-xl-left {
		float: left;
	}
}

.clearfix {
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}

.topic-block a, .center-1 .page-body a {
	text-decoration: underline;
}
.topic-block a:hover, .center-1 .page-body a:hover {
	text-decoration: underline;
	color: #8d8d8d;
}