.card {

	p {
        color: #666;
    }
	
    &, 
    &:hover,
    &.focus {
        color: $body-color;
        text-decoration: none;
    }

    a {
		&,
        &:hover,
        &.focus,
		&.active {
			text-decoration:none;
        }
    }

	.text-align-right{
		text-align: right;
	}

	.price{
		margin-bottom: 0;
	}

	.block{
		display: block;
	}

	.info-footer{
		margin-top: 2rem;
	}

	h6 a {
		color: #000;
	}

	.btn {
		padding: 0.8rem 1rem;
	}

    @include media-breakpoint-up(lg) {
        &.card-lg-shadow {
            box-shadow: $box-shadow;
        }
    }
}

@media (min-width: 992px) {
	.pt-lg-0 .card:first-child {
		border-top: none !important;
	}
	.pt-lg-0 .card:last-child {
		margin-bottom: -1rem;
	}
}
