.ajax-loading-block-window {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: transparentize($gray-500, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ajax-loading-block-window .fa {
    font-size: 9em;
}