.process {
  overflow: auto;
  text-align: center;

  .process-step {
    display: inline-block;
    width: 32%;
	vertical-align: text-top;

    .process-step-icon {
      padding: .25rem;
    }

    .process-step-text {
      padding: .25rem;

      h6 {
        margin-bottom: .2rem;
      }
    }
  }

  img {
    width: 42px;
  }
}

.less-padding {
  padding-top: 1rem !important;
  padding-bottom: 0 !important;
}

.sublist {
  list-style-type: none;

  li {
    display: inline-block;
  }
}

.part-group {
	
	.part-letter {
		display: none;
		background-color: white;
		float: left;
		width: 4%;
		height: 100%;
		text-align: center;
		padding-top: 1rem;
		margin-right: 1%;
	}

	.part-links {
		display: inline-block;
		background-color: white;
		width: 100%;
		height: 100%;
		padding: 1rem;
		text-align: center;

		ul {
			margin: 0;
			padding: 0;
		}

		a {
			margin: .25rem;
			padding: .5rem;
			color: black;
			background-color: #f4f4f4;
			border-radius: 5px;
			display: block;
		}

		.categories a {
			background: none;
			text-align: left;
			margin: 0;
			line-height: 24px;
		}

		.categories .default-list a {
			line-height: 5px;
		}

		.categories .card:first-child {
			border-top:none !important;
		}
	}
}

.mobile-only{
	display: none;
}

.mobile-navigation-img{
	display:none;
}

.category-image-crop {
	float: right;
	max-height: 40px;

		img {
			width: 100px;
		}
}

@media only screen and (max-width: 991px) {
	.desktop-only {
		display: none;
	}

	.mobile-only {
		display: block;
	}

	.image-cut-container {
		visibility: hidden; // Always hide
		overflow: hidden;
		max-height: 25px;
		display: inline-block;
		float: right;
		margin-left: 10px;
	}

	.mobile-navigation-img {
		width: 66px;
		display: block;
		float: right;
	}

	.part-group {
		.part-links {
			.flex-column {
				flex-direction: inherit !important;
			}

			.categories {
				a {
					white-space: normal;
				}
			}
		}
	}

	.process {

		.process-step {
			display: inline-block;
			width: 100%;
			text-align: left;

			.process-step-icon {
				padding: .25rem;
				display: inline-block;
				vertical-align: text-top;
			}

			.process-step-text {
				padding: .25rem;
				display: inline-block;
				vertical-align: text-top;

				p {
					display: inline-grid;
				}
			}
		}

		img {
			width: 21px;
		}
	}

	.category-image-crop {
		max-height: 30px;

		img {
			width: 66px;
		}
	}
}