.module {
    padding-top: spacers(3);
    padding-bottom: spacers(3);

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    .alert-usp {
        background-color: black;
        padding: 10px;
        text-align: center;
        color: #f8c350 !important;
        font-size: 110%;
    }
}
