.default-list {
	li {

		a {
			padding: 0.4rem 0.4rem 0.4rem 1.5rem;
			display: block;
			color: $gray-800;

			&:hover,
			&:focus,
			&.active {
				background-color: theme-color('light-primary');
				border-left: solid 3px theme-color('primary'); 
			}
		}
	}
}
