.search-supplier-divider {
	text-align: center;
	margin-bottom: 1rem;
	padding: 15px;
	font-weight: bold;
	color: #000;
	width: 100%;
}

.licenseplate {

	input, select, textarea {
		font-size: 7.0625rem;
		color: #FFF;
		margin-top: -2rem;
		margin-bottom: -2rem;
		font-weight: 500;
	}

	.licenseplate-bar {
		max-width: 380px;
		margin-left: auto;
		margin-right: auto;
		margin-top: -3rem;
	}

	@include media-breakpoint-down(sm) {
		input, select, textarea {
			font-size: 5.0625rem;
			margin-top: -1.5rem;
			margin-bottom: -1.5rem;
		}

		.licenseplate-bar {
			max-width: 280px;
		}
	}

	@include media-breakpoint-down(xs) {
		input, select, textarea {
			font-size: 4.0625rem;
			margin-top: -1.5rem;
			margin-bottom: -1.5rem;
		}

		.licenseplate-bar {
			max-width: 220px;
			margin-top: -3.5rem;
		}
	}

	textarea:focus, input:focus {
		color: #FFF;
	}

	input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #AAA;
	}

	input:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #AAA;
	}

	input::-ms-input-placeholder { /* Microsoft Edge */
		color: #AAA;
	}
}

.list-inline-width {
	width: 5rem;
}

.catalog-selectors {
	@include media-breakpoint-up(lg) {
		text-align: center;
	}

	@include media-breakpoint-down(lg) {
		.selector-wrap {
			select {
				padding: .6rem 1.5rem .6rem 1.2rem;
				height: auto;
				background-position: right 0.6rem center;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.page-sizes {
			display: block;
		}

		.sort-options, .supplier-options {
			width: 50%;
			display: inline-block;
		}

		.sort-options {
			padding-right: 1rem;
		}

		.supplier-options {
			padding-left: 1rem;
		}

		.sort-options select, .supplier-options select {
			width: 100%;
		}
	}

	@include media-breakpoint-down(xs) {
		.sort-options, .supplier-options {
			width: 100%;
			display: block;
			padding: 0;
		}
	}
}

.categories-header {
	padding: 1.3rem;
	padding-left: 2.9rem;
	background-color: theme-color('dark');

	h6 {
		color: $white;
	}

	&:before {
		position: absolute;
		left: 1.3rem;
		top: 1.15rem;
		color: theme-color('primary');
		font-family: Ionicons;
		content: "\f394";
		font-size: 1.2rem;
	}

	&:after {
		color: $white;
		right:1.3rem;
		top:1.4rem;
		content: "\f35f";
	}

	&.collapsed:after {
		content: "\f363";
	}
}