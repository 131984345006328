.arrow-list {
    a {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: $font-weight-semi-bold;
        padding: spacers(1) 0 spacers(1) spacers(3);
        transition: $transition-base;
        color: $gray-900;

        &::before {
            @include position(absolute, auto, auto, auto, 0);

            font-family: 'Ionicons';
            content: "\f125";
            line-height: 1;
            color: $gray-500;
            font-size: 0.7rem;
        }

        &:hover,
        &:focus,
        &.active {
            margin-left: spacers(2); 
            text-decoration: none;

            &, 
            &::before {
                color: theme-color('primary');
            }
        }
    }
}
