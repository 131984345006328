.slideout-panel {
    position: relative;
    z-index: 10;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel, {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block !important;
}

.mobile-menu {
    @include position(fixed, 0, 0, 0, auto);

    width: 280px;
    padding: spacers(4) spacers(5);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: none;
    z-index: -1;

    .mobile-menu-title {
        text-transform: uppercase;
        border-bottom: 2px solid $white;
        display: inline-block;
        padding-bottom: 0.3rem;
    }

	.search-box-black {
		width:100%;
	}

    .navbar {
        &, .navbar-nav {
            width: 100%;
        }
        
        .navbar-nav {
            .nav-link {
                color: $white;
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: $font-weight-bold;
                padding-top: spacers(3);
                padding-bottom: spacers(3);
                position: relative;
            }

            .dropdown {
                position: relative;

                .nav-link {
                    display: flex;
                    align-items: center;
                }

                .nav-arrow {
                    @include position(absolute, auto, 0, auto, auto);

                    transition: all 0.3s ease;
                    display: block;
                    color: $white;
                    text-align: center;
                    cursor: pointer;
                    transform-origin: center;
                    -webkit-backface-visibility: hidden;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;

                    &:after {
                        font-family: "Ionicons";
                        content: "\f123";
                        vertical-align: middle;
                    }

                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }

            .dropdown-menu {
                background-color: transparent;
                border: 0;
                padding-left: spacers(4);

                .dropdown-item {
                    padding-left: 0;
                    padding-right: 0;
                    color: $white;
                }
            }
        }
    }
}