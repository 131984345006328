@charset "UTF-8";
/** Import Bootstrap functions */
/** Import Bootstrap functions */
/*bull*/
@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes rotating-pulse {
  0% {
    transform: rotate(0deg) scale(1); }
  50% {
    transform: rotate(180deg) scale(1.3); }
  100% {
    transform: rotate(360deg) scale(1); } }

:root,
html {
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none; }
  @media (max-width: 767.98px) {
    :root,
    html {
      font-size: 0.9rem; } }

body {
  padding: 0;
  margin: 0;
  color: #303a42; }

.browserhappy {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #d54233;
  color: #fff;
  border: 0;
  text-align: center;
  padding: 2rem 1rem;
  margin-bottom: 0;
  border-radius: 0;
  z-index: 110; }

.wrapper {
  overflow: hidden;
  min-width: 320px; }

.container {
  max-width: 1200px;
  width: 100%; }
  .container.container-md {
    max-width: 991.98px; }
  .container.container-sm {
    max-width: 767.98px; }
  .container.container-xs {
    max-width: 575.98px; }

.bar-notification {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 15px 25px 15px 10px;
  line-height: 16px;
  color: #fff;
  opacity: 0.95; }

.bar-notification.success {
  background-color: #4bb07a; }

.bar-notification.error {
  background-color: #e4444c; }

.bar-notification.warning {
  background-color: #f39c12; }

.bar-notification .content {
  margin: 0 10px 0 0; }

.bar-notification .content a {
  color: #fff;
  text-decoration: underline; }

.bar-notification .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  margin: 7px;
  background: #fff url("../images/close.png") center no-repeat;
  cursor: pointer; }

.noscript {
  border-bottom: 1px solid #333;
  background-color: #ff9;
  padding: 30px 15px;
  text-align: center;
  line-height: 22px;
  color: #444; }

.please-wait {
  background: url("../images/ajax-loader-small.gif") no-repeat;
  padding-left: 20px;
  font-size: 14px; }

.ui-dialog {
  max-width: 90%;
  border: 1px solid #ddd;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background-color: #fff;
  /*override jQuery UI styles, do not delete doubled properties*/
  border-radius: 0;
  padding: 0;
  font: normal 14px Arial, Helvetica, sans-serif; }

.ui-dialog:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }

.ui-dialog-titlebar {
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  background-color: #eee;
  padding: 10px 15px;
  /*override jQuery UI styles, do not delete doubled properties*/
  border-width: 0 0 1px;
  border-radius: 0;
  background-image: none;
  padding: 10px 15px !important;
  font-weight: normal;
  cursor: auto !important; }

.ui-dialog-titlebar > span {
  float: left;
  font-size: 18px;
  color: #444;
  /*override jQuery UI styles, do not delete doubled properties*/
  margin: 0 !important; }

.ui-dialog-titlebar button {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  border: none;
  overflow: hidden;
  background: url("../images/close.png") center no-repeat;
  font-size: 0;
  /*override jQuery UI styles, do not delete doubled properties*/
  top: 0 !important;
  right: 0 !important;
  width: 42px !important;
  height: 42px !important;
  margin: 0 !important;
  border: none !important;
  border-radius: 0;
  background: url("../images/close.png") center no-repeat !important;
  padding: 0 !important; }

.ui-dialog-titlebar button span {
  display: none !important; }

.ui-dialog-content {
  padding: 15px;
  line-height: 20px;
  /*override jQuery UI styles, do not delete doubled properties*/
  background-color: #fff !important;
  padding: 15px 15px 20px 15px !important;
  color: #777; }

.ui-dialog-content .page {
  min-height: 0; }

.ui-dialog-content .page-title {
  min-height: 0;
  margin: 0 0 15px;
  padding: 0px 10px 10px 10px;
  text-align: center; }

.ui-dialog-content .page-title h1 {
  font-size: 24px;
  line-height: 30px; }

.ui-dialog-content .back-in-stock-subscription-page {
  text-align: center; }

.ui-dialog-content .back-in-stock-subscription-page .tooltip {
  margin-bottom: 10px; }

.ui-dialog-content .back-in-stock-subscription-page .button-1 {
  border: none;
  background-color: #4ab2f1;
  padding: 10px 15px;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase; }

.ui-dialog-content .back-in-stock-subscription-page .button-1:hover {
  background-color: #248ece; }

.eu-cookie-bar-notification {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1050;
  width: 320px;
  height: 180px;
  margin: -90px 0 0 -160px;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 20px;
  text-align: center; }

.eu-cookie-bar-notification .text {
  margin-bottom: 20px;
  line-height: 20px; }

.eu-cookie-bar-notification button {
  min-width: 60px;
  margin: 5px 0 10px;
  border: none;
  background-color: #4ab2f1;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff; }

.eu-cookie-bar-notification button:hover {
  background-color: #248ece; }

.eu-cookie-bar-notification a {
  display: block;
  color: #4ab2f1; }

.eu-cookie-bar-notification a:hover {
  text-decoration: underline; }

.hidden {
  display: none; }

.hover-pointer:hover {
  cursor: pointer; }

.pager {
  width: 100%;
  text-align: right;
  padding: 5px 0; }
  .pager ul {
    margin-bottom: 0; }

.no-margins {
  margin: 0; }

hr {
  height: 1px; }

.search-box-black {
  background-image: url("/Themes/DefaultClean/dist/images/search.png");
  background-repeat: no-repeat;
  background-position: 0.5rem;
  background-color: #131313;
  border: solid 1px #131313;
  color: #fff;
  font-weight: 500;
  height: 2.8rem;
  outline: none; }

.search-box-black:focus {
  border: 1px solid #7eBa27;
  border-radius: 0.3em;
  outline: none; }

.custom-select {
  border: none;
  background-color: #f4f4f4; }

.navbar .card a {
  padding: .4rem .4rem .4rem 1rem; }
  .navbar .card a:hover, .navbar .card a:focus, .navbar .card a.active {
    background-color: #e7f3d6;
    border-left: solid 3px #7eBa27; }

.navbar .default-list li a {
  padding-left: 1.5rem;
  display: block;
  color: #303a42; }

.product-search-image-card .product-img-link {
  width: 100%;
  padding-bottom: 70%;
  position: relative;
  display: block; }
  .product-search-image-card .product-img-link .product-img-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center; }
    .product-search-image-card .product-img-link .product-img-wrap .product-img {
      max-height: 100%;
      max-width: 100%; }

#offert-details-form {
  padding-top: 2rem; }

@media (min-width: 576px) {
  .pull-sm-right {
    float: right; }
  .pull-sm-left {
    float: left; } }

@media (min-width: 768px) {
  .pull-md-right {
    float: right; }
  .pull-md-left {
    float: left; } }

@media (min-width: 992px) {
  .pull-lg-right {
    float: right; }
  .pull-lg-left {
    float: left; } }

@media (min-width: 1200px) {
  .pull-xl-right {
    float: right; }
  .pull-xl-left {
    float: left; } }

.clearfix::after {
  display: block;
  content: "";
  clear: both; }

.topic-block a, .center-1 .page-body a {
  text-decoration: underline; }

.topic-block a:hover, .center-1 .page-body a:hover {
  text-decoration: underline;
  color: #8d8d8d; }

h5 {
  font-weight: 600;
  color: #7eBa27; }

@media (max-width: 575.98px) {
  h1 {
    font-size: 1.8rem; }
  h2 {
    font-size: 1.5rem; }
  h3 {
    font-size: 1.4rem; }
  h4 {
    font-size: 1.3rem; }
  h5 {
    font-size: 1.1rem; }
  h6 {
    font-size: 1rem; } }

label {
  color: #1b1b1b;
  font-size: 0.9rem; }

blockquote {
  padding: 1.5rem 1.5rem 1.5rem 3rem;
  position: relative;
  font-style: italic;
  background-color: #f4f4f4; }
  blockquote:before {
    top: 0.5rem;
    right: auto;
    bottom: auto;
    left: 0.5rem;
    position: absolute;
    font-family: "Georgia";
    content: "“";
    vertical-align: middle;
    color: #dedede;
    font-size: 4rem;
    line-height: 1; }

.uppercase {
  text-transform: uppercase; }

h1, h2, h3, h4, h5, h6, .btn {
  text-transform: uppercase; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Light.eot");
  src: local("Futura PT Light"), local("FuturaPT-Light"), url("../fonts/FuturaPT-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Light.woff2") format("woff2"), url("../fonts/FuturaPT-Light.woff") format("woff"), url("../fonts/FuturaPT-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Book.eot");
  src: local("Futura PT Book"), local("FuturaPT-Book"), url("../fonts/FuturaPT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Book.woff2") format("woff2"), url("../fonts/FuturaPT-Book.woff") format("woff"), url("../fonts/FuturaPT-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Medium.eot");
  src: local("Futura PT Medium"), local("FuturaPT-Medium"), url("../fonts/FuturaPT-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Medium.woff2") format("woff2"), url("../fonts/FuturaPT-Medium.woff") format("woff"), url("../fonts/FuturaPT-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Demi.eot");
  src: local("Futura PT Demi"), local("FuturaPT-Demi"), url("../fonts/FuturaPT-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Demi.woff2") format("woff2"), url("../fonts/FuturaPT-Demi.woff") format("woff"), url("../fonts/FuturaPT-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Heavy.eot");
  src: local("Futura PT Heavy"), local("FuturaPT-Heavy"), url("../fonts/FuturaPT-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Heavy.woff2") format("woff2"), url("../fonts/FuturaPT-Heavy.woff") format("woff"), url("../fonts/FuturaPT-Heavy.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-Bold.eot");
  src: local("Futura PT Bold"), local("FuturaPT-Bold"), url("../fonts/FuturaPT-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Bold.woff2") format("woff2"), url("../fonts/FuturaPT-Bold.woff") format("woff"), url("../fonts/FuturaPT-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-ExtraBold.eot");
  src: local("Futura PT Extra Bold"), local("FuturaPT-ExtraBold"), url("../fonts/FuturaPT-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-ExtraBold.woff2") format("woff2"), url("../fonts/FuturaPT-ExtraBold.woff") format("woff"), url("../fonts/FuturaPT-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-LightObl.eot");
  src: local("Futura PT Light Oblique"), local("FuturaPT-LightObl"), url("../fonts/FuturaPT-LightObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-LightObl.woff2") format("woff2"), url("../fonts/FuturaPT-LightObl.woff") format("woff"), url("../fonts/FuturaPT-LightObl.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-BookObl.eot");
  src: local("Futura PT Book Oblique"), local("FuturaPT-BookObl"), url("../fonts/FuturaPT-BookObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-BookObl.woff2") format("woff2"), url("../fonts/FuturaPT-BookObl.woff") format("woff"), url("../fonts/FuturaPT-BookObl.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-MediumObl.eot");
  src: local("Futura PT Medium Oblique"), local("FuturaPT-MediumObl"), url("../fonts/FuturaPT-MediumObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-MediumObl.woff2") format("woff2"), url("../fonts/FuturaPT-MediumObl.woff") format("woff"), url("../fonts/FuturaPT-MediumObl.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-DemiObl.eot");
  src: local("Futura PT Demi Oblique"), local("FuturaPT-DemiObl"), url("../fonts/FuturaPT-DemiObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-DemiObl.woff2") format("woff2"), url("../fonts/FuturaPT-DemiObl.woff") format("woff"), url("../fonts/FuturaPT-DemiObl.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-HeavyObl.eot");
  src: local("Futura PT Heavy Oblique"), local("FuturaPT-HeavyObl"), url("../fonts/FuturaPT-HeavyObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-HeavyObl.woff2") format("woff2"), url("../fonts/FuturaPT-HeavyObl.woff") format("woff"), url("../fonts/FuturaPT-HeavyObl.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-BoldObl.eot");
  src: local("Futura PT Bold Oblique"), local("FuturaPT-BoldObl"), url("../fonts/FuturaPT-BoldObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-BoldObl.woff2") format("woff2"), url("../fonts/FuturaPT-BoldObl.woff") format("woff"), url("../fonts/FuturaPT-BoldObl.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT-ExtraBoldObl.eot");
  src: local("Futura PT Extra Bold Oblique"), local("FuturaPT-ExtraBoldObl"), url("../fonts/FuturaPT-ExtraBoldObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-ExtraBoldObl.woff2") format("woff2"), url("../fonts/FuturaPT-ExtraBoldObl.woff") format("woff"), url("../fonts/FuturaPT-ExtraBoldObl.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT Cond';
  src: url("../fonts/FuturaPTCond-BoldObl.eot");
  src: local("Futura PT Cond Bold Oblique"), local("FuturaPTCond-BoldObl"), url("../fonts/FuturaPTCond-BoldObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-BoldObl.woff2") format("woff2"), url("../fonts/FuturaPTCond-BoldObl.woff") format("woff"), url("../fonts/FuturaPTCond-BoldObl.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT Cond Extra';
  src: url("../fonts/FuturaPTCond-ExtraBoldObl.eot");
  src: local("Futura PT Cond Extra Bold Oblique"), local("FuturaPTCond-ExtraBoldObl"), url("../fonts/FuturaPTCond-ExtraBoldObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-ExtraBoldObl.woff2") format("woff2"), url("../fonts/FuturaPTCond-ExtraBoldObl.woff") format("woff"), url("../fonts/FuturaPTCond-ExtraBoldObl.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url("../fonts/FuturaPTCond-Book.eot");
  src: local("Futura PT Cond Book"), local("FuturaPTCond-Book"), url("../fonts/FuturaPTCond-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-Book.woff2") format("woff2"), url("../fonts/FuturaPTCond-Book.woff") format("woff"), url("../fonts/FuturaPTCond-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Cond';
  src: url("../fonts/FuturaPTCond-Medium.eot");
  src: local("Futura PT Cond Medium"), local("FuturaPTCond-Medium"), url("../fonts/FuturaPTCond-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-Medium.woff2") format("woff2"), url("../fonts/FuturaPTCond-Medium.woff") format("woff"), url("../fonts/FuturaPTCond-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Cond';
  src: url("../fonts/FuturaPTCond-Bold.eot");
  src: local("Futura PT Cond Bold"), local("FuturaPTCond-Bold"), url("../fonts/FuturaPTCond-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-Bold.woff2") format("woff2"), url("../fonts/FuturaPTCond-Bold.woff") format("woff"), url("../fonts/FuturaPTCond-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Cond Extra';
  src: url("../fonts/FuturaPTCond-ExtraBold.eot");
  src: local("Futura PT Cond Extra Bold"), local("FuturaPTCond-ExtraBold"), url("../fonts/FuturaPTCond-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-ExtraBold.woff2") format("woff2"), url("../fonts/FuturaPTCond-ExtraBold.woff") format("woff"), url("../fonts/FuturaPTCond-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url("../fonts/FuturaPTCond-BookObl.eot");
  src: local("Futura PT Cond Book Oblique"), local("FuturaPTCond-BookObl"), url("../fonts/FuturaPTCond-BookObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-BookObl.woff2") format("woff2"), url("../fonts/FuturaPTCond-BookObl.woff") format("woff"), url("../fonts/FuturaPTCond-BookObl.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT Cond';
  src: url("../fonts/FuturaPTCond-MediumObl.eot");
  src: local("Futura PT Cond Medium Oblique"), local("FuturaPTCond-MediumObl"), url("../fonts/FuturaPTCond-MediumObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPTCond-MediumObl.woff2") format("woff2"), url("../fonts/FuturaPTCond-MediumObl.woff") format("woff"), url("../fonts/FuturaPTCond-MediumObl.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

body {
  font-family: 'Futura PT', Verdana, sans-serif; }

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  background-color: #7eBa27;
  vertical-align: middle; }
  .dot.dot-lg {
    width: 10px;
    height: 10px; }
  .dot.dot-danger {
    background-color: #d54233; }
  .dot.dot-success {
    background-color: #7eBa27; }
  .dot.dot-warning {
    background-color: #ea862b; }

.bg-center-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.text-crop {
  line-height: 1; }
  .text-crop::before, .text-crop::after {
    content: '';
    display: block;
    height: 0;
    width: 0; }
  .text-crop::before {
    margin-bottom: calc(-0.15em + 0px); }
  .text-crop::after {
    margin-top: calc(-0.1222222222em + 0px); }

.fixed {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 20; }

@media (max-width: 575.98px) {
  .btn-xs-block {
    display: block;
    width: 100%; } }

@media (max-width: 575.98px) {
  .w-xs-100 {
    width: 100% !important; } }

.big {
  font-size: 120% !important; }

.big-lg {
  font-size: 165% !important; }

@media (min-width: 768px) {
  .overflow-buttons {
    position: relative; }
    .overflow-buttons > div {
      top: -46px;
      right: 0;
      bottom: auto;
      left: 0;
      position: absolute; } }

.word-break {
  word-break: break-word; }

.text-xxl {
  font-size: 8rem; }
  @media (min-width: 576px) {
    .text-xxl {
      font-size: 10rem; } }

.btn {
  font-size: 0.9rem;
  white-space: initial;
  border-radius: 1px;
  cursor: pointer; }
  .btn span {
    vertical-align: middle; }
  .btn .icon {
    font-size: 120%;
    padding-right: 0.5rem;
    vertical-align: middle; }
  .btn.btn-icon-right .icon {
    padding-right: 0;
    padding-left: 0.5rem; }
  .btn.btn-outline-secondary {
    font-weight: 500;
    color: #1b1b1b; }
  .btn.btn-link {
    padding: 0;
    border: 0;
    line-height: 1; }
    .btn.btn-link::before, .btn.btn-link::after {
      content: '';
      display: block;
      height: 0;
      width: 0; }
    .btn.btn-link::before {
      margin-bottom: calc(-0.15em + 0px); }
    .btn.btn-link::after {
      margin-top: calc(-0.1222222222em + 0px); }
  .btn.btn-white {
    background-color: #fff;
    box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.04);
    position: relative;
    color: #303a42; }
    .btn.btn-white::after {
      top: auto;
      right: 1.5rem;
      bottom: auto;
      left: auto;
      position: absolute;
      width: 20px;
      height: 20px;
      font-family: "ionicons", sans-serif;
      content: "\f30f";
      line-height: 20px;
      text-align: center;
      font-size: 1.2rem;
      color: #7eBa27; }
    .btn.btn-white:not(.disabled):hover, .btn.btn-white:not(.disabled):focus {
      background-color: #7eBa27;
      color: #fff; }
      .btn.btn-white:not(.disabled):hover, .btn.btn-white:not(.disabled):hover::after, .btn.btn-white:not(.disabled):focus, .btn.btn-white:not(.disabled):focus::after {
        color: #fff; }
  .btn.btn-circle {
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .btn.btn-circle.btn-sm {
      width: 2.2rem;
      height: 2.2rem; }
    .btn.btn-circle.btn-lg {
      width: 3.5rem;
      height: 3.5rem; }
    .btn.btn-circle .icon {
      padding: 0; }
  .btn.btn-icon {
    position: relative;
    background-color: #fff;
    color: #7eBa27;
    text-align: left;
    font-size: 1.2rem;
    border-radius: 0;
    display: flex;
    align-items: center;
    padding: 1rem; }
    .btn.btn-icon .icon {
      width: 3.5rem;
      height: 3.5rem;
      margin-right: 1rem;
      padding: 0; }
    .btn.btn-icon::after {
      top: auto;
      right: 1rem;
      bottom: auto;
      left: auto;
      position: absolute;
      font-family: "ionicons", sans-serif;
      content: "\f30f";
      text-align: center;
      font-size: 1.4rem;
      color: #7eBa27; }
    .btn.btn-icon:hover, .btn.btn-icon:focus {
      background-color: #7eBa27; }
      .btn.btn-icon:hover, .btn.btn-icon:hover::after, .btn.btn-icon:focus, .btn.btn-icon:focus::after {
        color: #fff; }
  .btn.btn-primary:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-secondary:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-success:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-info:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-warning:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-danger:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-light:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-dark:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-accent:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }
  .btn.btn-light-primary:disabled {
    background-color: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff; }

@media (max-width: 767.98px) {
  .search-btn-circle a.text-uppercase {
    font-size: 90%; } }

@media (max-width: 575.98px) {
  .search-btn-circle a.text-uppercase {
    font-size: 80%; } }

.circle-green {
  width: 5rem;
  height: 5rem;
  background-color: #7eBa27;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  vertical-align: top;
  padding: 0;
  color: #fff;
  background-color: #7eBa27;
  border-color: #7eBa27; }
  .circle-green:hover {
    color: #fff;
    background-color: #699a20;
    border-color: #699a20; }
  @media (max-width: 767.98px) {
    .circle-green {
      width: 4rem;
      height: 4rem;
      background-size: 80%; } }
  @media (max-width: 575.98px) {
    .circle-green {
      width: 3rem;
      height: 3rem;
      background-size: 60%; } }

.codekind-car {
  background-image: url("/Themes/DefaultClean/dist/images/carsmall.png"); }

.codekind-mc {
  background-image: url("/Themes/DefaultClean/dist/images/mc.png"); }

.codekind-rv {
  background-image: url("/Themes/DefaultClean/dist/images/rv.png"); }

.codekind-truck {
  background-image: url("/Themes/DefaultClean/dist/images/truck.png"); }

.codekind-tire {
  background-image: url("/Themes/DefaultClean/dist/images/tire.png"); }

.codekind-trailer {
  background-image: url("/Themes/DefaultClean/dist/images/trailer.png"); }

form .form-control.border-color-none, form .form-control.border-color-none:focus {
  border-color: #fff; }

.custom-select {
  width: auto;
  border-radius: 1px; }

.input-group-text {
  flex-direction: column;
  justify-content: center;
  padding: 0.25rem 1rem; }

.input-group-text-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain; }
  .input-group-text-icon.eu {
    background-image: url("../images/eu.png"); }

.fieldset {
  padding-bottom: 1rem; }
  .fieldset .title {
    padding-bottom: 1rem; }

.inputs {
  overflow: auto;
  margin-bottom: 1rem; }

.column-label {
  width: 40%;
  float: left;
  padding-top: 1rem; }

.column-input {
  width: 60%;
  float: left; }
  .column-input input {
    padding-left: 1rem; }

.align-right {
  float: right; }

.control {
  overflow: auto; }
  .control .wSmall {
    width: 4%; }
  .control .wLarge {
    width: 95%; }
  .control .label {
    float: left;
    padding-left: .5rem; }
    .control .label label {
      display: block; }
  .control .input {
    float: left; }
    .control .input input {
      display: block; }

.text-smaller {
  font-size: 65%; }

.message-form {
  border-color: #eee;
  outline: none;
  border-radius: .4rem; }

.invalid-feedback {
  color: #dc3545 !important; }
  .invalid-feedback span {
    color: #dc3545 !important; }

.form-control:valid {
  background-image: none !important;
  padding-right: 1.9rem !important; }

.form-control {
  border-radius: 0;
  border: none;
  background-color: #f2f4f5; }
  .form-control.bg-transparent {
    transition: none; }

.form-control-sm {
  border-radius: 0; }

.form-control:focus {
  background-color: #f2f4f5;
  border: solid 1px #1b1b1b;
  border-radius: 0.3em; }
  .form-control:focus.bg-transparent {
    border: none; }

.card p {
  color: #666; }

.card, .card:hover, .card.focus {
  color: #666;
  text-decoration: none; }

.card a, .card a:hover, .card a.focus, .card a.active {
  text-decoration: none; }

.card .text-align-right {
  text-align: right; }

.card .price {
  margin-bottom: 0; }

.card .block {
  display: block; }

.card .info-footer {
  margin-top: 2rem; }

.card h6 a {
  color: #000; }

.card .btn {
  padding: 0.8rem 1rem; }

@media (min-width: 992px) {
  .card.card-lg-shadow {
    box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.04); } }

@media (min-width: 992px) {
  .pt-lg-0 .card:first-child {
    border-top: none !important; }
  .pt-lg-0 .card:last-child {
    margin-bottom: -1rem; } }

#checkout-steps .module h1,
#checkout-steps .module h2,
#checkout-steps .module h3,
#checkout-steps .module h4,
#checkout-steps .module h5 {
  color: black !important; }

#checkout-steps .page-title {
  text-align: center; }

#checkout-steps .fieldset label {
  color: black !important; }

#checkout-steps .selector label {
  color: black !important; }

#checkout-steps h3.invert {
  color: white; }

/* NEW */
ol.opc {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  ol.opc li {
    display: block;
    overflow: auto;
    position: relative; }
    ol.opc li.tab-section .buttons {
      background-color: white;
      padding: 1rem; }
  ol.opc .step {
    background-color: white;
    padding-left: 4rem;
    padding-right: 4rem;
    position: relative; }
    @media (max-width: 767.98px) {
      ol.opc .step {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (max-width: 575.98px) {
      ol.opc .step {
        padding-left: 1rem;
        padding-right: 1rem; } }
  ol.opc .opc-icon {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    z-index: 99; }
    ol.opc .opc-icon img {
      width: 42px; }
  ol.opc div.section {
    padding: 1rem; }
  ol.opc .step-title {
    background-color: white;
    position: relative;
    margin: 0;
    padding: 1.5rem;
    padding-left: 5rem;
    padding-top: 2rem;
    float: none; }
    ol.opc .step-title h2 {
      font-size: 120%; }

.step-link {
  text-align: right;
  padding-bottom: 0; }
  .step-link div {
    display: inline-block;
    background: #fff;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 16px; }
    .step-link div i {
      font-size: 25px;
      vertical-align: sub;
      margin-right: 5px; }

#shopping-cart-form .ion-trash-a {
  font-size: 30px;
  vertical-align: sub; }

#shopping-cart-form #applyDiscountButton {
  white-space: nowrap;
  line-height: 1.7rem; }

#shopping-cart-form .form-control {
  line-height: 1.7rem; }

#shopping-cart-form .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccccc; }

#shopping-cart-form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc; }

#shopping-cart-form .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccccc; }

@media (max-width: 575.98px) {
  #shopping-cart-form input[name="continueshopping"], #shopping-cart-form .removeAllItems {
    width: 100%; } }

/*Images*/
.icon-progress-checkout-1 {
  background: url("../images/icon-progress-checkout-1.png");
  background-size: 42px 42px;
  display: inline-block;
  width: 42px;
  height: 42px; }
  .icon-progress-checkout-1.inactive {
    background-image: url("../images/icon-progress-checkout-1-inactive.png"); }

.icon-progress-checkout-2 {
  background: url("../images/icon-progress-checkout-2.png");
  background-size: 42px 42px;
  display: inline-block;
  width: 42px;
  height: 42px; }
  .icon-progress-checkout-2.inactive {
    background-image: url("../images/icon-progress-checkout-2-inactive.png"); }

.icon-progress-checkout-3 {
  background: url("../images/icon-progress-checkout-3.png");
  background-size: 42px 42px;
  display: inline-block;
  width: 42px;
  height: 42px; }
  .icon-progress-checkout-3.inactive {
    background-image: url("../images/icon-progress-checkout-3-inactive.png"); }

.icon-progress-checkout-4 {
  background: url("../images/icon-progress-checkout-4.png");
  background-size: 42px 42px;
  display: inline-block;
  width: 42px;
  height: 42px; }
  .icon-progress-checkout-4.inactive {
    background-image: url("../images/icon-progress-checkout-4-inactive.png"); }

.icon-progress-checkout-5 {
  background: url("../images/icon-progress-checkout-5.png");
  background-size: 42px 42px;
  display: inline-block;
  width: 42px;
  height: 42px; }
  .icon-progress-checkout-5.inactive {
    background-image: url("../images/icon-progress-checkout-5-inactive.png"); }

.icon {
  height: 1rem;
  width: 1rem;
  display: inline-block; }
  .icon.ion-ios-cart {
    background-image: url("../images/cart.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem; }

.icon-cart {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
  background-image: url("../images/cart.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem; }

#dialog-notifications-success,
#dialog-notifications-error,
#dialog-notifications-warning {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  color: black;
  background-color: white;
  background-image: url("/Themes/DefaultClean/Content/images/loading.gif");
  border-style: solid black; }

.pagination-special .page-item:not(:first-child):not(:last-child):not(.disabled) .page-link {
  font-weight: 700; }
  .pagination-special .page-item:not(:first-child):not(:last-child):not(.disabled) .page-link::after {
    top: auto;
    right: 0.75rem;
    bottom: 0.4rem;
    left: 0.75rem;
    position: absolute;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    transform: scaleX(0);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    content: '';
    border-bottom: 2px solid #303a42; }

.pagination-special .page-item:not(:first-child):not(:last-child):not(.disabled).active .page-link::after, .pagination-special .page-item:not(:first-child):not(:last-child):not(.disabled):hover .page-link::after, .pagination-special .page-item:not(:first-child):not(:last-child):not(.disabled):focus .page-link::after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  transform: scaleX(1); }

.pagination-special .page-item .page-link {
  border: 0; }
  .pagination-special .page-item .page-link:hover, .pagination-special .page-item .page-link:focus {
    background-color: transparent;
    color: #303a42; }

.pagination-special .page-item:first-child {
  margin-right: 1.5rem; }

.pagination-special .page-item:last-child {
  margin-left: 1.5rem; }

.pagination-special .page-item:first-child .page-link, .pagination-special .page-item:last-child .page-link {
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f4f5;
  border-radius: 50%;
  font-size: 2rem;
  padding: 0;
  transition: all 0.2s ease-in-out; }
  .pagination-special .page-item:first-child .page-link.page-link-light, .pagination-special .page-item:last-child .page-link.page-link-light {
    background-color: #f2f4f5; }
  .pagination-special .page-item:first-child .page-link.page-link-white, .pagination-special .page-item:last-child .page-link.page-link-white {
    background-color: #fff; }
  .pagination-special .page-item:first-child .page-link:hover, .pagination-special .page-item:first-child .page-link:focus, .pagination-special .page-item:last-child .page-link:hover, .pagination-special .page-item:last-child .page-link:focus {
    background-color: #7eBa27;
    color: #fff; }

.pagination-special.pagination-sm .page-item:not(:first-child):not(:last-child) .page-link::after {
  top: auto;
  right: 0.5rem;
  bottom: 0;
  left: 0.5rem;
  position: absolute; }

.pagination-special.pagination-lg .page-item:not(:first-child):not(:last-child) .page-link::after {
  top: auto;
  right: 1rem;
  bottom: 0.6rem;
  left: 1rem;
  position: absolute; }

.pager ul {
  list-style-type: none; }
  .pager ul li {
    display: inline-block; }
    .pager ul li a, .pager ul li span {
      display: inline;
      padding: .5rem; }
    .pager ul li.current-page {
      color: #7eBa27;
      text-decoration: underline; }

.collapse-toggle {
  width: 100%;
  display: block;
  padding-right: 2rem;
  position: relative; }
  .collapse-toggle:hover, .collapse-toggle:active, .collapse-toggle:focus {
    text-decoration: none; }
  .collapse-toggle::after {
    top: 0.5rem;
    right: 0.5rem;
    bottom: auto;
    left: auto;
    position: absolute;
    font-family: 'Ionicons';
    content: '\f2f4';
    line-height: 1;
    color: #dedede;
    font-size: 1.25rem; }
  .collapse-toggle.collapsed::after {
    content: '\f2c7'; }

.part .part-thumbnail {
  width: 25%;
  float: left;
  padding: 1rem; }
  .part .part-thumbnail img {
    width: 100%; }
  .part .part-thumbnail img.active {
    filter: grayscale(100%); }

.part-image-big {
  width: 60%;
  margin: auto;
  padding: 1rem; }
  .part-image-big img {
    width: 100%; }

.overlay {
  position: absolute;
  width: 120px;
  bottom: 0;
  text-align: center; }

.overlay.winter {
  color: white; }

.overlay.summer {
  color: black; }

.process {
  overflow: auto;
  text-align: center; }
  .process .process-step {
    display: inline-block;
    width: 32%;
    vertical-align: text-top; }
    .process .process-step .process-step-icon {
      padding: .25rem; }
    .process .process-step .process-step-text {
      padding: .25rem; }
      .process .process-step .process-step-text h6 {
        margin-bottom: .2rem; }
  .process img {
    width: 42px; }

.less-padding {
  padding-top: 1rem !important;
  padding-bottom: 0 !important; }

.sublist {
  list-style-type: none; }
  .sublist li {
    display: inline-block; }

.part-group .part-letter {
  display: none;
  background-color: white;
  float: left;
  width: 4%;
  height: 100%;
  text-align: center;
  padding-top: 1rem;
  margin-right: 1%; }

.part-group .part-links {
  display: inline-block;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center; }
  .part-group .part-links ul {
    margin: 0;
    padding: 0; }
  .part-group .part-links a {
    margin: .25rem;
    padding: .5rem;
    color: black;
    background-color: #f4f4f4;
    border-radius: 5px;
    display: block; }
  .part-group .part-links .categories a {
    background: none;
    text-align: left;
    margin: 0;
    line-height: 24px; }
  .part-group .part-links .categories .default-list a {
    line-height: 5px; }
  .part-group .part-links .categories .card:first-child {
    border-top: none !important; }

.mobile-only {
  display: none; }

.mobile-navigation-img {
  display: none; }

.category-image-crop {
  float: right;
  max-height: 40px; }
  .category-image-crop img {
    width: 100px; }

@media only screen and (max-width: 991px) {
  .desktop-only {
    display: none; }
  .mobile-only {
    display: block; }
  .image-cut-container {
    visibility: hidden;
    overflow: hidden;
    max-height: 25px;
    display: inline-block;
    float: right;
    margin-left: 10px; }
  .mobile-navigation-img {
    width: 66px;
    display: block;
    float: right; }
  .part-group .part-links .flex-column {
    flex-direction: inherit !important; }
  .part-group .part-links .categories a {
    white-space: normal; }
  .process .process-step {
    display: inline-block;
    width: 100%;
    text-align: left; }
    .process .process-step .process-step-icon {
      padding: .25rem;
      display: inline-block;
      vertical-align: text-top; }
    .process .process-step .process-step-text {
      padding: .25rem;
      display: inline-block;
      vertical-align: text-top; }
      .process .process-step .process-step-text p {
        display: inline-grid; }
  .process img {
    width: 21px; }
  .category-image-crop {
    max-height: 30px; }
    .category-image-crop img {
      width: 66px; } }

.table th {
  color: #1b1b1b; }

.table th,
.table td {
  padding: 0.25rem 0; }

.table td {
  vertical-align: middle; }

.ui-autocomplete {
  top: 85px !important;
  height: 260px;
  overflow: auto;
  border-radius: .4rem !important;
  border-style: none !important;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5); }
  .ui-autocomplete .ui-menu-item {
    border-style: none !important;
    cursor: pointer !important; }
    .ui-autocomplete .ui-menu-item a.ui-state-focus {
      margin: 0 !important;
      background: none !important;
      display: block !important;
      color: black !important;
      padding: 1rem !important;
      padding-left: 1.5rem !important;
      font-weight: bold !important;
      background-color: #f4f4f4 !important;
      border-style: none !important;
      color: #24a47a !important; }
  .ui-autocomplete .ui-corner-all {
    margin: 0 !important;
    display: block !important;
    color: black !important;
    padding: 1rem !important;
    padding-left: 1.5rem !important;
    font-family: verdana !important;
    font-weight: bold !important;
    font-size: .8rem !important;
    background-color: white !important;
    border-style: none !important; }
  .ui-autocomplete .ui-corner-all:hover {
    margin: 0 !important;
    display: block !important;
    color: black !important;
    padding: 1rem !important;
    padding-left: 1.5rem !important;
    font-weight: bold !important;
    background-color: #f4f4f4 !important;
    border-style: none !important;
    color: #24a47a !important; }

.slick-slider {
  position: relative; }
  .slick-slider .slick-track {
    margin-left: 0;
    margin-right: 0; }
  .slick-slider .slider-item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: auto;
    outline: none; }
  .slick-slider .slick-dots {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    text-align: center; }
    .slick-slider .slick-dots li {
      width: 10px;
      height: 10px;
      display: inline-block;
      margin: 0 5px; }
      .slick-slider .slick-dots li button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        appearance: none;
        outline: 0;
        border: 0;
        padding: 0;
        font-size: 0; }

.header {
  width: 100%;
  height: auto;
  z-index: 100;
  transition: all 0.3s ease;
  background-image: url("../images/front-bakground-image.jpg");
  background-position: top;
  background-repeat: no-repeat; }
  .header.fixed-menu-open {
    transform: translate3d(-280px, 0px, 0px); }
  .header .topheaderlinks {
    background-color: #333; }
    .header .topheaderlinks .container {
      padding: 0.5rem 1rem; }
    .header .topheaderlinks .divider {
      padding: 0 0.5rem; }
    .header .topheaderlinks .topheaderlinksinner {
      display: inline-block; }
    .header .topheaderlinks .topheaderemailphone {
      display: inline-block;
      float: right; }
      .header .topheaderlinks .topheaderemailphone i {
        margin-right: 3px; }
      .header .topheaderlinks .topheaderemailphone a {
        margin-left: 10px; }
  .header .container {
    max-width: 1200px;
    padding: 2rem 1rem 3rem 3rem; }
    @media (max-width: 767.98px) {
      .header .container {
        padding: 1rem; } }
    @media (max-width: 575.98px) {
      .header .container {
        padding-left: 0.5rem;
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0; } }
    .header .container .logo {
      flex: 0 0 180px;
      background-image: url("../images/delebil_logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center; }
      @media (max-width: 767.98px) {
        .header .container .logo {
          flex: 0 0 160px; } }
      @media (max-width: 575.98px) {
        .header .container .logo {
          flex: 0 0 120px; } }
    .header .container .navbar {
      letter-spacing: 2px; }
      .header .container .navbar .navbar-nav {
        display: flex;
        flex-direction: row;
        width: 100%; }
        .header .container .navbar .navbar-nav .nav-item {
          margin-left: 0;
          display: block;
          float: none; }
          .header .container .navbar .navbar-nav .nav-item .nav-link {
            position: relative;
            white-space: nowrap;
            font-size: 0.9rem;
            text-transform: uppercase; }
            .header .container .navbar .navbar-nav .nav-item .nav-link::before {
              top: auto;
              right: 2rem;
              bottom: 0;
              left: 2rem;
              position: absolute;
              opacity: 0;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              filter: alpha(opacity=0);
              transform: scaleX(0);
              transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
              content: '';
              border-bottom: 2px solid #303a42; }
          .header .container .navbar .navbar-nav .nav-item .dropdown-menu .nav-link {
            text-transform: none; }
        .header .container .navbar .navbar-nav > .nav-item:not(.dropdown).active .nav-link::before, .header .container .navbar .navbar-nav > .nav-item:not(.dropdown):hover .nav-link::before, .header .container .navbar .navbar-nav > .nav-item:not(.dropdown):focus .nav-link::before {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          transform: scaleX(1); }
        .header .container .navbar .navbar-nav > .nav-item:not(.dropdown).active .nav-link {
          color: #303a42; }
          .header .container .navbar .navbar-nav > .nav-item:not(.dropdown).active .nav-link::before {
            border-color: #7eBa27; }
    .header .container .right-nav .nav-link {
      text-transform: uppercase; }
      .header .container .right-nav .nav-link .icon {
        font-size: 165%;
        vertical-align: middle;
        line-height: 1; }
      .header .container .right-nav .nav-link .nav-link-text {
        padding-left: 0.5rem; }
      .header .container .right-nav .nav-link span {
        vertical-align: middle; }
      .header .container .right-nav .nav-link.separator {
        width: 2px;
        height: 100%;
        background-color: #f4f4f4;
        padding: 0; }
    .header .container #navbar-toggler {
      cursor: pointer;
      padding: 0.5rem 1rem;
      height: 2.8rem;
      margin-left: 0.2rem; }
      .header .container #navbar-toggler span {
        height: 1.5rem;
        width: 1.5rem;
        line-height: 1.5rem; }
  .header #searchContainer .container {
    padding-top: 40px;
    max-width: 1200px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto; }
  .header #searchContainer .module:last-of-type {
    padding-bottom: 1rem; }
  .header .shopping-cart {
    position: relative;
    line-height: 1rem;
    height: 2.8rem; }
    .header .shopping-cart .cart-qty {
      position: absolute;
      background-color: #f8c350;
      padding: 0px 5px;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 600;
      top: 3px;
      left: 25px;
      z-index: 1;
      line-height: 1.5; }
  .header .header-contacts {
    max-width: 1200px;
    padding: 0 1rem 0 3rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px; }
    .header .header-contacts a:first-of-type {
      float: left; }
    .header .header-contacts a:last-of-type {
      float: right; }
    @media (max-width: 767.98px) {
      .header .header-contacts {
        padding: 0 1rem 0 1rem; } }
    @media (max-width: 575.98px) {
      .header .header-contacts {
        padding: 0 0 0 0.5rem; } }

.section {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*padding: 3rem 0;*/
  position: relative;
  background-color: #fff;
  margin-top: 2rem; }
  @media (max-width: 767.98px) {
    .section {
      padding: 2.5rem 0; } }
  .section.section-lg {
    padding: 6rem 0; }
    @media (max-width: 767.98px) {
      .section.section-lg {
        padding: 4rem 0; } }
  .section.section-xl {
    padding: 8rem 0; }
    @media (max-width: 767.98px) {
      .section.section-xl {
        padding: 5rem 0; } }
  .section.section-sm {
    padding: 1.5rem 0; }
  .section.section-0 {
    padding: 0; }
  .section.intro-background-image {
    background-image: url("../images/front-bakground-image.jpg"); }
  .section .image {
    width: 100%;
    position: relative; }
  .section .carousel-caption {
    top: 50%;
    transform: translateY(-70%); }
  .section .negate-p p {
    margin-bottom: 0; }

@media (max-width: 767.98px) {
  .section-side-padding {
    padding: 2.5rem 1rem; } }

.module {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .module:first-of-type {
    padding-top: 0; }
  .module:last-of-type {
    padding-bottom: 0; }
  .module .alert-usp {
    background-color: black;
    padding: 10px;
    text-align: center;
    color: #f8c350 !important;
    font-size: 110%; }

.footer .footer-logo {
  display: flex;
  flex: 0 0 180px;
  background-image: url("../images/logotype.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  height: 45px; }

.footer .footer-klarna-logo {
  display: flex;
  flex: 0 0 180px;
  background-image: url("../images/klarna.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  height: 45px;
  width: 75px; }

.footer .nav-link {
  padding: 0.3rem 0; }

.footer.section {
  padding: 0;
  border-top: #131313 solid 3px;
  margin-top: 2rem; }

.footer .footer-small-columns {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.footer .footer-large-column .bg-secondary {
  height: 100%;
  padding: 3rem; }

.footer .col-sm-12 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 90%; }

@media (max-width: 767.98px) {
  .footer .col-sm-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .footer .bg-secondary {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem; }
  .footer .footer-small-columns {
    padding-top: 1rem;
    padding-bottom: 0; }
  .footer .footer-large-column .bg-secondary {
    height: auto;
    padding: 1rem; } }

@media (max-width: 991.98px) {
  .footer .no-gutters {
    padding-left: 0;
    padding-right: 0; } }

.search-supplier-divider {
  text-align: center;
  margin-bottom: 1rem;
  padding: 15px;
  font-weight: bold;
  color: #000;
  width: 100%; }

.licenseplate input, .licenseplate select, .licenseplate textarea {
  font-size: 7.0625rem;
  color: #FFF;
  margin-top: -2rem;
  margin-bottom: -2rem;
  font-weight: 500; }

.licenseplate .licenseplate-bar {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3rem; }

@media (max-width: 767.98px) {
  .licenseplate input, .licenseplate select, .licenseplate textarea {
    font-size: 5.0625rem;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem; }
  .licenseplate .licenseplate-bar {
    max-width: 280px; } }

@media (max-width: 575.98px) {
  .licenseplate input, .licenseplate select, .licenseplate textarea {
    font-size: 4.0625rem;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem; }
  .licenseplate .licenseplate-bar {
    max-width: 220px;
    margin-top: -3.5rem; } }

.licenseplate textarea:focus, .licenseplate input:focus {
  color: #FFF; }

.licenseplate input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAA; }

.licenseplate input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #AAA; }

.licenseplate input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #AAA; }

.list-inline-width {
  width: 5rem; }

@media (min-width: 992px) {
  .catalog-selectors {
    text-align: center; } }

@media (max-width: 1199.98px) {
  .catalog-selectors .selector-wrap select {
    padding: .6rem 1.5rem .6rem 1.2rem;
    height: auto;
    background-position: right 0.6rem center; } }

@media (max-width: 991.98px) {
  .catalog-selectors .page-sizes {
    display: block; }
  .catalog-selectors .sort-options, .catalog-selectors .supplier-options {
    width: 50%;
    display: inline-block; }
  .catalog-selectors .sort-options {
    padding-right: 1rem; }
  .catalog-selectors .supplier-options {
    padding-left: 1rem; }
  .catalog-selectors .sort-options select, .catalog-selectors .supplier-options select {
    width: 100%; } }

@media (max-width: 575.98px) {
  .catalog-selectors .sort-options, .catalog-selectors .supplier-options {
    width: 100%;
    display: block;
    padding: 0; } }

.categories-header {
  padding: 1.3rem;
  padding-left: 2.9rem;
  background-color: #1b1b1b; }
  .categories-header h6 {
    color: #fff; }
  .categories-header:before {
    position: absolute;
    left: 1.3rem;
    top: 1.15rem;
    color: #7eBa27;
    font-family: Ionicons;
    content: "\f394";
    font-size: 1.2rem; }
  .categories-header:after {
    color: #fff;
    right: 1.3rem;
    top: 1.4rem;
    content: "\f35f"; }
  .categories-header.collapsed:after {
    content: "\f363"; }

@media (min-width: 992px) {
  .overlap-columns .overlap-lg-left {
    left: -6rem; }
  .overlap-columns .overlap-lg-right {
    right: -6rem; } }

.decor-module .column > * {
  position: relative;
  z-index: 10; }

.decor-module .column::after {
  content: "";
  width: 5000px;
  height: 5rem;
  background-color: #eee;
  position: absolute;
  top: 2.4rem;
  left: -2500px;
  z-index: 0;
  display: none; }

.decor-module .column::before {
  content: "";
  width: 5000px;
  height: 1px;
  position: absolute;
  top: 5rem;
  left: -2500px;
  border-top: 2px dashed #dedede;
  z-index: 1;
  display: none; }

.decor-module .column:nth-of-type(2)::after, .decor-module .column:nth-of-type(2)::before {
  display: block; }

@media (max-width: 767.98px) {
  .decor-module .column::after, .decor-module .column::before {
    display: block; } }

.image-slider-nav .slick-list {
  margin: 0 -0.25rem; }

.image-slider-nav .slick-slide {
  margin: 0 0.25rem; }

.image-slider-nav .slider-item {
  cursor: pointer; }

.product-carousel .slick-list {
  margin: 0 -0.5rem; }
  @media (max-width: 1399px) {
    .product-carousel .slick-list {
      margin: 0 2rem;
      overflow: visible; } }
  @media (max-width: 575.98px) {
    .product-carousel .slick-list {
      margin: 0 1rem; } }

.product-carousel .slick-slide {
  margin: 0 0.5rem; }

.product-carousel .slick-arrow {
  width: 3.75rem;
  height: 3.75rem;
  top: 50%;
  right: auto;
  bottom: auto;
  left: auto;
  position: absolute;
  transform: translate(0, -50%);
  background-color: #fff;
  text-align: center;
  line-height: 1;
  display: block;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 2;
  font-size: 1.9rem; }
  .product-carousel .slick-arrow:hover {
    background-color: #7eBa27;
    color: #fff; }
  .product-carousel .slick-arrow.slick-prev {
    left: -6.5rem; }
  .product-carousel .slick-arrow.slick-next {
    right: -6.5rem; }

.arrow-list a {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 0.25rem 0 0.25rem 1rem;
  transition: all 0.2s ease-in-out;
  color: #1b1b1b; }
  .arrow-list a::before {
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    font-family: 'Ionicons';
    content: "\f125";
    line-height: 1;
    color: #adb5bd;
    font-size: 0.7rem; }
  .arrow-list a:hover, .arrow-list a:focus, .arrow-list a.active {
    margin-left: 0.5rem;
    text-decoration: none; }
    .arrow-list a:hover, .arrow-list a:hover::before, .arrow-list a:focus, .arrow-list a:focus::before, .arrow-list a.active, .arrow-list a.active::before {
      color: #7eBa27; }

.default-list li a {
  padding: 0.4rem 0.4rem 0.4rem 1.5rem;
  display: block;
  color: #303a42; }
  .default-list li a:hover, .default-list li a:focus, .default-list li a.active {
    background-color: #e7f3d6;
    border-left: solid 3px #7eBa27; }

.icon-nav .nav-item .nav-link {
  color: #1b1b1b;
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.2s ease-in-out; }
  .icon-nav .nav-item .nav-link span,
  .icon-nav .nav-item .nav-link .icon-box {
    vertical-align: middle; }
  .icon-nav .nav-item .nav-link .icon-box {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 1.25rem;
    margin-right: 0.5rem; }
  .icon-nav .nav-item .nav-link:hover, .icon-nav .nav-item .nav-link.active {
    color: #7eBa27; }

.slideout-panel {
  position: relative;
  z-index: 10; }

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden; }

.slideout-open .slideout-menu {
  display: block !important; }

.mobile-menu {
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  position: fixed;
  width: 280px;
  padding: 1.5rem 2rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
  z-index: -1; }
  .mobile-menu .mobile-menu-title {
    text-transform: uppercase;
    border-bottom: 2px solid #fff;
    display: inline-block;
    padding-bottom: 0.3rem; }
  .mobile-menu .search-box-black {
    width: 100%; }
  .mobile-menu .navbar, .mobile-menu .navbar .navbar-nav {
    width: 100%; }
  .mobile-menu .navbar .navbar-nav .nav-link {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 700;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative; }
  .mobile-menu .navbar .navbar-nav .dropdown {
    position: relative; }
    .mobile-menu .navbar .navbar-nav .dropdown .nav-link {
      display: flex;
      align-items: center; }
    .mobile-menu .navbar .navbar-nav .dropdown .nav-arrow {
      top: auto;
      right: 0;
      bottom: auto;
      left: auto;
      position: absolute;
      transition: all 0.3s ease;
      display: block;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transform-origin: center;
      -webkit-backface-visibility: hidden;
      align-items: center;
      justify-content: center;
      line-height: 1; }
      .mobile-menu .navbar .navbar-nav .dropdown .nav-arrow:after {
        font-family: "Ionicons";
        content: "\f123";
        vertical-align: middle; }
      .mobile-menu .navbar .navbar-nav .dropdown .nav-arrow.open {
        transform: rotate(180deg); }
  .mobile-menu .navbar .navbar-nav .dropdown-menu {
    background-color: transparent;
    border: 0;
    padding-left: 1.5rem; }
    .mobile-menu .navbar .navbar-nav .dropdown-menu .dropdown-item, .mobile-menu .header .container .navbar .navbar-nav .nav-item .dropdown-menu .nav-link, .header .container .mobile-menu .navbar .navbar-nav .nav-item .dropdown-menu .nav-link {
      padding-left: 0;
      padding-right: 0;
      color: #fff; }

.horizontal-overflow {
  position: relative; }
  .horizontal-overflow:before, .horizontal-overflow:after {
    width: 50px;
    height: 100%;
    top: auto;
    right: auto;
    bottom: 0;
    left: auto;
    position: absolute;
    pointer-events: none;
    z-index: 10;
    transition: opacity 0.4s ease-in-out;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    line-height: 100%;
    color: #000;
    display: flex;
    align-items: center;
    opacity: 1; }
  .horizontal-overflow:before {
    left: -1rem;
    content: '<';
    text-align: left;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff 68%);
    padding-left: 0.5rem; }
  .horizontal-overflow:after {
    right: -1rem;
    content: '>';
    text-align: right;
    background: linear-gradient(to left, #fff 32%, rgba(255, 255, 255, 0));
    justify-content: flex-end;
    padding-right: 0.5rem; }
  .horizontal-overflow.left-end:before {
    opacity: 0; }
  .horizontal-overflow.right-end:after {
    opacity: 0; }
  .horizontal-overflow > ul {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    justify-content: unset; }
    .horizontal-overflow > ul::-webkit-scrollbar, .horizontal-overflow > ul::-webkit-scrollbar-thumb, .horizontal-overflow > ul::-webkit-scrollbar-track {
      display: none; }
    .horizontal-overflow > ul li {
      margin-bottom: 0;
      flex-shrink: 0; }
      .horizontal-overflow > ul li:first-child a {
        padding-left: 0 !important; }
      .horizontal-overflow > ul li:last-child {
        margin-right: 1rem; }
        .horizontal-overflow > ul li:last-child a {
          padding-right: 1rem; }

.product-details {
  margin-top: 40px;
  width: 100%; }
  .product-details tbody tr {
    border-bottom-color: #adb5bd;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    height: 1.9rem; }
    .product-details tbody tr th {
      width: 40%;
      line-height: 3rem; }
    .product-details tbody tr td {
      width: 60%;
      line-height: 3rem; }

#filterPartCodes {
  width: 100%;
  padding: 2rem 2rem 2rem 3rem;
  background-position: 1rem; }

@media (max-width: 1199.98px) {
  .filterPartCodesWrapper {
    padding: 1rem; } }

.product-details-module {
  padding-top: 0; }

.product-details-price-buy {
  margin-top: 10px; }

.product-details-car-details div div div {
  border-bottom-color: #adb5bd;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  height: 1.9rem; }

.product-car-details-module {
  padding-top: 25px;
  padding-bottom: 25px; }
  .product-car-details-module .detail-text {
    line-height: 3rem;
    height: 3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.product-car-details-module-divider {
  padding-top: 10px;
  padding-bottom: 10px; }

.product-details-relatedproducts-price-buy-price {
  color: #000;
  text-transform: none; }
  .product-details-relatedproducts-price-buy-price h4 {
    padding-top: 10px;
    font-weight: 400;
    text-transform: none; }

.product-price-display {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.6rem;
  white-space: nowrap; }

.product-price {
  padding-top: 8px; }
  .product-price h4 {
    color: #000;
    font-weight: 500;
    text-transform: none; }

.product-search-entry {
  padding-top: 30px; }
  .product-search-entry .btn {
    padding: 0.5rem 0; }
  .product-search-entry .product-search-image-card h4 {
    font-weight: 400; }
  .product-search-entry .product-search-image-card-body {
    padding-top: 0.2rem;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .product-search-entry .product-search-image-card-body .header1 {
      font-weight: 400;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 1.45rem; }
      .product-search-entry .product-search-image-card-body .header1:hover {
        color: #61901e !important; }
    .product-search-entry .product-search-image-card-body a:hover {
      text-decoration: none; }
    .product-search-entry .product-search-image-card-body .detail-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .product-search-entry .product-search-image-card-body .grid-row {
      padding-top: 5px;
      padding-bottom: 10px; }

.product-search-filter {
  padding-bottom: 1rem; }

.cnt-spacing-0 > * {
  margin-top: 0;
  margin-bottom: 0; }
  .cnt-spacing-0 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-0 > *:last-child {
    margin-bottom: 0; }

.cnt-spacing-1 > * {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }
  .cnt-spacing-1 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-1 > *:last-child {
    margin-bottom: 0; }

.cnt-spacing-2 > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
  .cnt-spacing-2 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-2 > *:last-child {
    margin-bottom: 0; }

.cnt-spacing-3 > * {
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .cnt-spacing-3 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-3 > *:last-child {
    margin-bottom: 0; }

.cnt-spacing-4 > * {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  .cnt-spacing-4 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-4 > *:last-child {
    margin-bottom: 0; }

.cnt-spacing-5 > * {
  margin-top: 2rem;
  margin-bottom: 2rem; }
  .cnt-spacing-5 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-5 > *:last-child {
    margin-bottom: 0; }

.cnt-spacing-6 > * {
  margin-top: 3rem;
  margin-bottom: 3rem; }
  .cnt-spacing-6 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-6 > *:last-child {
    margin-bottom: 0; }

.cnt-spacing-7 > * {
  margin-top: 7rem;
  margin-bottom: 7rem; }
  .cnt-spacing-7 > *:first-child {
    margin-top: 0; }
  .cnt-spacing-7 > *:last-child {
    margin-bottom: 0; }

@media (min-width: 576px) {
  .cnt-spacing-sm-0 > * {
    margin-top: 0;
    margin-bottom: 0; }
    .cnt-spacing-sm-0 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-0 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-sm-1 > * {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
    .cnt-spacing-sm-1 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-1 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-sm-2 > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
    .cnt-spacing-sm-2 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-2 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-sm-3 > * {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .cnt-spacing-sm-3 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-3 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-sm-4 > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    .cnt-spacing-sm-4 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-4 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-sm-5 > * {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .cnt-spacing-sm-5 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-5 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-sm-6 > * {
    margin-top: 3rem;
    margin-bottom: 3rem; }
    .cnt-spacing-sm-6 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-6 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-sm-7 > * {
    margin-top: 7rem;
    margin-bottom: 7rem; }
    .cnt-spacing-sm-7 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-sm-7 > *:last-child {
      margin-bottom: 0; } }

@media (min-width: 768px) {
  .cnt-spacing-md-0 > * {
    margin-top: 0;
    margin-bottom: 0; }
    .cnt-spacing-md-0 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-0 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-md-1 > * {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
    .cnt-spacing-md-1 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-1 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-md-2 > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
    .cnt-spacing-md-2 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-2 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-md-3 > * {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .cnt-spacing-md-3 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-3 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-md-4 > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    .cnt-spacing-md-4 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-4 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-md-5 > * {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .cnt-spacing-md-5 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-5 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-md-6 > * {
    margin-top: 3rem;
    margin-bottom: 3rem; }
    .cnt-spacing-md-6 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-6 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-md-7 > * {
    margin-top: 7rem;
    margin-bottom: 7rem; }
    .cnt-spacing-md-7 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-md-7 > *:last-child {
      margin-bottom: 0; } }

@media (min-width: 992px) {
  .cnt-spacing-lg-0 > * {
    margin-top: 0;
    margin-bottom: 0; }
    .cnt-spacing-lg-0 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-0 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-lg-1 > * {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
    .cnt-spacing-lg-1 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-1 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-lg-2 > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
    .cnt-spacing-lg-2 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-2 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-lg-3 > * {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .cnt-spacing-lg-3 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-3 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-lg-4 > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    .cnt-spacing-lg-4 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-4 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-lg-5 > * {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .cnt-spacing-lg-5 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-5 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-lg-6 > * {
    margin-top: 3rem;
    margin-bottom: 3rem; }
    .cnt-spacing-lg-6 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-6 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-lg-7 > * {
    margin-top: 7rem;
    margin-bottom: 7rem; }
    .cnt-spacing-lg-7 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-lg-7 > *:last-child {
      margin-bottom: 0; } }

@media (min-width: 1200px) {
  .cnt-spacing-xl-0 > * {
    margin-top: 0;
    margin-bottom: 0; }
    .cnt-spacing-xl-0 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-0 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-xl-1 > * {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
    .cnt-spacing-xl-1 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-1 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-xl-2 > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
    .cnt-spacing-xl-2 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-2 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-xl-3 > * {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .cnt-spacing-xl-3 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-3 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-xl-4 > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    .cnt-spacing-xl-4 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-4 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-xl-5 > * {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .cnt-spacing-xl-5 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-5 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-xl-6 > * {
    margin-top: 3rem;
    margin-bottom: 3rem; }
    .cnt-spacing-xl-6 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-6 > *:last-child {
      margin-bottom: 0; }
  .cnt-spacing-xl-7 > * {
    margin-top: 7rem;
    margin-bottom: 7rem; }
    .cnt-spacing-xl-7 > *:first-child {
      margin-top: 0; }
    .cnt-spacing-xl-7 > *:last-child {
      margin-bottom: 0; } }

.ratio-1by1 {
  position: relative; }
  .ratio-1by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 100%; }
  .ratio-1by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by2 {
  position: relative; }
  .ratio-1by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 200%; }
  .ratio-1by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by3 {
  position: relative; }
  .ratio-1by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 200%; }
  .ratio-1by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by4 {
  position: relative; }
  .ratio-1by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 300%; }
  .ratio-1by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by5 {
  position: relative; }
  .ratio-1by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 500%; }
  .ratio-1by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by6 {
  position: relative; }
  .ratio-1by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 600%; }
  .ratio-1by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by7 {
  position: relative; }
  .ratio-1by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 700%; }
  .ratio-1by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by8 {
  position: relative; }
  .ratio-1by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 800%; }
  .ratio-1by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-1by9 {
  position: relative; }
  .ratio-1by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 900%; }
  .ratio-1by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by1 {
  position: relative; }
  .ratio-2by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 50%; }
  .ratio-2by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by3 {
  position: relative; }
  .ratio-2by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 150%; }
  .ratio-2by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by4 {
  position: relative; }
  .ratio-2by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 200%; }
  .ratio-2by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by5 {
  position: relative; }
  .ratio-2by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 250%; }
  .ratio-2by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by6 {
  position: relative; }
  .ratio-2by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 300%; }
  .ratio-2by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by7 {
  position: relative; }
  .ratio-2by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 350%; }
  .ratio-2by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by8 {
  position: relative; }
  .ratio-2by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 400%; }
  .ratio-2by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-2by9 {
  position: relative; }
  .ratio-2by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 450%; }
  .ratio-2by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by1 {
  position: relative; }
  .ratio-3by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 33.3333333333%; }
  .ratio-3by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by2 {
  position: relative; }
  .ratio-3by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 66.6666666667%; }
  .ratio-3by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by4 {
  position: relative; }
  .ratio-3by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 133.333333333%; }
  .ratio-3by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by5 {
  position: relative; }
  .ratio-3by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 166.666666667%; }
  .ratio-3by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by6 {
  position: relative; }
  .ratio-3by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 200%; }
  .ratio-3by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by7 {
  position: relative; }
  .ratio-3by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 233.333333333%; }
  .ratio-3by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by8 {
  position: relative; }
  .ratio-3by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 266.666666667%; }
  .ratio-3by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-3by9 {
  position: relative; }
  .ratio-3by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 300%; }
  .ratio-3by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by1 {
  position: relative; }
  .ratio-4by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 25%; }
  .ratio-4by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by2 {
  position: relative; }
  .ratio-4by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 50%; }
  .ratio-4by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by3 {
  position: relative; }
  .ratio-4by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 75%; }
  .ratio-4by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by5 {
  position: relative; }
  .ratio-4by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 125%; }
  .ratio-4by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by6 {
  position: relative; }
  .ratio-4by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 150%; }
  .ratio-4by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by7 {
  position: relative; }
  .ratio-4by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 175%; }
  .ratio-4by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by8 {
  position: relative; }
  .ratio-4by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 200%; }
  .ratio-4by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-4by9 {
  position: relative; }
  .ratio-4by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 225%; }
  .ratio-4by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by1 {
  position: relative; }
  .ratio-5by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 20%; }
  .ratio-5by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by2 {
  position: relative; }
  .ratio-5by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 40%; }
  .ratio-5by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by3 {
  position: relative; }
  .ratio-5by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 60%; }
  .ratio-5by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by4 {
  position: relative; }
  .ratio-5by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 80%; }
  .ratio-5by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by6 {
  position: relative; }
  .ratio-5by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 120%; }
  .ratio-5by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by7 {
  position: relative; }
  .ratio-5by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 140%; }
  .ratio-5by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by8 {
  position: relative; }
  .ratio-5by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 160%; }
  .ratio-5by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-5by9 {
  position: relative; }
  .ratio-5by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 180%; }
  .ratio-5by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by1 {
  position: relative; }
  .ratio-6by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 16.6666666667%; }
  .ratio-6by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by2 {
  position: relative; }
  .ratio-6by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 33.3333333333%; }
  .ratio-6by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by3 {
  position: relative; }
  .ratio-6by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 50%; }
  .ratio-6by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by4 {
  position: relative; }
  .ratio-6by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 66.6666666667%; }
  .ratio-6by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by5 {
  position: relative; }
  .ratio-6by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 83.3333333333%; }
  .ratio-6by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by7 {
  position: relative; }
  .ratio-6by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 116.666666667%; }
  .ratio-6by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by8 {
  position: relative; }
  .ratio-6by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 133.333333333%; }
  .ratio-6by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-6by9 {
  position: relative; }
  .ratio-6by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 150%; }
  .ratio-6by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by1 {
  position: relative; }
  .ratio-7by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 14.2857142857%; }
  .ratio-7by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by2 {
  position: relative; }
  .ratio-7by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 28.5714285714%; }
  .ratio-7by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by3 {
  position: relative; }
  .ratio-7by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 42.8571428571%; }
  .ratio-7by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by4 {
  position: relative; }
  .ratio-7by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 57.1428571429%; }
  .ratio-7by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by5 {
  position: relative; }
  .ratio-7by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 71.4285714286%; }
  .ratio-7by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by6 {
  position: relative; }
  .ratio-7by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 85.7142857143%; }
  .ratio-7by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by8 {
  position: relative; }
  .ratio-7by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 114.285714286%; }
  .ratio-7by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-7by9 {
  position: relative; }
  .ratio-7by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 128.571428571%; }
  .ratio-7by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by1 {
  position: relative; }
  .ratio-8by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 12.5%; }
  .ratio-8by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by2 {
  position: relative; }
  .ratio-8by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 25%; }
  .ratio-8by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by3 {
  position: relative; }
  .ratio-8by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 37.5%; }
  .ratio-8by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by4 {
  position: relative; }
  .ratio-8by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 50%; }
  .ratio-8by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by5 {
  position: relative; }
  .ratio-8by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 62.5%; }
  .ratio-8by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by6 {
  position: relative; }
  .ratio-8by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 75%; }
  .ratio-8by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by7 {
  position: relative; }
  .ratio-8by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 87.5%; }
  .ratio-8by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-8by9 {
  position: relative; }
  .ratio-8by9::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 112.5%; }
  .ratio-8by9 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by1 {
  position: relative; }
  .ratio-9by1::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 11.1111111111%; }
  .ratio-9by1 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by2 {
  position: relative; }
  .ratio-9by2::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 22.2222222222%; }
  .ratio-9by2 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by3 {
  position: relative; }
  .ratio-9by3::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 33.3333333333%; }
  .ratio-9by3 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by4 {
  position: relative; }
  .ratio-9by4::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 44.4444444444%; }
  .ratio-9by4 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by5 {
  position: relative; }
  .ratio-9by5::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 55.5555555556%; }
  .ratio-9by5 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by6 {
  position: relative; }
  .ratio-9by6::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 66.6666666667%; }
  .ratio-9by6 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by7 {
  position: relative; }
  .ratio-9by7::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 77.7777777778%; }
  .ratio-9by7 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.ratio-9by8 {
  position: relative; }
  .ratio-9by8::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 88.8888888889%; }
  .ratio-9by8 > * {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }

.verdana {
  font-family: "verdana", sans-serif; }

.brandon-grotesque {
  font-family: "BrandonGrotesque-Bold", sans-serif; }

.open-sans {
  font-family: "open sans", sans-serif; }

.font-weight-medium {
  font-weight: 500; }

.font-weight-semi-bold {
  font-weight: 600; }

.overlay-0 {
  position: relative; }
  .overlay-0:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-0:after {
        -webkit-backdrop-filter: brightness(calc(0 * 1%));
        backdrop-filter: brightness(calc(0 * 1%)); } }
  .overlay-0 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-0 {
  position: relative; }
  .overlay-rounded-0:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-0:after {
        -webkit-backdrop-filter: brightness(calc(0 * 1%));
        backdrop-filter: brightness(calc(0 * 1%)); } }
  .overlay-rounded-0 > * {
    position: relative;
    z-index: 1; }

.overlay-1 {
  position: relative; }
  .overlay-1:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-1:after {
        -webkit-backdrop-filter: brightness(calc(1 * 1%));
        backdrop-filter: brightness(calc(1 * 1%)); } }
  .overlay-1 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-1 {
  position: relative; }
  .overlay-rounded-1:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-1:after {
        -webkit-backdrop-filter: brightness(calc(1 * 1%));
        backdrop-filter: brightness(calc(1 * 1%)); } }
  .overlay-rounded-1 > * {
    position: relative;
    z-index: 1; }

.overlay-2 {
  position: relative; }
  .overlay-2:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-2:after {
        -webkit-backdrop-filter: brightness(calc(2 * 1%));
        backdrop-filter: brightness(calc(2 * 1%)); } }
  .overlay-2 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-2 {
  position: relative; }
  .overlay-rounded-2:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-2:after {
        -webkit-backdrop-filter: brightness(calc(2 * 1%));
        backdrop-filter: brightness(calc(2 * 1%)); } }
  .overlay-rounded-2 > * {
    position: relative;
    z-index: 1; }

.overlay-3 {
  position: relative; }
  .overlay-3:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-3:after {
        -webkit-backdrop-filter: brightness(calc(3 * 1%));
        backdrop-filter: brightness(calc(3 * 1%)); } }
  .overlay-3 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-3 {
  position: relative; }
  .overlay-rounded-3:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-3:after {
        -webkit-backdrop-filter: brightness(calc(3 * 1%));
        backdrop-filter: brightness(calc(3 * 1%)); } }
  .overlay-rounded-3 > * {
    position: relative;
    z-index: 1; }

.overlay-4 {
  position: relative; }
  .overlay-4:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-4:after {
        -webkit-backdrop-filter: brightness(calc(4 * 1%));
        backdrop-filter: brightness(calc(4 * 1%)); } }
  .overlay-4 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-4 {
  position: relative; }
  .overlay-rounded-4:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-4:after {
        -webkit-backdrop-filter: brightness(calc(4 * 1%));
        backdrop-filter: brightness(calc(4 * 1%)); } }
  .overlay-rounded-4 > * {
    position: relative;
    z-index: 1; }

.overlay-5 {
  position: relative; }
  .overlay-5:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-5:after {
        -webkit-backdrop-filter: brightness(calc(5 * 1%));
        backdrop-filter: brightness(calc(5 * 1%)); } }
  .overlay-5 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-5 {
  position: relative; }
  .overlay-rounded-5:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-5:after {
        -webkit-backdrop-filter: brightness(calc(5 * 1%));
        backdrop-filter: brightness(calc(5 * 1%)); } }
  .overlay-rounded-5 > * {
    position: relative;
    z-index: 1; }

.overlay-6 {
  position: relative; }
  .overlay-6:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-6:after {
        -webkit-backdrop-filter: brightness(calc(6 * 1%));
        backdrop-filter: brightness(calc(6 * 1%)); } }
  .overlay-6 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-6 {
  position: relative; }
  .overlay-rounded-6:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-6:after {
        -webkit-backdrop-filter: brightness(calc(6 * 1%));
        backdrop-filter: brightness(calc(6 * 1%)); } }
  .overlay-rounded-6 > * {
    position: relative;
    z-index: 1; }

.overlay-7 {
  position: relative; }
  .overlay-7:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-7:after {
        -webkit-backdrop-filter: brightness(calc(7 * 1%));
        backdrop-filter: brightness(calc(7 * 1%)); } }
  .overlay-7 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-7 {
  position: relative; }
  .overlay-rounded-7:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-7:after {
        -webkit-backdrop-filter: brightness(calc(7 * 1%));
        backdrop-filter: brightness(calc(7 * 1%)); } }
  .overlay-rounded-7 > * {
    position: relative;
    z-index: 1; }

.overlay-8 {
  position: relative; }
  .overlay-8:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-8:after {
        -webkit-backdrop-filter: brightness(calc(8 * 1%));
        backdrop-filter: brightness(calc(8 * 1%)); } }
  .overlay-8 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-8 {
  position: relative; }
  .overlay-rounded-8:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-8:after {
        -webkit-backdrop-filter: brightness(calc(8 * 1%));
        backdrop-filter: brightness(calc(8 * 1%)); } }
  .overlay-rounded-8 > * {
    position: relative;
    z-index: 1; }

.overlay-9 {
  position: relative; }
  .overlay-9:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-9:after {
        -webkit-backdrop-filter: brightness(calc(9 * 1%));
        backdrop-filter: brightness(calc(9 * 1%)); } }
  .overlay-9 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-9 {
  position: relative; }
  .overlay-rounded-9:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-9:after {
        -webkit-backdrop-filter: brightness(calc(9 * 1%));
        backdrop-filter: brightness(calc(9 * 1%)); } }
  .overlay-rounded-9 > * {
    position: relative;
    z-index: 1; }

.overlay-10 {
  position: relative; }
  .overlay-10:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-10:after {
        -webkit-backdrop-filter: brightness(calc(10 * 1%));
        backdrop-filter: brightness(calc(10 * 1%)); } }
  .overlay-10 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-10 {
  position: relative; }
  .overlay-rounded-10:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-10:after {
        -webkit-backdrop-filter: brightness(calc(10 * 1%));
        backdrop-filter: brightness(calc(10 * 1%)); } }
  .overlay-rounded-10 > * {
    position: relative;
    z-index: 1; }

.overlay-11 {
  position: relative; }
  .overlay-11:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-11:after {
        -webkit-backdrop-filter: brightness(calc(11 * 1%));
        backdrop-filter: brightness(calc(11 * 1%)); } }
  .overlay-11 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-11 {
  position: relative; }
  .overlay-rounded-11:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-11:after {
        -webkit-backdrop-filter: brightness(calc(11 * 1%));
        backdrop-filter: brightness(calc(11 * 1%)); } }
  .overlay-rounded-11 > * {
    position: relative;
    z-index: 1; }

.overlay-12 {
  position: relative; }
  .overlay-12:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-12:after {
        -webkit-backdrop-filter: brightness(calc(12 * 1%));
        backdrop-filter: brightness(calc(12 * 1%)); } }
  .overlay-12 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-12 {
  position: relative; }
  .overlay-rounded-12:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-12:after {
        -webkit-backdrop-filter: brightness(calc(12 * 1%));
        backdrop-filter: brightness(calc(12 * 1%)); } }
  .overlay-rounded-12 > * {
    position: relative;
    z-index: 1; }

.overlay-13 {
  position: relative; }
  .overlay-13:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-13:after {
        -webkit-backdrop-filter: brightness(calc(13 * 1%));
        backdrop-filter: brightness(calc(13 * 1%)); } }
  .overlay-13 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-13 {
  position: relative; }
  .overlay-rounded-13:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-13:after {
        -webkit-backdrop-filter: brightness(calc(13 * 1%));
        backdrop-filter: brightness(calc(13 * 1%)); } }
  .overlay-rounded-13 > * {
    position: relative;
    z-index: 1; }

.overlay-14 {
  position: relative; }
  .overlay-14:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-14:after {
        -webkit-backdrop-filter: brightness(calc(14 * 1%));
        backdrop-filter: brightness(calc(14 * 1%)); } }
  .overlay-14 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-14 {
  position: relative; }
  .overlay-rounded-14:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-14:after {
        -webkit-backdrop-filter: brightness(calc(14 * 1%));
        backdrop-filter: brightness(calc(14 * 1%)); } }
  .overlay-rounded-14 > * {
    position: relative;
    z-index: 1; }

.overlay-15 {
  position: relative; }
  .overlay-15:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-15:after {
        -webkit-backdrop-filter: brightness(calc(15 * 1%));
        backdrop-filter: brightness(calc(15 * 1%)); } }
  .overlay-15 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-15 {
  position: relative; }
  .overlay-rounded-15:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-15:after {
        -webkit-backdrop-filter: brightness(calc(15 * 1%));
        backdrop-filter: brightness(calc(15 * 1%)); } }
  .overlay-rounded-15 > * {
    position: relative;
    z-index: 1; }

.overlay-16 {
  position: relative; }
  .overlay-16:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-16:after {
        -webkit-backdrop-filter: brightness(calc(16 * 1%));
        backdrop-filter: brightness(calc(16 * 1%)); } }
  .overlay-16 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-16 {
  position: relative; }
  .overlay-rounded-16:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-16:after {
        -webkit-backdrop-filter: brightness(calc(16 * 1%));
        backdrop-filter: brightness(calc(16 * 1%)); } }
  .overlay-rounded-16 > * {
    position: relative;
    z-index: 1; }

.overlay-17 {
  position: relative; }
  .overlay-17:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-17:after {
        -webkit-backdrop-filter: brightness(calc(17 * 1%));
        backdrop-filter: brightness(calc(17 * 1%)); } }
  .overlay-17 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-17 {
  position: relative; }
  .overlay-rounded-17:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-17:after {
        -webkit-backdrop-filter: brightness(calc(17 * 1%));
        backdrop-filter: brightness(calc(17 * 1%)); } }
  .overlay-rounded-17 > * {
    position: relative;
    z-index: 1; }

.overlay-18 {
  position: relative; }
  .overlay-18:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-18:after {
        -webkit-backdrop-filter: brightness(calc(18 * 1%));
        backdrop-filter: brightness(calc(18 * 1%)); } }
  .overlay-18 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-18 {
  position: relative; }
  .overlay-rounded-18:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-18:after {
        -webkit-backdrop-filter: brightness(calc(18 * 1%));
        backdrop-filter: brightness(calc(18 * 1%)); } }
  .overlay-rounded-18 > * {
    position: relative;
    z-index: 1; }

.overlay-19 {
  position: relative; }
  .overlay-19:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-19:after {
        -webkit-backdrop-filter: brightness(calc(19 * 1%));
        backdrop-filter: brightness(calc(19 * 1%)); } }
  .overlay-19 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-19 {
  position: relative; }
  .overlay-rounded-19:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-19:after {
        -webkit-backdrop-filter: brightness(calc(19 * 1%));
        backdrop-filter: brightness(calc(19 * 1%)); } }
  .overlay-rounded-19 > * {
    position: relative;
    z-index: 1; }

.overlay-20 {
  position: relative; }
  .overlay-20:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-20:after {
        -webkit-backdrop-filter: brightness(calc(20 * 1%));
        backdrop-filter: brightness(calc(20 * 1%)); } }
  .overlay-20 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-20 {
  position: relative; }
  .overlay-rounded-20:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-20:after {
        -webkit-backdrop-filter: brightness(calc(20 * 1%));
        backdrop-filter: brightness(calc(20 * 1%)); } }
  .overlay-rounded-20 > * {
    position: relative;
    z-index: 1; }

.overlay-21 {
  position: relative; }
  .overlay-21:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-21:after {
        -webkit-backdrop-filter: brightness(calc(21 * 1%));
        backdrop-filter: brightness(calc(21 * 1%)); } }
  .overlay-21 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-21 {
  position: relative; }
  .overlay-rounded-21:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-21:after {
        -webkit-backdrop-filter: brightness(calc(21 * 1%));
        backdrop-filter: brightness(calc(21 * 1%)); } }
  .overlay-rounded-21 > * {
    position: relative;
    z-index: 1; }

.overlay-22 {
  position: relative; }
  .overlay-22:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-22:after {
        -webkit-backdrop-filter: brightness(calc(22 * 1%));
        backdrop-filter: brightness(calc(22 * 1%)); } }
  .overlay-22 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-22 {
  position: relative; }
  .overlay-rounded-22:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-22:after {
        -webkit-backdrop-filter: brightness(calc(22 * 1%));
        backdrop-filter: brightness(calc(22 * 1%)); } }
  .overlay-rounded-22 > * {
    position: relative;
    z-index: 1; }

.overlay-23 {
  position: relative; }
  .overlay-23:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-23:after {
        -webkit-backdrop-filter: brightness(calc(23 * 1%));
        backdrop-filter: brightness(calc(23 * 1%)); } }
  .overlay-23 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-23 {
  position: relative; }
  .overlay-rounded-23:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-23:after {
        -webkit-backdrop-filter: brightness(calc(23 * 1%));
        backdrop-filter: brightness(calc(23 * 1%)); } }
  .overlay-rounded-23 > * {
    position: relative;
    z-index: 1; }

.overlay-24 {
  position: relative; }
  .overlay-24:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-24:after {
        -webkit-backdrop-filter: brightness(calc(24 * 1%));
        backdrop-filter: brightness(calc(24 * 1%)); } }
  .overlay-24 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-24 {
  position: relative; }
  .overlay-rounded-24:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-24:after {
        -webkit-backdrop-filter: brightness(calc(24 * 1%));
        backdrop-filter: brightness(calc(24 * 1%)); } }
  .overlay-rounded-24 > * {
    position: relative;
    z-index: 1; }

.overlay-25 {
  position: relative; }
  .overlay-25:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-25:after {
        -webkit-backdrop-filter: brightness(calc(25 * 1%));
        backdrop-filter: brightness(calc(25 * 1%)); } }
  .overlay-25 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-25 {
  position: relative; }
  .overlay-rounded-25:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-25:after {
        -webkit-backdrop-filter: brightness(calc(25 * 1%));
        backdrop-filter: brightness(calc(25 * 1%)); } }
  .overlay-rounded-25 > * {
    position: relative;
    z-index: 1; }

.overlay-26 {
  position: relative; }
  .overlay-26:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-26:after {
        -webkit-backdrop-filter: brightness(calc(26 * 1%));
        backdrop-filter: brightness(calc(26 * 1%)); } }
  .overlay-26 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-26 {
  position: relative; }
  .overlay-rounded-26:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-26:after {
        -webkit-backdrop-filter: brightness(calc(26 * 1%));
        backdrop-filter: brightness(calc(26 * 1%)); } }
  .overlay-rounded-26 > * {
    position: relative;
    z-index: 1; }

.overlay-27 {
  position: relative; }
  .overlay-27:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-27:after {
        -webkit-backdrop-filter: brightness(calc(27 * 1%));
        backdrop-filter: brightness(calc(27 * 1%)); } }
  .overlay-27 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-27 {
  position: relative; }
  .overlay-rounded-27:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-27:after {
        -webkit-backdrop-filter: brightness(calc(27 * 1%));
        backdrop-filter: brightness(calc(27 * 1%)); } }
  .overlay-rounded-27 > * {
    position: relative;
    z-index: 1; }

.overlay-28 {
  position: relative; }
  .overlay-28:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-28:after {
        -webkit-backdrop-filter: brightness(calc(28 * 1%));
        backdrop-filter: brightness(calc(28 * 1%)); } }
  .overlay-28 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-28 {
  position: relative; }
  .overlay-rounded-28:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-28:after {
        -webkit-backdrop-filter: brightness(calc(28 * 1%));
        backdrop-filter: brightness(calc(28 * 1%)); } }
  .overlay-rounded-28 > * {
    position: relative;
    z-index: 1; }

.overlay-29 {
  position: relative; }
  .overlay-29:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-29:after {
        -webkit-backdrop-filter: brightness(calc(29 * 1%));
        backdrop-filter: brightness(calc(29 * 1%)); } }
  .overlay-29 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-29 {
  position: relative; }
  .overlay-rounded-29:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-29:after {
        -webkit-backdrop-filter: brightness(calc(29 * 1%));
        backdrop-filter: brightness(calc(29 * 1%)); } }
  .overlay-rounded-29 > * {
    position: relative;
    z-index: 1; }

.overlay-30 {
  position: relative; }
  .overlay-30:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-30:after {
        -webkit-backdrop-filter: brightness(calc(30 * 1%));
        backdrop-filter: brightness(calc(30 * 1%)); } }
  .overlay-30 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-30 {
  position: relative; }
  .overlay-rounded-30:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-30:after {
        -webkit-backdrop-filter: brightness(calc(30 * 1%));
        backdrop-filter: brightness(calc(30 * 1%)); } }
  .overlay-rounded-30 > * {
    position: relative;
    z-index: 1; }

.overlay-31 {
  position: relative; }
  .overlay-31:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-31:after {
        -webkit-backdrop-filter: brightness(calc(31 * 1%));
        backdrop-filter: brightness(calc(31 * 1%)); } }
  .overlay-31 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-31 {
  position: relative; }
  .overlay-rounded-31:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-31:after {
        -webkit-backdrop-filter: brightness(calc(31 * 1%));
        backdrop-filter: brightness(calc(31 * 1%)); } }
  .overlay-rounded-31 > * {
    position: relative;
    z-index: 1; }

.overlay-32 {
  position: relative; }
  .overlay-32:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-32:after {
        -webkit-backdrop-filter: brightness(calc(32 * 1%));
        backdrop-filter: brightness(calc(32 * 1%)); } }
  .overlay-32 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-32 {
  position: relative; }
  .overlay-rounded-32:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-32:after {
        -webkit-backdrop-filter: brightness(calc(32 * 1%));
        backdrop-filter: brightness(calc(32 * 1%)); } }
  .overlay-rounded-32 > * {
    position: relative;
    z-index: 1; }

.overlay-33 {
  position: relative; }
  .overlay-33:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-33:after {
        -webkit-backdrop-filter: brightness(calc(33 * 1%));
        backdrop-filter: brightness(calc(33 * 1%)); } }
  .overlay-33 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-33 {
  position: relative; }
  .overlay-rounded-33:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-33:after {
        -webkit-backdrop-filter: brightness(calc(33 * 1%));
        backdrop-filter: brightness(calc(33 * 1%)); } }
  .overlay-rounded-33 > * {
    position: relative;
    z-index: 1; }

.overlay-34 {
  position: relative; }
  .overlay-34:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-34:after {
        -webkit-backdrop-filter: brightness(calc(34 * 1%));
        backdrop-filter: brightness(calc(34 * 1%)); } }
  .overlay-34 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-34 {
  position: relative; }
  .overlay-rounded-34:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-34:after {
        -webkit-backdrop-filter: brightness(calc(34 * 1%));
        backdrop-filter: brightness(calc(34 * 1%)); } }
  .overlay-rounded-34 > * {
    position: relative;
    z-index: 1; }

.overlay-35 {
  position: relative; }
  .overlay-35:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-35:after {
        -webkit-backdrop-filter: brightness(calc(35 * 1%));
        backdrop-filter: brightness(calc(35 * 1%)); } }
  .overlay-35 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-35 {
  position: relative; }
  .overlay-rounded-35:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-35:after {
        -webkit-backdrop-filter: brightness(calc(35 * 1%));
        backdrop-filter: brightness(calc(35 * 1%)); } }
  .overlay-rounded-35 > * {
    position: relative;
    z-index: 1; }

.overlay-36 {
  position: relative; }
  .overlay-36:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-36:after {
        -webkit-backdrop-filter: brightness(calc(36 * 1%));
        backdrop-filter: brightness(calc(36 * 1%)); } }
  .overlay-36 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-36 {
  position: relative; }
  .overlay-rounded-36:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-36:after {
        -webkit-backdrop-filter: brightness(calc(36 * 1%));
        backdrop-filter: brightness(calc(36 * 1%)); } }
  .overlay-rounded-36 > * {
    position: relative;
    z-index: 1; }

.overlay-37 {
  position: relative; }
  .overlay-37:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-37:after {
        -webkit-backdrop-filter: brightness(calc(37 * 1%));
        backdrop-filter: brightness(calc(37 * 1%)); } }
  .overlay-37 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-37 {
  position: relative; }
  .overlay-rounded-37:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-37:after {
        -webkit-backdrop-filter: brightness(calc(37 * 1%));
        backdrop-filter: brightness(calc(37 * 1%)); } }
  .overlay-rounded-37 > * {
    position: relative;
    z-index: 1; }

.overlay-38 {
  position: relative; }
  .overlay-38:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-38:after {
        -webkit-backdrop-filter: brightness(calc(38 * 1%));
        backdrop-filter: brightness(calc(38 * 1%)); } }
  .overlay-38 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-38 {
  position: relative; }
  .overlay-rounded-38:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-38:after {
        -webkit-backdrop-filter: brightness(calc(38 * 1%));
        backdrop-filter: brightness(calc(38 * 1%)); } }
  .overlay-rounded-38 > * {
    position: relative;
    z-index: 1; }

.overlay-39 {
  position: relative; }
  .overlay-39:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-39:after {
        -webkit-backdrop-filter: brightness(calc(39 * 1%));
        backdrop-filter: brightness(calc(39 * 1%)); } }
  .overlay-39 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-39 {
  position: relative; }
  .overlay-rounded-39:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-39:after {
        -webkit-backdrop-filter: brightness(calc(39 * 1%));
        backdrop-filter: brightness(calc(39 * 1%)); } }
  .overlay-rounded-39 > * {
    position: relative;
    z-index: 1; }

.overlay-40 {
  position: relative; }
  .overlay-40:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-40:after {
        -webkit-backdrop-filter: brightness(calc(40 * 1%));
        backdrop-filter: brightness(calc(40 * 1%)); } }
  .overlay-40 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-40 {
  position: relative; }
  .overlay-rounded-40:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-40:after {
        -webkit-backdrop-filter: brightness(calc(40 * 1%));
        backdrop-filter: brightness(calc(40 * 1%)); } }
  .overlay-rounded-40 > * {
    position: relative;
    z-index: 1; }

.overlay-41 {
  position: relative; }
  .overlay-41:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-41:after {
        -webkit-backdrop-filter: brightness(calc(41 * 1%));
        backdrop-filter: brightness(calc(41 * 1%)); } }
  .overlay-41 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-41 {
  position: relative; }
  .overlay-rounded-41:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-41:after {
        -webkit-backdrop-filter: brightness(calc(41 * 1%));
        backdrop-filter: brightness(calc(41 * 1%)); } }
  .overlay-rounded-41 > * {
    position: relative;
    z-index: 1; }

.overlay-42 {
  position: relative; }
  .overlay-42:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-42:after {
        -webkit-backdrop-filter: brightness(calc(42 * 1%));
        backdrop-filter: brightness(calc(42 * 1%)); } }
  .overlay-42 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-42 {
  position: relative; }
  .overlay-rounded-42:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-42:after {
        -webkit-backdrop-filter: brightness(calc(42 * 1%));
        backdrop-filter: brightness(calc(42 * 1%)); } }
  .overlay-rounded-42 > * {
    position: relative;
    z-index: 1; }

.overlay-43 {
  position: relative; }
  .overlay-43:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-43:after {
        -webkit-backdrop-filter: brightness(calc(43 * 1%));
        backdrop-filter: brightness(calc(43 * 1%)); } }
  .overlay-43 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-43 {
  position: relative; }
  .overlay-rounded-43:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-43:after {
        -webkit-backdrop-filter: brightness(calc(43 * 1%));
        backdrop-filter: brightness(calc(43 * 1%)); } }
  .overlay-rounded-43 > * {
    position: relative;
    z-index: 1; }

.overlay-44 {
  position: relative; }
  .overlay-44:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-44:after {
        -webkit-backdrop-filter: brightness(calc(44 * 1%));
        backdrop-filter: brightness(calc(44 * 1%)); } }
  .overlay-44 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-44 {
  position: relative; }
  .overlay-rounded-44:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-44:after {
        -webkit-backdrop-filter: brightness(calc(44 * 1%));
        backdrop-filter: brightness(calc(44 * 1%)); } }
  .overlay-rounded-44 > * {
    position: relative;
    z-index: 1; }

.overlay-45 {
  position: relative; }
  .overlay-45:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-45:after {
        -webkit-backdrop-filter: brightness(calc(45 * 1%));
        backdrop-filter: brightness(calc(45 * 1%)); } }
  .overlay-45 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-45 {
  position: relative; }
  .overlay-rounded-45:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-45:after {
        -webkit-backdrop-filter: brightness(calc(45 * 1%));
        backdrop-filter: brightness(calc(45 * 1%)); } }
  .overlay-rounded-45 > * {
    position: relative;
    z-index: 1; }

.overlay-46 {
  position: relative; }
  .overlay-46:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-46:after {
        -webkit-backdrop-filter: brightness(calc(46 * 1%));
        backdrop-filter: brightness(calc(46 * 1%)); } }
  .overlay-46 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-46 {
  position: relative; }
  .overlay-rounded-46:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-46:after {
        -webkit-backdrop-filter: brightness(calc(46 * 1%));
        backdrop-filter: brightness(calc(46 * 1%)); } }
  .overlay-rounded-46 > * {
    position: relative;
    z-index: 1; }

.overlay-47 {
  position: relative; }
  .overlay-47:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-47:after {
        -webkit-backdrop-filter: brightness(calc(47 * 1%));
        backdrop-filter: brightness(calc(47 * 1%)); } }
  .overlay-47 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-47 {
  position: relative; }
  .overlay-rounded-47:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-47:after {
        -webkit-backdrop-filter: brightness(calc(47 * 1%));
        backdrop-filter: brightness(calc(47 * 1%)); } }
  .overlay-rounded-47 > * {
    position: relative;
    z-index: 1; }

.overlay-48 {
  position: relative; }
  .overlay-48:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-48:after {
        -webkit-backdrop-filter: brightness(calc(48 * 1%));
        backdrop-filter: brightness(calc(48 * 1%)); } }
  .overlay-48 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-48 {
  position: relative; }
  .overlay-rounded-48:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-48:after {
        -webkit-backdrop-filter: brightness(calc(48 * 1%));
        backdrop-filter: brightness(calc(48 * 1%)); } }
  .overlay-rounded-48 > * {
    position: relative;
    z-index: 1; }

.overlay-49 {
  position: relative; }
  .overlay-49:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-49:after {
        -webkit-backdrop-filter: brightness(calc(49 * 1%));
        backdrop-filter: brightness(calc(49 * 1%)); } }
  .overlay-49 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-49 {
  position: relative; }
  .overlay-rounded-49:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-49:after {
        -webkit-backdrop-filter: brightness(calc(49 * 1%));
        backdrop-filter: brightness(calc(49 * 1%)); } }
  .overlay-rounded-49 > * {
    position: relative;
    z-index: 1; }

.overlay-50 {
  position: relative; }
  .overlay-50:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-50:after {
        -webkit-backdrop-filter: brightness(calc(50 * 1%));
        backdrop-filter: brightness(calc(50 * 1%)); } }
  .overlay-50 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-50 {
  position: relative; }
  .overlay-rounded-50:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-50:after {
        -webkit-backdrop-filter: brightness(calc(50 * 1%));
        backdrop-filter: brightness(calc(50 * 1%)); } }
  .overlay-rounded-50 > * {
    position: relative;
    z-index: 1; }

.overlay-51 {
  position: relative; }
  .overlay-51:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-51:after {
        -webkit-backdrop-filter: brightness(calc(51 * 1%));
        backdrop-filter: brightness(calc(51 * 1%)); } }
  .overlay-51 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-51 {
  position: relative; }
  .overlay-rounded-51:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-51:after {
        -webkit-backdrop-filter: brightness(calc(51 * 1%));
        backdrop-filter: brightness(calc(51 * 1%)); } }
  .overlay-rounded-51 > * {
    position: relative;
    z-index: 1; }

.overlay-52 {
  position: relative; }
  .overlay-52:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-52:after {
        -webkit-backdrop-filter: brightness(calc(52 * 1%));
        backdrop-filter: brightness(calc(52 * 1%)); } }
  .overlay-52 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-52 {
  position: relative; }
  .overlay-rounded-52:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-52:after {
        -webkit-backdrop-filter: brightness(calc(52 * 1%));
        backdrop-filter: brightness(calc(52 * 1%)); } }
  .overlay-rounded-52 > * {
    position: relative;
    z-index: 1; }

.overlay-53 {
  position: relative; }
  .overlay-53:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-53:after {
        -webkit-backdrop-filter: brightness(calc(53 * 1%));
        backdrop-filter: brightness(calc(53 * 1%)); } }
  .overlay-53 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-53 {
  position: relative; }
  .overlay-rounded-53:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-53:after {
        -webkit-backdrop-filter: brightness(calc(53 * 1%));
        backdrop-filter: brightness(calc(53 * 1%)); } }
  .overlay-rounded-53 > * {
    position: relative;
    z-index: 1; }

.overlay-54 {
  position: relative; }
  .overlay-54:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-54:after {
        -webkit-backdrop-filter: brightness(calc(54 * 1%));
        backdrop-filter: brightness(calc(54 * 1%)); } }
  .overlay-54 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-54 {
  position: relative; }
  .overlay-rounded-54:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-54:after {
        -webkit-backdrop-filter: brightness(calc(54 * 1%));
        backdrop-filter: brightness(calc(54 * 1%)); } }
  .overlay-rounded-54 > * {
    position: relative;
    z-index: 1; }

.overlay-55 {
  position: relative; }
  .overlay-55:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-55:after {
        -webkit-backdrop-filter: brightness(calc(55 * 1%));
        backdrop-filter: brightness(calc(55 * 1%)); } }
  .overlay-55 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-55 {
  position: relative; }
  .overlay-rounded-55:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-55:after {
        -webkit-backdrop-filter: brightness(calc(55 * 1%));
        backdrop-filter: brightness(calc(55 * 1%)); } }
  .overlay-rounded-55 > * {
    position: relative;
    z-index: 1; }

.overlay-56 {
  position: relative; }
  .overlay-56:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-56:after {
        -webkit-backdrop-filter: brightness(calc(56 * 1%));
        backdrop-filter: brightness(calc(56 * 1%)); } }
  .overlay-56 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-56 {
  position: relative; }
  .overlay-rounded-56:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-56:after {
        -webkit-backdrop-filter: brightness(calc(56 * 1%));
        backdrop-filter: brightness(calc(56 * 1%)); } }
  .overlay-rounded-56 > * {
    position: relative;
    z-index: 1; }

.overlay-57 {
  position: relative; }
  .overlay-57:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-57:after {
        -webkit-backdrop-filter: brightness(calc(57 * 1%));
        backdrop-filter: brightness(calc(57 * 1%)); } }
  .overlay-57 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-57 {
  position: relative; }
  .overlay-rounded-57:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-57:after {
        -webkit-backdrop-filter: brightness(calc(57 * 1%));
        backdrop-filter: brightness(calc(57 * 1%)); } }
  .overlay-rounded-57 > * {
    position: relative;
    z-index: 1; }

.overlay-58 {
  position: relative; }
  .overlay-58:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-58:after {
        -webkit-backdrop-filter: brightness(calc(58 * 1%));
        backdrop-filter: brightness(calc(58 * 1%)); } }
  .overlay-58 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-58 {
  position: relative; }
  .overlay-rounded-58:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-58:after {
        -webkit-backdrop-filter: brightness(calc(58 * 1%));
        backdrop-filter: brightness(calc(58 * 1%)); } }
  .overlay-rounded-58 > * {
    position: relative;
    z-index: 1; }

.overlay-59 {
  position: relative; }
  .overlay-59:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-59:after {
        -webkit-backdrop-filter: brightness(calc(59 * 1%));
        backdrop-filter: brightness(calc(59 * 1%)); } }
  .overlay-59 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-59 {
  position: relative; }
  .overlay-rounded-59:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-59:after {
        -webkit-backdrop-filter: brightness(calc(59 * 1%));
        backdrop-filter: brightness(calc(59 * 1%)); } }
  .overlay-rounded-59 > * {
    position: relative;
    z-index: 1; }

.overlay-60 {
  position: relative; }
  .overlay-60:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-60:after {
        -webkit-backdrop-filter: brightness(calc(60 * 1%));
        backdrop-filter: brightness(calc(60 * 1%)); } }
  .overlay-60 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-60 {
  position: relative; }
  .overlay-rounded-60:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-60:after {
        -webkit-backdrop-filter: brightness(calc(60 * 1%));
        backdrop-filter: brightness(calc(60 * 1%)); } }
  .overlay-rounded-60 > * {
    position: relative;
    z-index: 1; }

.overlay-61 {
  position: relative; }
  .overlay-61:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-61:after {
        -webkit-backdrop-filter: brightness(calc(61 * 1%));
        backdrop-filter: brightness(calc(61 * 1%)); } }
  .overlay-61 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-61 {
  position: relative; }
  .overlay-rounded-61:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-61:after {
        -webkit-backdrop-filter: brightness(calc(61 * 1%));
        backdrop-filter: brightness(calc(61 * 1%)); } }
  .overlay-rounded-61 > * {
    position: relative;
    z-index: 1; }

.overlay-62 {
  position: relative; }
  .overlay-62:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-62:after {
        -webkit-backdrop-filter: brightness(calc(62 * 1%));
        backdrop-filter: brightness(calc(62 * 1%)); } }
  .overlay-62 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-62 {
  position: relative; }
  .overlay-rounded-62:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-62:after {
        -webkit-backdrop-filter: brightness(calc(62 * 1%));
        backdrop-filter: brightness(calc(62 * 1%)); } }
  .overlay-rounded-62 > * {
    position: relative;
    z-index: 1; }

.overlay-63 {
  position: relative; }
  .overlay-63:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-63:after {
        -webkit-backdrop-filter: brightness(calc(63 * 1%));
        backdrop-filter: brightness(calc(63 * 1%)); } }
  .overlay-63 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-63 {
  position: relative; }
  .overlay-rounded-63:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-63:after {
        -webkit-backdrop-filter: brightness(calc(63 * 1%));
        backdrop-filter: brightness(calc(63 * 1%)); } }
  .overlay-rounded-63 > * {
    position: relative;
    z-index: 1; }

.overlay-64 {
  position: relative; }
  .overlay-64:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-64:after {
        -webkit-backdrop-filter: brightness(calc(64 * 1%));
        backdrop-filter: brightness(calc(64 * 1%)); } }
  .overlay-64 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-64 {
  position: relative; }
  .overlay-rounded-64:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-64:after {
        -webkit-backdrop-filter: brightness(calc(64 * 1%));
        backdrop-filter: brightness(calc(64 * 1%)); } }
  .overlay-rounded-64 > * {
    position: relative;
    z-index: 1; }

.overlay-65 {
  position: relative; }
  .overlay-65:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-65:after {
        -webkit-backdrop-filter: brightness(calc(65 * 1%));
        backdrop-filter: brightness(calc(65 * 1%)); } }
  .overlay-65 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-65 {
  position: relative; }
  .overlay-rounded-65:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-65:after {
        -webkit-backdrop-filter: brightness(calc(65 * 1%));
        backdrop-filter: brightness(calc(65 * 1%)); } }
  .overlay-rounded-65 > * {
    position: relative;
    z-index: 1; }

.overlay-66 {
  position: relative; }
  .overlay-66:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-66:after {
        -webkit-backdrop-filter: brightness(calc(66 * 1%));
        backdrop-filter: brightness(calc(66 * 1%)); } }
  .overlay-66 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-66 {
  position: relative; }
  .overlay-rounded-66:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-66:after {
        -webkit-backdrop-filter: brightness(calc(66 * 1%));
        backdrop-filter: brightness(calc(66 * 1%)); } }
  .overlay-rounded-66 > * {
    position: relative;
    z-index: 1; }

.overlay-67 {
  position: relative; }
  .overlay-67:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-67:after {
        -webkit-backdrop-filter: brightness(calc(67 * 1%));
        backdrop-filter: brightness(calc(67 * 1%)); } }
  .overlay-67 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-67 {
  position: relative; }
  .overlay-rounded-67:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-67:after {
        -webkit-backdrop-filter: brightness(calc(67 * 1%));
        backdrop-filter: brightness(calc(67 * 1%)); } }
  .overlay-rounded-67 > * {
    position: relative;
    z-index: 1; }

.overlay-68 {
  position: relative; }
  .overlay-68:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-68:after {
        -webkit-backdrop-filter: brightness(calc(68 * 1%));
        backdrop-filter: brightness(calc(68 * 1%)); } }
  .overlay-68 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-68 {
  position: relative; }
  .overlay-rounded-68:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-68:after {
        -webkit-backdrop-filter: brightness(calc(68 * 1%));
        backdrop-filter: brightness(calc(68 * 1%)); } }
  .overlay-rounded-68 > * {
    position: relative;
    z-index: 1; }

.overlay-69 {
  position: relative; }
  .overlay-69:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-69:after {
        -webkit-backdrop-filter: brightness(calc(69 * 1%));
        backdrop-filter: brightness(calc(69 * 1%)); } }
  .overlay-69 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-69 {
  position: relative; }
  .overlay-rounded-69:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-69:after {
        -webkit-backdrop-filter: brightness(calc(69 * 1%));
        backdrop-filter: brightness(calc(69 * 1%)); } }
  .overlay-rounded-69 > * {
    position: relative;
    z-index: 1; }

.overlay-70 {
  position: relative; }
  .overlay-70:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-70:after {
        -webkit-backdrop-filter: brightness(calc(70 * 1%));
        backdrop-filter: brightness(calc(70 * 1%)); } }
  .overlay-70 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-70 {
  position: relative; }
  .overlay-rounded-70:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-70:after {
        -webkit-backdrop-filter: brightness(calc(70 * 1%));
        backdrop-filter: brightness(calc(70 * 1%)); } }
  .overlay-rounded-70 > * {
    position: relative;
    z-index: 1; }

.overlay-71 {
  position: relative; }
  .overlay-71:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-71:after {
        -webkit-backdrop-filter: brightness(calc(71 * 1%));
        backdrop-filter: brightness(calc(71 * 1%)); } }
  .overlay-71 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-71 {
  position: relative; }
  .overlay-rounded-71:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-71:after {
        -webkit-backdrop-filter: brightness(calc(71 * 1%));
        backdrop-filter: brightness(calc(71 * 1%)); } }
  .overlay-rounded-71 > * {
    position: relative;
    z-index: 1; }

.overlay-72 {
  position: relative; }
  .overlay-72:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-72:after {
        -webkit-backdrop-filter: brightness(calc(72 * 1%));
        backdrop-filter: brightness(calc(72 * 1%)); } }
  .overlay-72 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-72 {
  position: relative; }
  .overlay-rounded-72:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-72:after {
        -webkit-backdrop-filter: brightness(calc(72 * 1%));
        backdrop-filter: brightness(calc(72 * 1%)); } }
  .overlay-rounded-72 > * {
    position: relative;
    z-index: 1; }

.overlay-73 {
  position: relative; }
  .overlay-73:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-73:after {
        -webkit-backdrop-filter: brightness(calc(73 * 1%));
        backdrop-filter: brightness(calc(73 * 1%)); } }
  .overlay-73 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-73 {
  position: relative; }
  .overlay-rounded-73:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-73:after {
        -webkit-backdrop-filter: brightness(calc(73 * 1%));
        backdrop-filter: brightness(calc(73 * 1%)); } }
  .overlay-rounded-73 > * {
    position: relative;
    z-index: 1; }

.overlay-74 {
  position: relative; }
  .overlay-74:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-74:after {
        -webkit-backdrop-filter: brightness(calc(74 * 1%));
        backdrop-filter: brightness(calc(74 * 1%)); } }
  .overlay-74 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-74 {
  position: relative; }
  .overlay-rounded-74:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-74:after {
        -webkit-backdrop-filter: brightness(calc(74 * 1%));
        backdrop-filter: brightness(calc(74 * 1%)); } }
  .overlay-rounded-74 > * {
    position: relative;
    z-index: 1; }

.overlay-75 {
  position: relative; }
  .overlay-75:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-75:after {
        -webkit-backdrop-filter: brightness(calc(75 * 1%));
        backdrop-filter: brightness(calc(75 * 1%)); } }
  .overlay-75 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-75 {
  position: relative; }
  .overlay-rounded-75:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-75:after {
        -webkit-backdrop-filter: brightness(calc(75 * 1%));
        backdrop-filter: brightness(calc(75 * 1%)); } }
  .overlay-rounded-75 > * {
    position: relative;
    z-index: 1; }

.overlay-76 {
  position: relative; }
  .overlay-76:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-76:after {
        -webkit-backdrop-filter: brightness(calc(76 * 1%));
        backdrop-filter: brightness(calc(76 * 1%)); } }
  .overlay-76 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-76 {
  position: relative; }
  .overlay-rounded-76:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-76:after {
        -webkit-backdrop-filter: brightness(calc(76 * 1%));
        backdrop-filter: brightness(calc(76 * 1%)); } }
  .overlay-rounded-76 > * {
    position: relative;
    z-index: 1; }

.overlay-77 {
  position: relative; }
  .overlay-77:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-77:after {
        -webkit-backdrop-filter: brightness(calc(77 * 1%));
        backdrop-filter: brightness(calc(77 * 1%)); } }
  .overlay-77 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-77 {
  position: relative; }
  .overlay-rounded-77:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-77:after {
        -webkit-backdrop-filter: brightness(calc(77 * 1%));
        backdrop-filter: brightness(calc(77 * 1%)); } }
  .overlay-rounded-77 > * {
    position: relative;
    z-index: 1; }

.overlay-78 {
  position: relative; }
  .overlay-78:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-78:after {
        -webkit-backdrop-filter: brightness(calc(78 * 1%));
        backdrop-filter: brightness(calc(78 * 1%)); } }
  .overlay-78 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-78 {
  position: relative; }
  .overlay-rounded-78:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-78:after {
        -webkit-backdrop-filter: brightness(calc(78 * 1%));
        backdrop-filter: brightness(calc(78 * 1%)); } }
  .overlay-rounded-78 > * {
    position: relative;
    z-index: 1; }

.overlay-79 {
  position: relative; }
  .overlay-79:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-79:after {
        -webkit-backdrop-filter: brightness(calc(79 * 1%));
        backdrop-filter: brightness(calc(79 * 1%)); } }
  .overlay-79 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-79 {
  position: relative; }
  .overlay-rounded-79:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-79:after {
        -webkit-backdrop-filter: brightness(calc(79 * 1%));
        backdrop-filter: brightness(calc(79 * 1%)); } }
  .overlay-rounded-79 > * {
    position: relative;
    z-index: 1; }

.overlay-80 {
  position: relative; }
  .overlay-80:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-80:after {
        -webkit-backdrop-filter: brightness(calc(80 * 1%));
        backdrop-filter: brightness(calc(80 * 1%)); } }
  .overlay-80 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-80 {
  position: relative; }
  .overlay-rounded-80:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-80:after {
        -webkit-backdrop-filter: brightness(calc(80 * 1%));
        backdrop-filter: brightness(calc(80 * 1%)); } }
  .overlay-rounded-80 > * {
    position: relative;
    z-index: 1; }

.overlay-81 {
  position: relative; }
  .overlay-81:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-81:after {
        -webkit-backdrop-filter: brightness(calc(81 * 1%));
        backdrop-filter: brightness(calc(81 * 1%)); } }
  .overlay-81 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-81 {
  position: relative; }
  .overlay-rounded-81:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-81:after {
        -webkit-backdrop-filter: brightness(calc(81 * 1%));
        backdrop-filter: brightness(calc(81 * 1%)); } }
  .overlay-rounded-81 > * {
    position: relative;
    z-index: 1; }

.overlay-82 {
  position: relative; }
  .overlay-82:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-82:after {
        -webkit-backdrop-filter: brightness(calc(82 * 1%));
        backdrop-filter: brightness(calc(82 * 1%)); } }
  .overlay-82 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-82 {
  position: relative; }
  .overlay-rounded-82:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-82:after {
        -webkit-backdrop-filter: brightness(calc(82 * 1%));
        backdrop-filter: brightness(calc(82 * 1%)); } }
  .overlay-rounded-82 > * {
    position: relative;
    z-index: 1; }

.overlay-83 {
  position: relative; }
  .overlay-83:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-83:after {
        -webkit-backdrop-filter: brightness(calc(83 * 1%));
        backdrop-filter: brightness(calc(83 * 1%)); } }
  .overlay-83 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-83 {
  position: relative; }
  .overlay-rounded-83:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-83:after {
        -webkit-backdrop-filter: brightness(calc(83 * 1%));
        backdrop-filter: brightness(calc(83 * 1%)); } }
  .overlay-rounded-83 > * {
    position: relative;
    z-index: 1; }

.overlay-84 {
  position: relative; }
  .overlay-84:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-84:after {
        -webkit-backdrop-filter: brightness(calc(84 * 1%));
        backdrop-filter: brightness(calc(84 * 1%)); } }
  .overlay-84 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-84 {
  position: relative; }
  .overlay-rounded-84:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-84:after {
        -webkit-backdrop-filter: brightness(calc(84 * 1%));
        backdrop-filter: brightness(calc(84 * 1%)); } }
  .overlay-rounded-84 > * {
    position: relative;
    z-index: 1; }

.overlay-85 {
  position: relative; }
  .overlay-85:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-85:after {
        -webkit-backdrop-filter: brightness(calc(85 * 1%));
        backdrop-filter: brightness(calc(85 * 1%)); } }
  .overlay-85 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-85 {
  position: relative; }
  .overlay-rounded-85:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-85:after {
        -webkit-backdrop-filter: brightness(calc(85 * 1%));
        backdrop-filter: brightness(calc(85 * 1%)); } }
  .overlay-rounded-85 > * {
    position: relative;
    z-index: 1; }

.overlay-86 {
  position: relative; }
  .overlay-86:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-86:after {
        -webkit-backdrop-filter: brightness(calc(86 * 1%));
        backdrop-filter: brightness(calc(86 * 1%)); } }
  .overlay-86 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-86 {
  position: relative; }
  .overlay-rounded-86:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-86:after {
        -webkit-backdrop-filter: brightness(calc(86 * 1%));
        backdrop-filter: brightness(calc(86 * 1%)); } }
  .overlay-rounded-86 > * {
    position: relative;
    z-index: 1; }

.overlay-87 {
  position: relative; }
  .overlay-87:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-87:after {
        -webkit-backdrop-filter: brightness(calc(87 * 1%));
        backdrop-filter: brightness(calc(87 * 1%)); } }
  .overlay-87 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-87 {
  position: relative; }
  .overlay-rounded-87:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-87:after {
        -webkit-backdrop-filter: brightness(calc(87 * 1%));
        backdrop-filter: brightness(calc(87 * 1%)); } }
  .overlay-rounded-87 > * {
    position: relative;
    z-index: 1; }

.overlay-88 {
  position: relative; }
  .overlay-88:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-88:after {
        -webkit-backdrop-filter: brightness(calc(88 * 1%));
        backdrop-filter: brightness(calc(88 * 1%)); } }
  .overlay-88 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-88 {
  position: relative; }
  .overlay-rounded-88:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-88:after {
        -webkit-backdrop-filter: brightness(calc(88 * 1%));
        backdrop-filter: brightness(calc(88 * 1%)); } }
  .overlay-rounded-88 > * {
    position: relative;
    z-index: 1; }

.overlay-89 {
  position: relative; }
  .overlay-89:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-89:after {
        -webkit-backdrop-filter: brightness(calc(89 * 1%));
        backdrop-filter: brightness(calc(89 * 1%)); } }
  .overlay-89 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-89 {
  position: relative; }
  .overlay-rounded-89:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-89:after {
        -webkit-backdrop-filter: brightness(calc(89 * 1%));
        backdrop-filter: brightness(calc(89 * 1%)); } }
  .overlay-rounded-89 > * {
    position: relative;
    z-index: 1; }

.overlay-90 {
  position: relative; }
  .overlay-90:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-90:after {
        -webkit-backdrop-filter: brightness(calc(90 * 1%));
        backdrop-filter: brightness(calc(90 * 1%)); } }
  .overlay-90 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-90 {
  position: relative; }
  .overlay-rounded-90:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-90:after {
        -webkit-backdrop-filter: brightness(calc(90 * 1%));
        backdrop-filter: brightness(calc(90 * 1%)); } }
  .overlay-rounded-90 > * {
    position: relative;
    z-index: 1; }

.overlay-91 {
  position: relative; }
  .overlay-91:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-91:after {
        -webkit-backdrop-filter: brightness(calc(91 * 1%));
        backdrop-filter: brightness(calc(91 * 1%)); } }
  .overlay-91 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-91 {
  position: relative; }
  .overlay-rounded-91:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-91:after {
        -webkit-backdrop-filter: brightness(calc(91 * 1%));
        backdrop-filter: brightness(calc(91 * 1%)); } }
  .overlay-rounded-91 > * {
    position: relative;
    z-index: 1; }

.overlay-92 {
  position: relative; }
  .overlay-92:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-92:after {
        -webkit-backdrop-filter: brightness(calc(92 * 1%));
        backdrop-filter: brightness(calc(92 * 1%)); } }
  .overlay-92 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-92 {
  position: relative; }
  .overlay-rounded-92:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-92:after {
        -webkit-backdrop-filter: brightness(calc(92 * 1%));
        backdrop-filter: brightness(calc(92 * 1%)); } }
  .overlay-rounded-92 > * {
    position: relative;
    z-index: 1; }

.overlay-93 {
  position: relative; }
  .overlay-93:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-93:after {
        -webkit-backdrop-filter: brightness(calc(93 * 1%));
        backdrop-filter: brightness(calc(93 * 1%)); } }
  .overlay-93 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-93 {
  position: relative; }
  .overlay-rounded-93:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-93:after {
        -webkit-backdrop-filter: brightness(calc(93 * 1%));
        backdrop-filter: brightness(calc(93 * 1%)); } }
  .overlay-rounded-93 > * {
    position: relative;
    z-index: 1; }

.overlay-94 {
  position: relative; }
  .overlay-94:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-94:after {
        -webkit-backdrop-filter: brightness(calc(94 * 1%));
        backdrop-filter: brightness(calc(94 * 1%)); } }
  .overlay-94 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-94 {
  position: relative; }
  .overlay-rounded-94:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-94:after {
        -webkit-backdrop-filter: brightness(calc(94 * 1%));
        backdrop-filter: brightness(calc(94 * 1%)); } }
  .overlay-rounded-94 > * {
    position: relative;
    z-index: 1; }

.overlay-95 {
  position: relative; }
  .overlay-95:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-95:after {
        -webkit-backdrop-filter: brightness(calc(95 * 1%));
        backdrop-filter: brightness(calc(95 * 1%)); } }
  .overlay-95 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-95 {
  position: relative; }
  .overlay-rounded-95:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-95:after {
        -webkit-backdrop-filter: brightness(calc(95 * 1%));
        backdrop-filter: brightness(calc(95 * 1%)); } }
  .overlay-rounded-95 > * {
    position: relative;
    z-index: 1; }

.overlay-96 {
  position: relative; }
  .overlay-96:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-96:after {
        -webkit-backdrop-filter: brightness(calc(96 * 1%));
        backdrop-filter: brightness(calc(96 * 1%)); } }
  .overlay-96 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-96 {
  position: relative; }
  .overlay-rounded-96:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-96:after {
        -webkit-backdrop-filter: brightness(calc(96 * 1%));
        backdrop-filter: brightness(calc(96 * 1%)); } }
  .overlay-rounded-96 > * {
    position: relative;
    z-index: 1; }

.overlay-97 {
  position: relative; }
  .overlay-97:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-97:after {
        -webkit-backdrop-filter: brightness(calc(97 * 1%));
        backdrop-filter: brightness(calc(97 * 1%)); } }
  .overlay-97 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-97 {
  position: relative; }
  .overlay-rounded-97:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-97:after {
        -webkit-backdrop-filter: brightness(calc(97 * 1%));
        backdrop-filter: brightness(calc(97 * 1%)); } }
  .overlay-rounded-97 > * {
    position: relative;
    z-index: 1; }

.overlay-98 {
  position: relative; }
  .overlay-98:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-98:after {
        -webkit-backdrop-filter: brightness(calc(98 * 1%));
        backdrop-filter: brightness(calc(98 * 1%)); } }
  .overlay-98 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-98 {
  position: relative; }
  .overlay-rounded-98:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-98:after {
        -webkit-backdrop-filter: brightness(calc(98 * 1%));
        backdrop-filter: brightness(calc(98 * 1%)); } }
  .overlay-rounded-98 > * {
    position: relative;
    z-index: 1; }

.overlay-99 {
  position: relative; }
  .overlay-99:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-99:after {
        -webkit-backdrop-filter: brightness(calc(99 * 1%));
        backdrop-filter: brightness(calc(99 * 1%)); } }
  .overlay-99 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-99 {
  position: relative; }
  .overlay-rounded-99:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-99:after {
        -webkit-backdrop-filter: brightness(calc(99 * 1%));
        backdrop-filter: brightness(calc(99 * 1%)); } }
  .overlay-rounded-99 > * {
    position: relative;
    z-index: 1; }

.overlay-100 {
  position: relative; }
  .overlay-100:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-100:after {
        -webkit-backdrop-filter: brightness(calc(100 * 1%));
        backdrop-filter: brightness(calc(100 * 1%)); } }
  .overlay-100 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-100 {
  position: relative; }
  .overlay-rounded-100:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-100:after {
        -webkit-backdrop-filter: brightness(calc(100 * 1%));
        backdrop-filter: brightness(calc(100 * 1%)); } }
  .overlay-rounded-100 > * {
    position: relative;
    z-index: 1; }

.overlay-101 {
  position: relative; }
  .overlay-101:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-101:after {
        -webkit-backdrop-filter: brightness(calc(101 * 1%));
        backdrop-filter: brightness(calc(101 * 1%)); } }
  .overlay-101 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-101 {
  position: relative; }
  .overlay-rounded-101:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-101:after {
        -webkit-backdrop-filter: brightness(calc(101 * 1%));
        backdrop-filter: brightness(calc(101 * 1%)); } }
  .overlay-rounded-101 > * {
    position: relative;
    z-index: 1; }

.overlay-102 {
  position: relative; }
  .overlay-102:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-102:after {
        -webkit-backdrop-filter: brightness(calc(102 * 1%));
        backdrop-filter: brightness(calc(102 * 1%)); } }
  .overlay-102 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-102 {
  position: relative; }
  .overlay-rounded-102:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-102:after {
        -webkit-backdrop-filter: brightness(calc(102 * 1%));
        backdrop-filter: brightness(calc(102 * 1%)); } }
  .overlay-rounded-102 > * {
    position: relative;
    z-index: 1; }

.overlay-103 {
  position: relative; }
  .overlay-103:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-103:after {
        -webkit-backdrop-filter: brightness(calc(103 * 1%));
        backdrop-filter: brightness(calc(103 * 1%)); } }
  .overlay-103 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-103 {
  position: relative; }
  .overlay-rounded-103:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-103:after {
        -webkit-backdrop-filter: brightness(calc(103 * 1%));
        backdrop-filter: brightness(calc(103 * 1%)); } }
  .overlay-rounded-103 > * {
    position: relative;
    z-index: 1; }

.overlay-104 {
  position: relative; }
  .overlay-104:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-104:after {
        -webkit-backdrop-filter: brightness(calc(104 * 1%));
        backdrop-filter: brightness(calc(104 * 1%)); } }
  .overlay-104 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-104 {
  position: relative; }
  .overlay-rounded-104:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-104:after {
        -webkit-backdrop-filter: brightness(calc(104 * 1%));
        backdrop-filter: brightness(calc(104 * 1%)); } }
  .overlay-rounded-104 > * {
    position: relative;
    z-index: 1; }

.overlay-105 {
  position: relative; }
  .overlay-105:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-105:after {
        -webkit-backdrop-filter: brightness(calc(105 * 1%));
        backdrop-filter: brightness(calc(105 * 1%)); } }
  .overlay-105 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-105 {
  position: relative; }
  .overlay-rounded-105:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-105:after {
        -webkit-backdrop-filter: brightness(calc(105 * 1%));
        backdrop-filter: brightness(calc(105 * 1%)); } }
  .overlay-rounded-105 > * {
    position: relative;
    z-index: 1; }

.overlay-106 {
  position: relative; }
  .overlay-106:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-106:after {
        -webkit-backdrop-filter: brightness(calc(106 * 1%));
        backdrop-filter: brightness(calc(106 * 1%)); } }
  .overlay-106 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-106 {
  position: relative; }
  .overlay-rounded-106:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-106:after {
        -webkit-backdrop-filter: brightness(calc(106 * 1%));
        backdrop-filter: brightness(calc(106 * 1%)); } }
  .overlay-rounded-106 > * {
    position: relative;
    z-index: 1; }

.overlay-107 {
  position: relative; }
  .overlay-107:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-107:after {
        -webkit-backdrop-filter: brightness(calc(107 * 1%));
        backdrop-filter: brightness(calc(107 * 1%)); } }
  .overlay-107 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-107 {
  position: relative; }
  .overlay-rounded-107:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-107:after {
        -webkit-backdrop-filter: brightness(calc(107 * 1%));
        backdrop-filter: brightness(calc(107 * 1%)); } }
  .overlay-rounded-107 > * {
    position: relative;
    z-index: 1; }

.overlay-108 {
  position: relative; }
  .overlay-108:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-108:after {
        -webkit-backdrop-filter: brightness(calc(108 * 1%));
        backdrop-filter: brightness(calc(108 * 1%)); } }
  .overlay-108 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-108 {
  position: relative; }
  .overlay-rounded-108:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-108:after {
        -webkit-backdrop-filter: brightness(calc(108 * 1%));
        backdrop-filter: brightness(calc(108 * 1%)); } }
  .overlay-rounded-108 > * {
    position: relative;
    z-index: 1; }

.overlay-109 {
  position: relative; }
  .overlay-109:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-109:after {
        -webkit-backdrop-filter: brightness(calc(109 * 1%));
        backdrop-filter: brightness(calc(109 * 1%)); } }
  .overlay-109 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-109 {
  position: relative; }
  .overlay-rounded-109:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-109:after {
        -webkit-backdrop-filter: brightness(calc(109 * 1%));
        backdrop-filter: brightness(calc(109 * 1%)); } }
  .overlay-rounded-109 > * {
    position: relative;
    z-index: 1; }

.overlay-110 {
  position: relative; }
  .overlay-110:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-110:after {
        -webkit-backdrop-filter: brightness(calc(110 * 1%));
        backdrop-filter: brightness(calc(110 * 1%)); } }
  .overlay-110 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-110 {
  position: relative; }
  .overlay-rounded-110:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-110:after {
        -webkit-backdrop-filter: brightness(calc(110 * 1%));
        backdrop-filter: brightness(calc(110 * 1%)); } }
  .overlay-rounded-110 > * {
    position: relative;
    z-index: 1; }

.overlay-111 {
  position: relative; }
  .overlay-111:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-111:after {
        -webkit-backdrop-filter: brightness(calc(111 * 1%));
        backdrop-filter: brightness(calc(111 * 1%)); } }
  .overlay-111 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-111 {
  position: relative; }
  .overlay-rounded-111:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-111:after {
        -webkit-backdrop-filter: brightness(calc(111 * 1%));
        backdrop-filter: brightness(calc(111 * 1%)); } }
  .overlay-rounded-111 > * {
    position: relative;
    z-index: 1; }

.overlay-112 {
  position: relative; }
  .overlay-112:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-112:after {
        -webkit-backdrop-filter: brightness(calc(112 * 1%));
        backdrop-filter: brightness(calc(112 * 1%)); } }
  .overlay-112 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-112 {
  position: relative; }
  .overlay-rounded-112:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-112:after {
        -webkit-backdrop-filter: brightness(calc(112 * 1%));
        backdrop-filter: brightness(calc(112 * 1%)); } }
  .overlay-rounded-112 > * {
    position: relative;
    z-index: 1; }

.overlay-113 {
  position: relative; }
  .overlay-113:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-113:after {
        -webkit-backdrop-filter: brightness(calc(113 * 1%));
        backdrop-filter: brightness(calc(113 * 1%)); } }
  .overlay-113 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-113 {
  position: relative; }
  .overlay-rounded-113:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-113:after {
        -webkit-backdrop-filter: brightness(calc(113 * 1%));
        backdrop-filter: brightness(calc(113 * 1%)); } }
  .overlay-rounded-113 > * {
    position: relative;
    z-index: 1; }

.overlay-114 {
  position: relative; }
  .overlay-114:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-114:after {
        -webkit-backdrop-filter: brightness(calc(114 * 1%));
        backdrop-filter: brightness(calc(114 * 1%)); } }
  .overlay-114 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-114 {
  position: relative; }
  .overlay-rounded-114:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-114:after {
        -webkit-backdrop-filter: brightness(calc(114 * 1%));
        backdrop-filter: brightness(calc(114 * 1%)); } }
  .overlay-rounded-114 > * {
    position: relative;
    z-index: 1; }

.overlay-115 {
  position: relative; }
  .overlay-115:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-115:after {
        -webkit-backdrop-filter: brightness(calc(115 * 1%));
        backdrop-filter: brightness(calc(115 * 1%)); } }
  .overlay-115 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-115 {
  position: relative; }
  .overlay-rounded-115:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-115:after {
        -webkit-backdrop-filter: brightness(calc(115 * 1%));
        backdrop-filter: brightness(calc(115 * 1%)); } }
  .overlay-rounded-115 > * {
    position: relative;
    z-index: 1; }

.overlay-116 {
  position: relative; }
  .overlay-116:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-116:after {
        -webkit-backdrop-filter: brightness(calc(116 * 1%));
        backdrop-filter: brightness(calc(116 * 1%)); } }
  .overlay-116 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-116 {
  position: relative; }
  .overlay-rounded-116:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-116:after {
        -webkit-backdrop-filter: brightness(calc(116 * 1%));
        backdrop-filter: brightness(calc(116 * 1%)); } }
  .overlay-rounded-116 > * {
    position: relative;
    z-index: 1; }

.overlay-117 {
  position: relative; }
  .overlay-117:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-117:after {
        -webkit-backdrop-filter: brightness(calc(117 * 1%));
        backdrop-filter: brightness(calc(117 * 1%)); } }
  .overlay-117 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-117 {
  position: relative; }
  .overlay-rounded-117:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-117:after {
        -webkit-backdrop-filter: brightness(calc(117 * 1%));
        backdrop-filter: brightness(calc(117 * 1%)); } }
  .overlay-rounded-117 > * {
    position: relative;
    z-index: 1; }

.overlay-118 {
  position: relative; }
  .overlay-118:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-118:after {
        -webkit-backdrop-filter: brightness(calc(118 * 1%));
        backdrop-filter: brightness(calc(118 * 1%)); } }
  .overlay-118 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-118 {
  position: relative; }
  .overlay-rounded-118:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-118:after {
        -webkit-backdrop-filter: brightness(calc(118 * 1%));
        backdrop-filter: brightness(calc(118 * 1%)); } }
  .overlay-rounded-118 > * {
    position: relative;
    z-index: 1; }

.overlay-119 {
  position: relative; }
  .overlay-119:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-119:after {
        -webkit-backdrop-filter: brightness(calc(119 * 1%));
        backdrop-filter: brightness(calc(119 * 1%)); } }
  .overlay-119 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-119 {
  position: relative; }
  .overlay-rounded-119:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-119:after {
        -webkit-backdrop-filter: brightness(calc(119 * 1%));
        backdrop-filter: brightness(calc(119 * 1%)); } }
  .overlay-rounded-119 > * {
    position: relative;
    z-index: 1; }

.overlay-120 {
  position: relative; }
  .overlay-120:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-120:after {
        -webkit-backdrop-filter: brightness(calc(120 * 1%));
        backdrop-filter: brightness(calc(120 * 1%)); } }
  .overlay-120 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-120 {
  position: relative; }
  .overlay-rounded-120:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-120:after {
        -webkit-backdrop-filter: brightness(calc(120 * 1%));
        backdrop-filter: brightness(calc(120 * 1%)); } }
  .overlay-rounded-120 > * {
    position: relative;
    z-index: 1; }

.overlay-121 {
  position: relative; }
  .overlay-121:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-121:after {
        -webkit-backdrop-filter: brightness(calc(121 * 1%));
        backdrop-filter: brightness(calc(121 * 1%)); } }
  .overlay-121 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-121 {
  position: relative; }
  .overlay-rounded-121:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-121:after {
        -webkit-backdrop-filter: brightness(calc(121 * 1%));
        backdrop-filter: brightness(calc(121 * 1%)); } }
  .overlay-rounded-121 > * {
    position: relative;
    z-index: 1; }

.overlay-122 {
  position: relative; }
  .overlay-122:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-122:after {
        -webkit-backdrop-filter: brightness(calc(122 * 1%));
        backdrop-filter: brightness(calc(122 * 1%)); } }
  .overlay-122 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-122 {
  position: relative; }
  .overlay-rounded-122:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-122:after {
        -webkit-backdrop-filter: brightness(calc(122 * 1%));
        backdrop-filter: brightness(calc(122 * 1%)); } }
  .overlay-rounded-122 > * {
    position: relative;
    z-index: 1; }

.overlay-123 {
  position: relative; }
  .overlay-123:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-123:after {
        -webkit-backdrop-filter: brightness(calc(123 * 1%));
        backdrop-filter: brightness(calc(123 * 1%)); } }
  .overlay-123 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-123 {
  position: relative; }
  .overlay-rounded-123:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-123:after {
        -webkit-backdrop-filter: brightness(calc(123 * 1%));
        backdrop-filter: brightness(calc(123 * 1%)); } }
  .overlay-rounded-123 > * {
    position: relative;
    z-index: 1; }

.overlay-124 {
  position: relative; }
  .overlay-124:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-124:after {
        -webkit-backdrop-filter: brightness(calc(124 * 1%));
        backdrop-filter: brightness(calc(124 * 1%)); } }
  .overlay-124 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-124 {
  position: relative; }
  .overlay-rounded-124:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-124:after {
        -webkit-backdrop-filter: brightness(calc(124 * 1%));
        backdrop-filter: brightness(calc(124 * 1%)); } }
  .overlay-rounded-124 > * {
    position: relative;
    z-index: 1; }

.overlay-125 {
  position: relative; }
  .overlay-125:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-125:after {
        -webkit-backdrop-filter: brightness(calc(125 * 1%));
        backdrop-filter: brightness(calc(125 * 1%)); } }
  .overlay-125 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-125 {
  position: relative; }
  .overlay-rounded-125:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-125:after {
        -webkit-backdrop-filter: brightness(calc(125 * 1%));
        backdrop-filter: brightness(calc(125 * 1%)); } }
  .overlay-rounded-125 > * {
    position: relative;
    z-index: 1; }

.overlay-126 {
  position: relative; }
  .overlay-126:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-126:after {
        -webkit-backdrop-filter: brightness(calc(126 * 1%));
        backdrop-filter: brightness(calc(126 * 1%)); } }
  .overlay-126 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-126 {
  position: relative; }
  .overlay-rounded-126:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-126:after {
        -webkit-backdrop-filter: brightness(calc(126 * 1%));
        backdrop-filter: brightness(calc(126 * 1%)); } }
  .overlay-rounded-126 > * {
    position: relative;
    z-index: 1; }

.overlay-127 {
  position: relative; }
  .overlay-127:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-127:after {
        -webkit-backdrop-filter: brightness(calc(127 * 1%));
        backdrop-filter: brightness(calc(127 * 1%)); } }
  .overlay-127 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-127 {
  position: relative; }
  .overlay-rounded-127:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-127:after {
        -webkit-backdrop-filter: brightness(calc(127 * 1%));
        backdrop-filter: brightness(calc(127 * 1%)); } }
  .overlay-rounded-127 > * {
    position: relative;
    z-index: 1; }

.overlay-128 {
  position: relative; }
  .overlay-128:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-128:after {
        -webkit-backdrop-filter: brightness(calc(128 * 1%));
        backdrop-filter: brightness(calc(128 * 1%)); } }
  .overlay-128 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-128 {
  position: relative; }
  .overlay-rounded-128:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-128:after {
        -webkit-backdrop-filter: brightness(calc(128 * 1%));
        backdrop-filter: brightness(calc(128 * 1%)); } }
  .overlay-rounded-128 > * {
    position: relative;
    z-index: 1; }

.overlay-129 {
  position: relative; }
  .overlay-129:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-129:after {
        -webkit-backdrop-filter: brightness(calc(129 * 1%));
        backdrop-filter: brightness(calc(129 * 1%)); } }
  .overlay-129 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-129 {
  position: relative; }
  .overlay-rounded-129:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-129:after {
        -webkit-backdrop-filter: brightness(calc(129 * 1%));
        backdrop-filter: brightness(calc(129 * 1%)); } }
  .overlay-rounded-129 > * {
    position: relative;
    z-index: 1; }

.overlay-130 {
  position: relative; }
  .overlay-130:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-130:after {
        -webkit-backdrop-filter: brightness(calc(130 * 1%));
        backdrop-filter: brightness(calc(130 * 1%)); } }
  .overlay-130 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-130 {
  position: relative; }
  .overlay-rounded-130:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-130:after {
        -webkit-backdrop-filter: brightness(calc(130 * 1%));
        backdrop-filter: brightness(calc(130 * 1%)); } }
  .overlay-rounded-130 > * {
    position: relative;
    z-index: 1; }

.overlay-131 {
  position: relative; }
  .overlay-131:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-131:after {
        -webkit-backdrop-filter: brightness(calc(131 * 1%));
        backdrop-filter: brightness(calc(131 * 1%)); } }
  .overlay-131 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-131 {
  position: relative; }
  .overlay-rounded-131:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-131:after {
        -webkit-backdrop-filter: brightness(calc(131 * 1%));
        backdrop-filter: brightness(calc(131 * 1%)); } }
  .overlay-rounded-131 > * {
    position: relative;
    z-index: 1; }

.overlay-132 {
  position: relative; }
  .overlay-132:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-132:after {
        -webkit-backdrop-filter: brightness(calc(132 * 1%));
        backdrop-filter: brightness(calc(132 * 1%)); } }
  .overlay-132 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-132 {
  position: relative; }
  .overlay-rounded-132:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-132:after {
        -webkit-backdrop-filter: brightness(calc(132 * 1%));
        backdrop-filter: brightness(calc(132 * 1%)); } }
  .overlay-rounded-132 > * {
    position: relative;
    z-index: 1; }

.overlay-133 {
  position: relative; }
  .overlay-133:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-133:after {
        -webkit-backdrop-filter: brightness(calc(133 * 1%));
        backdrop-filter: brightness(calc(133 * 1%)); } }
  .overlay-133 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-133 {
  position: relative; }
  .overlay-rounded-133:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-133:after {
        -webkit-backdrop-filter: brightness(calc(133 * 1%));
        backdrop-filter: brightness(calc(133 * 1%)); } }
  .overlay-rounded-133 > * {
    position: relative;
    z-index: 1; }

.overlay-134 {
  position: relative; }
  .overlay-134:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-134:after {
        -webkit-backdrop-filter: brightness(calc(134 * 1%));
        backdrop-filter: brightness(calc(134 * 1%)); } }
  .overlay-134 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-134 {
  position: relative; }
  .overlay-rounded-134:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-134:after {
        -webkit-backdrop-filter: brightness(calc(134 * 1%));
        backdrop-filter: brightness(calc(134 * 1%)); } }
  .overlay-rounded-134 > * {
    position: relative;
    z-index: 1; }

.overlay-135 {
  position: relative; }
  .overlay-135:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-135:after {
        -webkit-backdrop-filter: brightness(calc(135 * 1%));
        backdrop-filter: brightness(calc(135 * 1%)); } }
  .overlay-135 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-135 {
  position: relative; }
  .overlay-rounded-135:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-135:after {
        -webkit-backdrop-filter: brightness(calc(135 * 1%));
        backdrop-filter: brightness(calc(135 * 1%)); } }
  .overlay-rounded-135 > * {
    position: relative;
    z-index: 1; }

.overlay-136 {
  position: relative; }
  .overlay-136:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-136:after {
        -webkit-backdrop-filter: brightness(calc(136 * 1%));
        backdrop-filter: brightness(calc(136 * 1%)); } }
  .overlay-136 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-136 {
  position: relative; }
  .overlay-rounded-136:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-136:after {
        -webkit-backdrop-filter: brightness(calc(136 * 1%));
        backdrop-filter: brightness(calc(136 * 1%)); } }
  .overlay-rounded-136 > * {
    position: relative;
    z-index: 1; }

.overlay-137 {
  position: relative; }
  .overlay-137:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-137:after {
        -webkit-backdrop-filter: brightness(calc(137 * 1%));
        backdrop-filter: brightness(calc(137 * 1%)); } }
  .overlay-137 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-137 {
  position: relative; }
  .overlay-rounded-137:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-137:after {
        -webkit-backdrop-filter: brightness(calc(137 * 1%));
        backdrop-filter: brightness(calc(137 * 1%)); } }
  .overlay-rounded-137 > * {
    position: relative;
    z-index: 1; }

.overlay-138 {
  position: relative; }
  .overlay-138:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-138:after {
        -webkit-backdrop-filter: brightness(calc(138 * 1%));
        backdrop-filter: brightness(calc(138 * 1%)); } }
  .overlay-138 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-138 {
  position: relative; }
  .overlay-rounded-138:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-138:after {
        -webkit-backdrop-filter: brightness(calc(138 * 1%));
        backdrop-filter: brightness(calc(138 * 1%)); } }
  .overlay-rounded-138 > * {
    position: relative;
    z-index: 1; }

.overlay-139 {
  position: relative; }
  .overlay-139:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-139:after {
        -webkit-backdrop-filter: brightness(calc(139 * 1%));
        backdrop-filter: brightness(calc(139 * 1%)); } }
  .overlay-139 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-139 {
  position: relative; }
  .overlay-rounded-139:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-139:after {
        -webkit-backdrop-filter: brightness(calc(139 * 1%));
        backdrop-filter: brightness(calc(139 * 1%)); } }
  .overlay-rounded-139 > * {
    position: relative;
    z-index: 1; }

.overlay-140 {
  position: relative; }
  .overlay-140:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-140:after {
        -webkit-backdrop-filter: brightness(calc(140 * 1%));
        backdrop-filter: brightness(calc(140 * 1%)); } }
  .overlay-140 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-140 {
  position: relative; }
  .overlay-rounded-140:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-140:after {
        -webkit-backdrop-filter: brightness(calc(140 * 1%));
        backdrop-filter: brightness(calc(140 * 1%)); } }
  .overlay-rounded-140 > * {
    position: relative;
    z-index: 1; }

.overlay-141 {
  position: relative; }
  .overlay-141:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-141:after {
        -webkit-backdrop-filter: brightness(calc(141 * 1%));
        backdrop-filter: brightness(calc(141 * 1%)); } }
  .overlay-141 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-141 {
  position: relative; }
  .overlay-rounded-141:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-141:after {
        -webkit-backdrop-filter: brightness(calc(141 * 1%));
        backdrop-filter: brightness(calc(141 * 1%)); } }
  .overlay-rounded-141 > * {
    position: relative;
    z-index: 1; }

.overlay-142 {
  position: relative; }
  .overlay-142:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-142:after {
        -webkit-backdrop-filter: brightness(calc(142 * 1%));
        backdrop-filter: brightness(calc(142 * 1%)); } }
  .overlay-142 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-142 {
  position: relative; }
  .overlay-rounded-142:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-142:after {
        -webkit-backdrop-filter: brightness(calc(142 * 1%));
        backdrop-filter: brightness(calc(142 * 1%)); } }
  .overlay-rounded-142 > * {
    position: relative;
    z-index: 1; }

.overlay-143 {
  position: relative; }
  .overlay-143:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-143:after {
        -webkit-backdrop-filter: brightness(calc(143 * 1%));
        backdrop-filter: brightness(calc(143 * 1%)); } }
  .overlay-143 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-143 {
  position: relative; }
  .overlay-rounded-143:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-143:after {
        -webkit-backdrop-filter: brightness(calc(143 * 1%));
        backdrop-filter: brightness(calc(143 * 1%)); } }
  .overlay-rounded-143 > * {
    position: relative;
    z-index: 1; }

.overlay-144 {
  position: relative; }
  .overlay-144:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-144:after {
        -webkit-backdrop-filter: brightness(calc(144 * 1%));
        backdrop-filter: brightness(calc(144 * 1%)); } }
  .overlay-144 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-144 {
  position: relative; }
  .overlay-rounded-144:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-144:after {
        -webkit-backdrop-filter: brightness(calc(144 * 1%));
        backdrop-filter: brightness(calc(144 * 1%)); } }
  .overlay-rounded-144 > * {
    position: relative;
    z-index: 1; }

.overlay-145 {
  position: relative; }
  .overlay-145:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-145:after {
        -webkit-backdrop-filter: brightness(calc(145 * 1%));
        backdrop-filter: brightness(calc(145 * 1%)); } }
  .overlay-145 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-145 {
  position: relative; }
  .overlay-rounded-145:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-145:after {
        -webkit-backdrop-filter: brightness(calc(145 * 1%));
        backdrop-filter: brightness(calc(145 * 1%)); } }
  .overlay-rounded-145 > * {
    position: relative;
    z-index: 1; }

.overlay-146 {
  position: relative; }
  .overlay-146:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-146:after {
        -webkit-backdrop-filter: brightness(calc(146 * 1%));
        backdrop-filter: brightness(calc(146 * 1%)); } }
  .overlay-146 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-146 {
  position: relative; }
  .overlay-rounded-146:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-146:after {
        -webkit-backdrop-filter: brightness(calc(146 * 1%));
        backdrop-filter: brightness(calc(146 * 1%)); } }
  .overlay-rounded-146 > * {
    position: relative;
    z-index: 1; }

.overlay-147 {
  position: relative; }
  .overlay-147:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-147:after {
        -webkit-backdrop-filter: brightness(calc(147 * 1%));
        backdrop-filter: brightness(calc(147 * 1%)); } }
  .overlay-147 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-147 {
  position: relative; }
  .overlay-rounded-147:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-147:after {
        -webkit-backdrop-filter: brightness(calc(147 * 1%));
        backdrop-filter: brightness(calc(147 * 1%)); } }
  .overlay-rounded-147 > * {
    position: relative;
    z-index: 1; }

.overlay-148 {
  position: relative; }
  .overlay-148:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-148:after {
        -webkit-backdrop-filter: brightness(calc(148 * 1%));
        backdrop-filter: brightness(calc(148 * 1%)); } }
  .overlay-148 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-148 {
  position: relative; }
  .overlay-rounded-148:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-148:after {
        -webkit-backdrop-filter: brightness(calc(148 * 1%));
        backdrop-filter: brightness(calc(148 * 1%)); } }
  .overlay-rounded-148 > * {
    position: relative;
    z-index: 1; }

.overlay-149 {
  position: relative; }
  .overlay-149:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-149:after {
        -webkit-backdrop-filter: brightness(calc(149 * 1%));
        backdrop-filter: brightness(calc(149 * 1%)); } }
  .overlay-149 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-149 {
  position: relative; }
  .overlay-rounded-149:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-149:after {
        -webkit-backdrop-filter: brightness(calc(149 * 1%));
        backdrop-filter: brightness(calc(149 * 1%)); } }
  .overlay-rounded-149 > * {
    position: relative;
    z-index: 1; }

.overlay-150 {
  position: relative; }
  .overlay-150:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-150:after {
        -webkit-backdrop-filter: brightness(calc(150 * 1%));
        backdrop-filter: brightness(calc(150 * 1%)); } }
  .overlay-150 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-150 {
  position: relative; }
  .overlay-rounded-150:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-150:after {
        -webkit-backdrop-filter: brightness(calc(150 * 1%));
        backdrop-filter: brightness(calc(150 * 1%)); } }
  .overlay-rounded-150 > * {
    position: relative;
    z-index: 1; }

.overlay-151 {
  position: relative; }
  .overlay-151:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-151:after {
        -webkit-backdrop-filter: brightness(calc(151 * 1%));
        backdrop-filter: brightness(calc(151 * 1%)); } }
  .overlay-151 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-151 {
  position: relative; }
  .overlay-rounded-151:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-151:after {
        -webkit-backdrop-filter: brightness(calc(151 * 1%));
        backdrop-filter: brightness(calc(151 * 1%)); } }
  .overlay-rounded-151 > * {
    position: relative;
    z-index: 1; }

.overlay-152 {
  position: relative; }
  .overlay-152:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-152:after {
        -webkit-backdrop-filter: brightness(calc(152 * 1%));
        backdrop-filter: brightness(calc(152 * 1%)); } }
  .overlay-152 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-152 {
  position: relative; }
  .overlay-rounded-152:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-152:after {
        -webkit-backdrop-filter: brightness(calc(152 * 1%));
        backdrop-filter: brightness(calc(152 * 1%)); } }
  .overlay-rounded-152 > * {
    position: relative;
    z-index: 1; }

.overlay-153 {
  position: relative; }
  .overlay-153:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-153:after {
        -webkit-backdrop-filter: brightness(calc(153 * 1%));
        backdrop-filter: brightness(calc(153 * 1%)); } }
  .overlay-153 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-153 {
  position: relative; }
  .overlay-rounded-153:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-153:after {
        -webkit-backdrop-filter: brightness(calc(153 * 1%));
        backdrop-filter: brightness(calc(153 * 1%)); } }
  .overlay-rounded-153 > * {
    position: relative;
    z-index: 1; }

.overlay-154 {
  position: relative; }
  .overlay-154:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-154:after {
        -webkit-backdrop-filter: brightness(calc(154 * 1%));
        backdrop-filter: brightness(calc(154 * 1%)); } }
  .overlay-154 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-154 {
  position: relative; }
  .overlay-rounded-154:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-154:after {
        -webkit-backdrop-filter: brightness(calc(154 * 1%));
        backdrop-filter: brightness(calc(154 * 1%)); } }
  .overlay-rounded-154 > * {
    position: relative;
    z-index: 1; }

.overlay-155 {
  position: relative; }
  .overlay-155:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-155:after {
        -webkit-backdrop-filter: brightness(calc(155 * 1%));
        backdrop-filter: brightness(calc(155 * 1%)); } }
  .overlay-155 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-155 {
  position: relative; }
  .overlay-rounded-155:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-155:after {
        -webkit-backdrop-filter: brightness(calc(155 * 1%));
        backdrop-filter: brightness(calc(155 * 1%)); } }
  .overlay-rounded-155 > * {
    position: relative;
    z-index: 1; }

.overlay-156 {
  position: relative; }
  .overlay-156:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-156:after {
        -webkit-backdrop-filter: brightness(calc(156 * 1%));
        backdrop-filter: brightness(calc(156 * 1%)); } }
  .overlay-156 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-156 {
  position: relative; }
  .overlay-rounded-156:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-156:after {
        -webkit-backdrop-filter: brightness(calc(156 * 1%));
        backdrop-filter: brightness(calc(156 * 1%)); } }
  .overlay-rounded-156 > * {
    position: relative;
    z-index: 1; }

.overlay-157 {
  position: relative; }
  .overlay-157:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-157:after {
        -webkit-backdrop-filter: brightness(calc(157 * 1%));
        backdrop-filter: brightness(calc(157 * 1%)); } }
  .overlay-157 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-157 {
  position: relative; }
  .overlay-rounded-157:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-157:after {
        -webkit-backdrop-filter: brightness(calc(157 * 1%));
        backdrop-filter: brightness(calc(157 * 1%)); } }
  .overlay-rounded-157 > * {
    position: relative;
    z-index: 1; }

.overlay-158 {
  position: relative; }
  .overlay-158:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-158:after {
        -webkit-backdrop-filter: brightness(calc(158 * 1%));
        backdrop-filter: brightness(calc(158 * 1%)); } }
  .overlay-158 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-158 {
  position: relative; }
  .overlay-rounded-158:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-158:after {
        -webkit-backdrop-filter: brightness(calc(158 * 1%));
        backdrop-filter: brightness(calc(158 * 1%)); } }
  .overlay-rounded-158 > * {
    position: relative;
    z-index: 1; }

.overlay-159 {
  position: relative; }
  .overlay-159:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-159:after {
        -webkit-backdrop-filter: brightness(calc(159 * 1%));
        backdrop-filter: brightness(calc(159 * 1%)); } }
  .overlay-159 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-159 {
  position: relative; }
  .overlay-rounded-159:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-159:after {
        -webkit-backdrop-filter: brightness(calc(159 * 1%));
        backdrop-filter: brightness(calc(159 * 1%)); } }
  .overlay-rounded-159 > * {
    position: relative;
    z-index: 1; }

.overlay-160 {
  position: relative; }
  .overlay-160:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-160:after {
        -webkit-backdrop-filter: brightness(calc(160 * 1%));
        backdrop-filter: brightness(calc(160 * 1%)); } }
  .overlay-160 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-160 {
  position: relative; }
  .overlay-rounded-160:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-160:after {
        -webkit-backdrop-filter: brightness(calc(160 * 1%));
        backdrop-filter: brightness(calc(160 * 1%)); } }
  .overlay-rounded-160 > * {
    position: relative;
    z-index: 1; }

.overlay-161 {
  position: relative; }
  .overlay-161:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-161:after {
        -webkit-backdrop-filter: brightness(calc(161 * 1%));
        backdrop-filter: brightness(calc(161 * 1%)); } }
  .overlay-161 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-161 {
  position: relative; }
  .overlay-rounded-161:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-161:after {
        -webkit-backdrop-filter: brightness(calc(161 * 1%));
        backdrop-filter: brightness(calc(161 * 1%)); } }
  .overlay-rounded-161 > * {
    position: relative;
    z-index: 1; }

.overlay-162 {
  position: relative; }
  .overlay-162:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-162:after {
        -webkit-backdrop-filter: brightness(calc(162 * 1%));
        backdrop-filter: brightness(calc(162 * 1%)); } }
  .overlay-162 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-162 {
  position: relative; }
  .overlay-rounded-162:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-162:after {
        -webkit-backdrop-filter: brightness(calc(162 * 1%));
        backdrop-filter: brightness(calc(162 * 1%)); } }
  .overlay-rounded-162 > * {
    position: relative;
    z-index: 1; }

.overlay-163 {
  position: relative; }
  .overlay-163:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-163:after {
        -webkit-backdrop-filter: brightness(calc(163 * 1%));
        backdrop-filter: brightness(calc(163 * 1%)); } }
  .overlay-163 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-163 {
  position: relative; }
  .overlay-rounded-163:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-163:after {
        -webkit-backdrop-filter: brightness(calc(163 * 1%));
        backdrop-filter: brightness(calc(163 * 1%)); } }
  .overlay-rounded-163 > * {
    position: relative;
    z-index: 1; }

.overlay-164 {
  position: relative; }
  .overlay-164:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-164:after {
        -webkit-backdrop-filter: brightness(calc(164 * 1%));
        backdrop-filter: brightness(calc(164 * 1%)); } }
  .overlay-164 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-164 {
  position: relative; }
  .overlay-rounded-164:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-164:after {
        -webkit-backdrop-filter: brightness(calc(164 * 1%));
        backdrop-filter: brightness(calc(164 * 1%)); } }
  .overlay-rounded-164 > * {
    position: relative;
    z-index: 1; }

.overlay-165 {
  position: relative; }
  .overlay-165:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-165:after {
        -webkit-backdrop-filter: brightness(calc(165 * 1%));
        backdrop-filter: brightness(calc(165 * 1%)); } }
  .overlay-165 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-165 {
  position: relative; }
  .overlay-rounded-165:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-165:after {
        -webkit-backdrop-filter: brightness(calc(165 * 1%));
        backdrop-filter: brightness(calc(165 * 1%)); } }
  .overlay-rounded-165 > * {
    position: relative;
    z-index: 1; }

.overlay-166 {
  position: relative; }
  .overlay-166:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-166:after {
        -webkit-backdrop-filter: brightness(calc(166 * 1%));
        backdrop-filter: brightness(calc(166 * 1%)); } }
  .overlay-166 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-166 {
  position: relative; }
  .overlay-rounded-166:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-166:after {
        -webkit-backdrop-filter: brightness(calc(166 * 1%));
        backdrop-filter: brightness(calc(166 * 1%)); } }
  .overlay-rounded-166 > * {
    position: relative;
    z-index: 1; }

.overlay-167 {
  position: relative; }
  .overlay-167:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-167:after {
        -webkit-backdrop-filter: brightness(calc(167 * 1%));
        backdrop-filter: brightness(calc(167 * 1%)); } }
  .overlay-167 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-167 {
  position: relative; }
  .overlay-rounded-167:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-167:after {
        -webkit-backdrop-filter: brightness(calc(167 * 1%));
        backdrop-filter: brightness(calc(167 * 1%)); } }
  .overlay-rounded-167 > * {
    position: relative;
    z-index: 1; }

.overlay-168 {
  position: relative; }
  .overlay-168:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-168:after {
        -webkit-backdrop-filter: brightness(calc(168 * 1%));
        backdrop-filter: brightness(calc(168 * 1%)); } }
  .overlay-168 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-168 {
  position: relative; }
  .overlay-rounded-168:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-168:after {
        -webkit-backdrop-filter: brightness(calc(168 * 1%));
        backdrop-filter: brightness(calc(168 * 1%)); } }
  .overlay-rounded-168 > * {
    position: relative;
    z-index: 1; }

.overlay-169 {
  position: relative; }
  .overlay-169:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-169:after {
        -webkit-backdrop-filter: brightness(calc(169 * 1%));
        backdrop-filter: brightness(calc(169 * 1%)); } }
  .overlay-169 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-169 {
  position: relative; }
  .overlay-rounded-169:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-169:after {
        -webkit-backdrop-filter: brightness(calc(169 * 1%));
        backdrop-filter: brightness(calc(169 * 1%)); } }
  .overlay-rounded-169 > * {
    position: relative;
    z-index: 1; }

.overlay-170 {
  position: relative; }
  .overlay-170:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-170:after {
        -webkit-backdrop-filter: brightness(calc(170 * 1%));
        backdrop-filter: brightness(calc(170 * 1%)); } }
  .overlay-170 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-170 {
  position: relative; }
  .overlay-rounded-170:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-170:after {
        -webkit-backdrop-filter: brightness(calc(170 * 1%));
        backdrop-filter: brightness(calc(170 * 1%)); } }
  .overlay-rounded-170 > * {
    position: relative;
    z-index: 1; }

.overlay-171 {
  position: relative; }
  .overlay-171:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-171:after {
        -webkit-backdrop-filter: brightness(calc(171 * 1%));
        backdrop-filter: brightness(calc(171 * 1%)); } }
  .overlay-171 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-171 {
  position: relative; }
  .overlay-rounded-171:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-171:after {
        -webkit-backdrop-filter: brightness(calc(171 * 1%));
        backdrop-filter: brightness(calc(171 * 1%)); } }
  .overlay-rounded-171 > * {
    position: relative;
    z-index: 1; }

.overlay-172 {
  position: relative; }
  .overlay-172:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-172:after {
        -webkit-backdrop-filter: brightness(calc(172 * 1%));
        backdrop-filter: brightness(calc(172 * 1%)); } }
  .overlay-172 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-172 {
  position: relative; }
  .overlay-rounded-172:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-172:after {
        -webkit-backdrop-filter: brightness(calc(172 * 1%));
        backdrop-filter: brightness(calc(172 * 1%)); } }
  .overlay-rounded-172 > * {
    position: relative;
    z-index: 1; }

.overlay-173 {
  position: relative; }
  .overlay-173:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-173:after {
        -webkit-backdrop-filter: brightness(calc(173 * 1%));
        backdrop-filter: brightness(calc(173 * 1%)); } }
  .overlay-173 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-173 {
  position: relative; }
  .overlay-rounded-173:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-173:after {
        -webkit-backdrop-filter: brightness(calc(173 * 1%));
        backdrop-filter: brightness(calc(173 * 1%)); } }
  .overlay-rounded-173 > * {
    position: relative;
    z-index: 1; }

.overlay-174 {
  position: relative; }
  .overlay-174:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-174:after {
        -webkit-backdrop-filter: brightness(calc(174 * 1%));
        backdrop-filter: brightness(calc(174 * 1%)); } }
  .overlay-174 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-174 {
  position: relative; }
  .overlay-rounded-174:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-174:after {
        -webkit-backdrop-filter: brightness(calc(174 * 1%));
        backdrop-filter: brightness(calc(174 * 1%)); } }
  .overlay-rounded-174 > * {
    position: relative;
    z-index: 1; }

.overlay-175 {
  position: relative; }
  .overlay-175:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-175:after {
        -webkit-backdrop-filter: brightness(calc(175 * 1%));
        backdrop-filter: brightness(calc(175 * 1%)); } }
  .overlay-175 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-175 {
  position: relative; }
  .overlay-rounded-175:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-175:after {
        -webkit-backdrop-filter: brightness(calc(175 * 1%));
        backdrop-filter: brightness(calc(175 * 1%)); } }
  .overlay-rounded-175 > * {
    position: relative;
    z-index: 1; }

.overlay-176 {
  position: relative; }
  .overlay-176:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-176:after {
        -webkit-backdrop-filter: brightness(calc(176 * 1%));
        backdrop-filter: brightness(calc(176 * 1%)); } }
  .overlay-176 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-176 {
  position: relative; }
  .overlay-rounded-176:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-176:after {
        -webkit-backdrop-filter: brightness(calc(176 * 1%));
        backdrop-filter: brightness(calc(176 * 1%)); } }
  .overlay-rounded-176 > * {
    position: relative;
    z-index: 1; }

.overlay-177 {
  position: relative; }
  .overlay-177:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-177:after {
        -webkit-backdrop-filter: brightness(calc(177 * 1%));
        backdrop-filter: brightness(calc(177 * 1%)); } }
  .overlay-177 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-177 {
  position: relative; }
  .overlay-rounded-177:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-177:after {
        -webkit-backdrop-filter: brightness(calc(177 * 1%));
        backdrop-filter: brightness(calc(177 * 1%)); } }
  .overlay-rounded-177 > * {
    position: relative;
    z-index: 1; }

.overlay-178 {
  position: relative; }
  .overlay-178:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-178:after {
        -webkit-backdrop-filter: brightness(calc(178 * 1%));
        backdrop-filter: brightness(calc(178 * 1%)); } }
  .overlay-178 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-178 {
  position: relative; }
  .overlay-rounded-178:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-178:after {
        -webkit-backdrop-filter: brightness(calc(178 * 1%));
        backdrop-filter: brightness(calc(178 * 1%)); } }
  .overlay-rounded-178 > * {
    position: relative;
    z-index: 1; }

.overlay-179 {
  position: relative; }
  .overlay-179:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-179:after {
        -webkit-backdrop-filter: brightness(calc(179 * 1%));
        backdrop-filter: brightness(calc(179 * 1%)); } }
  .overlay-179 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-179 {
  position: relative; }
  .overlay-rounded-179:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-179:after {
        -webkit-backdrop-filter: brightness(calc(179 * 1%));
        backdrop-filter: brightness(calc(179 * 1%)); } }
  .overlay-rounded-179 > * {
    position: relative;
    z-index: 1; }

.overlay-180 {
  position: relative; }
  .overlay-180:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-180:after {
        -webkit-backdrop-filter: brightness(calc(180 * 1%));
        backdrop-filter: brightness(calc(180 * 1%)); } }
  .overlay-180 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-180 {
  position: relative; }
  .overlay-rounded-180:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-180:after {
        -webkit-backdrop-filter: brightness(calc(180 * 1%));
        backdrop-filter: brightness(calc(180 * 1%)); } }
  .overlay-rounded-180 > * {
    position: relative;
    z-index: 1; }

.overlay-181 {
  position: relative; }
  .overlay-181:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-181:after {
        -webkit-backdrop-filter: brightness(calc(181 * 1%));
        backdrop-filter: brightness(calc(181 * 1%)); } }
  .overlay-181 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-181 {
  position: relative; }
  .overlay-rounded-181:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-181:after {
        -webkit-backdrop-filter: brightness(calc(181 * 1%));
        backdrop-filter: brightness(calc(181 * 1%)); } }
  .overlay-rounded-181 > * {
    position: relative;
    z-index: 1; }

.overlay-182 {
  position: relative; }
  .overlay-182:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-182:after {
        -webkit-backdrop-filter: brightness(calc(182 * 1%));
        backdrop-filter: brightness(calc(182 * 1%)); } }
  .overlay-182 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-182 {
  position: relative; }
  .overlay-rounded-182:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-182:after {
        -webkit-backdrop-filter: brightness(calc(182 * 1%));
        backdrop-filter: brightness(calc(182 * 1%)); } }
  .overlay-rounded-182 > * {
    position: relative;
    z-index: 1; }

.overlay-183 {
  position: relative; }
  .overlay-183:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-183:after {
        -webkit-backdrop-filter: brightness(calc(183 * 1%));
        backdrop-filter: brightness(calc(183 * 1%)); } }
  .overlay-183 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-183 {
  position: relative; }
  .overlay-rounded-183:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-183:after {
        -webkit-backdrop-filter: brightness(calc(183 * 1%));
        backdrop-filter: brightness(calc(183 * 1%)); } }
  .overlay-rounded-183 > * {
    position: relative;
    z-index: 1; }

.overlay-184 {
  position: relative; }
  .overlay-184:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-184:after {
        -webkit-backdrop-filter: brightness(calc(184 * 1%));
        backdrop-filter: brightness(calc(184 * 1%)); } }
  .overlay-184 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-184 {
  position: relative; }
  .overlay-rounded-184:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-184:after {
        -webkit-backdrop-filter: brightness(calc(184 * 1%));
        backdrop-filter: brightness(calc(184 * 1%)); } }
  .overlay-rounded-184 > * {
    position: relative;
    z-index: 1; }

.overlay-185 {
  position: relative; }
  .overlay-185:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-185:after {
        -webkit-backdrop-filter: brightness(calc(185 * 1%));
        backdrop-filter: brightness(calc(185 * 1%)); } }
  .overlay-185 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-185 {
  position: relative; }
  .overlay-rounded-185:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-185:after {
        -webkit-backdrop-filter: brightness(calc(185 * 1%));
        backdrop-filter: brightness(calc(185 * 1%)); } }
  .overlay-rounded-185 > * {
    position: relative;
    z-index: 1; }

.overlay-186 {
  position: relative; }
  .overlay-186:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-186:after {
        -webkit-backdrop-filter: brightness(calc(186 * 1%));
        backdrop-filter: brightness(calc(186 * 1%)); } }
  .overlay-186 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-186 {
  position: relative; }
  .overlay-rounded-186:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-186:after {
        -webkit-backdrop-filter: brightness(calc(186 * 1%));
        backdrop-filter: brightness(calc(186 * 1%)); } }
  .overlay-rounded-186 > * {
    position: relative;
    z-index: 1; }

.overlay-187 {
  position: relative; }
  .overlay-187:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-187:after {
        -webkit-backdrop-filter: brightness(calc(187 * 1%));
        backdrop-filter: brightness(calc(187 * 1%)); } }
  .overlay-187 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-187 {
  position: relative; }
  .overlay-rounded-187:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-187:after {
        -webkit-backdrop-filter: brightness(calc(187 * 1%));
        backdrop-filter: brightness(calc(187 * 1%)); } }
  .overlay-rounded-187 > * {
    position: relative;
    z-index: 1; }

.overlay-188 {
  position: relative; }
  .overlay-188:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-188:after {
        -webkit-backdrop-filter: brightness(calc(188 * 1%));
        backdrop-filter: brightness(calc(188 * 1%)); } }
  .overlay-188 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-188 {
  position: relative; }
  .overlay-rounded-188:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-188:after {
        -webkit-backdrop-filter: brightness(calc(188 * 1%));
        backdrop-filter: brightness(calc(188 * 1%)); } }
  .overlay-rounded-188 > * {
    position: relative;
    z-index: 1; }

.overlay-189 {
  position: relative; }
  .overlay-189:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-189:after {
        -webkit-backdrop-filter: brightness(calc(189 * 1%));
        backdrop-filter: brightness(calc(189 * 1%)); } }
  .overlay-189 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-189 {
  position: relative; }
  .overlay-rounded-189:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-189:after {
        -webkit-backdrop-filter: brightness(calc(189 * 1%));
        backdrop-filter: brightness(calc(189 * 1%)); } }
  .overlay-rounded-189 > * {
    position: relative;
    z-index: 1; }

.overlay-190 {
  position: relative; }
  .overlay-190:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-190:after {
        -webkit-backdrop-filter: brightness(calc(190 * 1%));
        backdrop-filter: brightness(calc(190 * 1%)); } }
  .overlay-190 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-190 {
  position: relative; }
  .overlay-rounded-190:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-190:after {
        -webkit-backdrop-filter: brightness(calc(190 * 1%));
        backdrop-filter: brightness(calc(190 * 1%)); } }
  .overlay-rounded-190 > * {
    position: relative;
    z-index: 1; }

.overlay-191 {
  position: relative; }
  .overlay-191:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-191:after {
        -webkit-backdrop-filter: brightness(calc(191 * 1%));
        backdrop-filter: brightness(calc(191 * 1%)); } }
  .overlay-191 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-191 {
  position: relative; }
  .overlay-rounded-191:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-191:after {
        -webkit-backdrop-filter: brightness(calc(191 * 1%));
        backdrop-filter: brightness(calc(191 * 1%)); } }
  .overlay-rounded-191 > * {
    position: relative;
    z-index: 1; }

.overlay-192 {
  position: relative; }
  .overlay-192:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-192:after {
        -webkit-backdrop-filter: brightness(calc(192 * 1%));
        backdrop-filter: brightness(calc(192 * 1%)); } }
  .overlay-192 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-192 {
  position: relative; }
  .overlay-rounded-192:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-192:after {
        -webkit-backdrop-filter: brightness(calc(192 * 1%));
        backdrop-filter: brightness(calc(192 * 1%)); } }
  .overlay-rounded-192 > * {
    position: relative;
    z-index: 1; }

.overlay-193 {
  position: relative; }
  .overlay-193:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-193:after {
        -webkit-backdrop-filter: brightness(calc(193 * 1%));
        backdrop-filter: brightness(calc(193 * 1%)); } }
  .overlay-193 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-193 {
  position: relative; }
  .overlay-rounded-193:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-193:after {
        -webkit-backdrop-filter: brightness(calc(193 * 1%));
        backdrop-filter: brightness(calc(193 * 1%)); } }
  .overlay-rounded-193 > * {
    position: relative;
    z-index: 1; }

.overlay-194 {
  position: relative; }
  .overlay-194:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-194:after {
        -webkit-backdrop-filter: brightness(calc(194 * 1%));
        backdrop-filter: brightness(calc(194 * 1%)); } }
  .overlay-194 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-194 {
  position: relative; }
  .overlay-rounded-194:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-194:after {
        -webkit-backdrop-filter: brightness(calc(194 * 1%));
        backdrop-filter: brightness(calc(194 * 1%)); } }
  .overlay-rounded-194 > * {
    position: relative;
    z-index: 1; }

.overlay-195 {
  position: relative; }
  .overlay-195:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-195:after {
        -webkit-backdrop-filter: brightness(calc(195 * 1%));
        backdrop-filter: brightness(calc(195 * 1%)); } }
  .overlay-195 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-195 {
  position: relative; }
  .overlay-rounded-195:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-195:after {
        -webkit-backdrop-filter: brightness(calc(195 * 1%));
        backdrop-filter: brightness(calc(195 * 1%)); } }
  .overlay-rounded-195 > * {
    position: relative;
    z-index: 1; }

.overlay-196 {
  position: relative; }
  .overlay-196:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-196:after {
        -webkit-backdrop-filter: brightness(calc(196 * 1%));
        backdrop-filter: brightness(calc(196 * 1%)); } }
  .overlay-196 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-196 {
  position: relative; }
  .overlay-rounded-196:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-196:after {
        -webkit-backdrop-filter: brightness(calc(196 * 1%));
        backdrop-filter: brightness(calc(196 * 1%)); } }
  .overlay-rounded-196 > * {
    position: relative;
    z-index: 1; }

.overlay-197 {
  position: relative; }
  .overlay-197:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-197:after {
        -webkit-backdrop-filter: brightness(calc(197 * 1%));
        backdrop-filter: brightness(calc(197 * 1%)); } }
  .overlay-197 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-197 {
  position: relative; }
  .overlay-rounded-197:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-197:after {
        -webkit-backdrop-filter: brightness(calc(197 * 1%));
        backdrop-filter: brightness(calc(197 * 1%)); } }
  .overlay-rounded-197 > * {
    position: relative;
    z-index: 1; }

.overlay-198 {
  position: relative; }
  .overlay-198:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-198:after {
        -webkit-backdrop-filter: brightness(calc(198 * 1%));
        backdrop-filter: brightness(calc(198 * 1%)); } }
  .overlay-198 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-198 {
  position: relative; }
  .overlay-rounded-198:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-198:after {
        -webkit-backdrop-filter: brightness(calc(198 * 1%));
        backdrop-filter: brightness(calc(198 * 1%)); } }
  .overlay-rounded-198 > * {
    position: relative;
    z-index: 1; }

.overlay-199 {
  position: relative; }
  .overlay-199:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-199:after {
        -webkit-backdrop-filter: brightness(calc(199 * 1%));
        backdrop-filter: brightness(calc(199 * 1%)); } }
  .overlay-199 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-199 {
  position: relative; }
  .overlay-rounded-199:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-199:after {
        -webkit-backdrop-filter: brightness(calc(199 * 1%));
        backdrop-filter: brightness(calc(199 * 1%)); } }
  .overlay-rounded-199 > * {
    position: relative;
    z-index: 1; }

.overlay-200 {
  position: relative; }
  .overlay-200:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-200:after {
        -webkit-backdrop-filter: brightness(calc(200 * 1%));
        backdrop-filter: brightness(calc(200 * 1%)); } }
  .overlay-200 > * {
    position: relative;
    z-index: 1; }

.overlay-rounded-200 {
  position: relative; }
  .overlay-rounded-200:after {
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    position: absolute;
    border-radius: 0; }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .overlay-rounded-200:after {
        -webkit-backdrop-filter: brightness(calc(200 * 1%));
        backdrop-filter: brightness(calc(200 * 1%)); } }
  .overlay-rounded-200 > * {
    position: relative;
    z-index: 1; }

[theme="dark"] h1,
[theme="dark"] h2,
[theme="dark"] h3,
[theme="dark"] h4,
[theme="dark"] h5,
[theme="dark"] h6,
[theme="dark"] .table th {
  color: #fff; }

[theme="dark"] p,
[theme="dark"] a:not(.btn),
[theme="dark"] ul,
[theme="dark"] label,
[theme="dark"] .table td,
[theme="dark"] ul li {
  color: #fff; }

[theme="default"] h1,
[theme="default"] h2,
[theme="default"] h3,
[theme="default"] h4,
[theme="default"] h5,
[theme="default"] h6,
[theme="default"] .table th {
  color: #1b1b1b; }

[theme="default"] p,
[theme="default"] a:not(.btn),
[theme="default"] ul,
[theme="default"] label,
[theme="default"] .table td,
[theme="default"] ul li {
  color: #666; }

@media (max-width: 575.98px) {
  .float-down-left {
    float: left !important; }
  .float-down-right {
    float: right !important; }
  .float-down-none {
    float: none !important; } }

@media (max-width: 767.98px) {
  .float-sm-down-left {
    float: left !important; }
  .float-sm-down-right {
    float: right !important; }
  .float-sm-down-none {
    float: none !important; } }

@media (max-width: 991.98px) {
  .float-md-down-left {
    float: left !important; }
  .float-md-down-right {
    float: right !important; }
  .float-md-down-none {
    float: none !important; } }

@media (max-width: 1199.98px) {
  .float-lg-down-left {
    float: left !important; }
  .float-lg-down-right {
    float: right !important; }
  .float-lg-down-none {
    float: none !important; } }

.float-xl-down-left {
  float: left !important; }

.float-xl-down-right {
  float: right !important; }

.float-xl-down-none {
  float: none !important; }

.ajax-loading-block-window {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(173, 181, 189, 0.4);
  display: flex;
  align-items: center;
  justify-content: center; }

.ajax-loading-block-window .fa {
  font-size: 9em; }
