.collapse-toggle {
	width: 100%;
	display: block;
	padding-right: 2rem;
	position: relative;

	@include hover-state {
		text-decoration: none;
	}

	&::after {
		@include position(absolute, 0.5rem, 0.5rem, auto, auto);
		font-family: 'Ionicons';
		content: '\f2f4';
		line-height: 1;
		color: $gray-400;
		font-size: 1.25rem;
	}

	&.collapsed {
		&::after {
			content: '\f2c7';
		}
	}
}
