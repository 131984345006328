.icon-nav {
    .nav-item {
        .nav-link {
            color: $gray-900;
            display: flex;
            align-items: center;
            padding-left: 0;
            padding-right: 0;
            transition: $transition-base;

            span,
            .icon-box {
                vertical-align: middle;
            }

            .icon-box {
				width: 1.25rem;
				height: 1.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-basis: 1.25rem;
                margin-right: spacers(2);
            }

            &:hover,
            &.active {
                color: theme-color('primary');
            }
        }
    }
}
