.btn {
	font-size: 0.9rem;
	white-space: initial;
	border-radius: 1px;
	cursor:pointer;

	span {
		vertical-align: middle;
	}

	.icon {
		font-size: 120%;
		padding-right: 0.5rem;
		vertical-align: middle;
	}

	&.btn-icon-right {
		.icon {
			padding-right: 0;
			padding-left: 0.5rem;
		}
	}

	&.btn-outline-secondary {
		font-weight: 500;
		color: $gray-900;
	}

	&.btn-link {
		padding: 0;
		border: 0;
		@include text-crop(1);
	}

	&.btn-white {
		background-color: $white;
		box-shadow: $box-shadow;
		position: relative;
		color: $dark;

		&::after {
			@include position(absolute, auto, #{spacers(4)}, auto, auto);
			width: 20px;
			height: 20px;
			font-family: "ionicons", sans-serif;
			content: "\f30f";
			line-height: 20px;
			text-align: center;
			font-size: 1.2rem;
			color: theme-color('primary');
		}

		&:not(.disabled) {
			&:hover,
			&:focus {
				background-color: theme-color('primary');
				color: $white;

				&,
				&::after {
					color: $white;
				}
			}
		}
	}

	&.btn-circle {
		&.btn-sm {
			width: 2.2rem;
			height: 2.2rem;
		}

		&.btn-lg {
			width: 3.5rem;
			height: 3.5rem;
		}

		width: 2.6rem;
		height: 2.6rem;
		text-align: center;
		border-radius: 50%;
		padding: 0;
		font-size: 1.1rem;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			padding: 0;
		}
	}

	&.btn-icon {
		position: relative;
		background-color: $white;
		color: theme-color('primary');
		text-align: left;
		font-size: 1.2rem;
		border-radius: 0;
		display: flex;
		align-items: center;
		padding: 1rem;

		.icon {
			@include square(3.5rem);
			margin-right: spacers(3);
			padding: 0;
		}

		&::after {
			@include position(absolute, auto, #{spacers(3)}, auto, auto);
			font-family: "ionicons", sans-serif;
			content: "\f30f";
			text-align: center;
			font-size: 1.4rem;
			color: theme-color('primary');
		}

		&:hover,
		&:focus {
			background-color: theme-color('primary');

			&,
			&::after {
				color: $white;
			}
		}
	}

	@each $color, $value in $theme-colors {
		&.btn-#{$color} {
			&:disabled {
				background-color: $gray-600;
				border-color: $gray-600;
				color: $white;
			}
		}
	}
}

.search-btn-circle {
	a.text-uppercase {
		@include media-breakpoint-down(sm) {
			font-size: 90%;
		}

		@include media-breakpoint-down(xs) {
			font-size: 80%;
		}
	}
}

.circle-green {
	width: 5rem;
	height: 5rem;
	background-color: theme-color(primary);
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	vertical-align: top;
	padding: 0;
	color: color-yiq(theme-color(primary));
	@include gradient-bg(theme-color(primary));
	border-color: theme-color(primary);

	@include hover {
		color: color-yiq(darken(theme-color(primary), 7.5%));
		@include gradient-bg(darken(theme-color(primary), 7.5%));
		border-color: darken(theme-color(primary), 7.5%);
	}

	@include media-breakpoint-down(sm) {
		width: 4rem;
		height: 4rem;
		background-size: 80%;
	}

	@include media-breakpoint-down(xs) {
		width: 3rem;
		height: 3rem;
		background-size: 60%;
	}
}


.codekind-car {
	background-image: $search-codekind-car;
}

.codekind-mc {
	background-image: $search-codekind-mc;
}

.codekind-rv {
	background-image: $search-codekind-rv;
}

.codekind-truck {
	background-image: $search-codekind-truck;
}

.codekind-tire {
	background-image: $search-codekind-tire;
}

.codekind-trailer {
	background-image: $search-codekind-trailer;
}