﻿.product-details {
	margin-top: 40px;
	width: 100%;

	tbody {
		tr {
			border-bottom-color: $gray-500;
			border-bottom-style: solid;
			border-bottom-width: 0.5px;
			height: 1.9rem;

			th {
				width: 40%;
				line-height: 3rem;
			}

			td {
				width: 60%;
				line-height: 3rem;
			}
		}
	}
}

#filterPartCodes {
	width:100%;
	padding: 2rem 2rem 2rem 3rem;
	background-position:1rem;
}

@include media-breakpoint-down(lg) {
	.filterPartCodesWrapper {
		padding:1rem;
	}
}

.product-details-module {
	padding-top: 0;
}



.product-details-price-buy {
	margin-top: 10px;

	.div1 {
	}

	.div2 {

		button {

		}
	}

	.col-6 {

		.span {

		}
	}
}

.product-details-car-details {
	div {
		div {
			div {
				border-bottom-color: $gray-500;
				border-bottom-style: solid;
				border-bottom-width: 0.5px;
				height: 1.9rem;
			}
		}
	}
}

.product-car-details-module {
	padding-top: 25px;
	padding-bottom: 25px;

	.detail-text {
		line-height:3rem;
		height:3rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.product-car-details-module-divider {
	padding-top: 10px;
	padding-bottom: 10px;
}

.product-details-relatedproducts-price-buy-price {
	color: $black;
	text-transform: none;

	h4 {
		padding-top: 10px;
		font-weight: $font-weight-normal;
		text-transform: none;
	}
}

.product-price-display {
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 2.6rem;
	white-space:nowrap;
}

.product-price {
	padding-top: 8px;

	h4 {
		color: $black;
		font-weight: 500;
		text-transform: none;
	}
}

.product-search-entry {
	padding-top: 30px;

	.btn {
		padding: 0.5rem 0;
	}

	.product-search-image-card {
		h4 {
			font-weight: $font-weight-normal;
		}
	}

	.product-search-image-card-body {
		padding-top: 0.2rem;
		padding-bottom: 0px;
		padding-left: 0px;
		padding-right: 0px;

		.header1 {
			font-weight: $font-weight-normal;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-size: 1.45rem;

			&:hover {
				color: darken(theme-color('primary'), 10%) !important;
			}
		}

		a:hover {
			text-decoration: none;
		}

		.detail-text {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.grid-row {
			padding-top: 5px;
			padding-bottom: 10px;
		}
	}
}

.product-search-filter {
	padding-bottom: 1rem;
}