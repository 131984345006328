.icon {
	height:1rem;
	width:1rem;
	display:inline-block;
	&.ion-ios-cart {
		background-image: url('../images/cart.png');
		background-position:center;
		background-repeat:no-repeat;
		background-size:1rem;
	}
}

.icon-cart {
	height: 1.5rem;
	width: 1.5rem;
	display: inline-block;
	background-image: url('../images/cart.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1rem;
}