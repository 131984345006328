.section {
	width: 100%;
	height: auto;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	/*padding: 3rem 0;*/
	position: relative;
	background-color: $body-bg;
	margin-top:2rem;

	@include media-breakpoint-down(sm) {
		padding: 2.5rem 0;
	}

	&.section-lg {
		padding: 6rem 0;

		@include media-breakpoint-down(sm) {
			padding: 4rem 0;
		}
	}

	&.section-xl {
		padding: 8rem 0;

		@include media-breakpoint-down(sm) {
			padding: 5rem 0;
		}
	}

	&.section-sm {
		padding: 1.5rem 0;
	}

	&.section-0 {
		padding: 0;
	}

	&.intro-background-image {
		background-image: $header-background-image;
	}

	.image {
		width: 100%;
		position: relative;
	}

	.carousel-caption {

		top: 50%;
		transform: translateY(-70%);
		
	}

	.negate-p p {
		margin-bottom: 0;
	}

}

.section-side-padding {
	@include media-breakpoint-down(sm) {
		padding: 2.5rem 1rem;
	}
}
