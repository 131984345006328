﻿@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-down($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.float#{$infix}-down-left {
			float: left !important;
		}

		.float#{$infix}-down-right {
			float: right !important;
		}

		.float#{$infix}-down-none {
			float: none !important;
		}
	}
}
