form {
    .form-control.border-color-none {
        &, 
        &:focus {
            border-color: $white;
        }
    }
}

.custom-select {
    width: auto;
	border-radius:1px;
}

.input-group-text {
    flex-direction: column;
    justify-content: center;
    padding: spacers(1) spacers(3);
}

.input-group-text-icon {
	width: 20px;
	height: 20px;
    background-repeat: no-repeat;
    background-size: contain;

    &.eu {
        background-image: url('../images/eu.png');
    }
}

.fieldset {
    padding-bottom: 1rem;

    .title {
        padding-bottom: 1rem;
    }
}

.inputs {
    overflow: auto;
    margin-bottom: 1rem;
}

.column-label {
    width:  40%;
    float: left;
    padding-top: 1rem;
}

.column-input {
    width: 60%;
    float: left;

    input {
        padding-left: 1rem;
    }
}

.align-right {
    float: right;
}

.control{
    overflow: auto;

    .wSmall {
        width: 4%;
    }

    .wLarge {
        width: 95%;
    }

    .label {
        float: left;
        padding-left: .5rem;

        label {
            display: block;
        }
    }
    .input {
        float: left;

        input {
            display: block;
        }
    }
}

.text-smaller {
    font-size: 65%;
}

.message-form {
    border-color: #eee;
    outline: none;
    border-radius: .4rem;
}

.invalid-feedback {
	color: #dc3545 !important;

	span {
		color: #dc3545 !important;
	}
}
.form-control:valid {
	background-image: none !important;
	padding-right: 1.9rem !important;
}
.form-control {
    border-radius: 0;
    border: none;
    background-color: theme-color("light");
    &.bg-transparent {
        transition: none;
    }
}


.form-control-sm {
	border-radius: 0;
}

.form-control:focus {
    background-color: theme-color("light");
    border: solid 1px theme-color("dark");
    border-radius: 0.3em;
    &.bg-transparent {
        border: none;
    }
}