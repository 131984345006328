[theme="dark"] {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.table th {
		color: $white;
	}

	p,
	a:not(.btn),
	ul,
	label,
	.table td,
	ul li {
		color: $white;
	}
}

[theme="default"] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .table th {
        color: $gray-900;
    }

    p,
    a:not(.btn),
    ul,
    label,
    .table td,
    ul li {
        color: $gray-700;
    }
}
