.ratio-1by1 {
    @include aspect-ratio(1, 1, true);
}
.ratio-1by2 {
    @include aspect-ratio(1, 2, true);
}
.ratio-1by3 {
    @include aspect-ratio(1, 2, true);
}
.ratio-1by4 {
    @include aspect-ratio(1, 3, true);
}
.ratio-1by5 {
    @include aspect-ratio(1, 5, true);
}
.ratio-1by6 {
    @include aspect-ratio(1, 6, true);
}
.ratio-1by7 {
    @include aspect-ratio(1, 7, true);
}
.ratio-1by8 {
    @include aspect-ratio(1, 8, true);
}
.ratio-1by9 {
    @include aspect-ratio(1, 9, true);
}

.ratio-2by1 {
    @include aspect-ratio(2, 1, true);
}
.ratio-2by3 {
    @include aspect-ratio(2, 3, true);
}
.ratio-2by4 {
    @include aspect-ratio(2, 4, true);
}
.ratio-2by5 {
    @include aspect-ratio(2, 5, true);
}
.ratio-2by6 {
    @include aspect-ratio(2, 6, true);
}
.ratio-2by7 {
    @include aspect-ratio(2, 7, true);
}
.ratio-2by8 {
    @include aspect-ratio(2, 8, true);
}
.ratio-2by9 {
    @include aspect-ratio(2, 9, true);
}

.ratio-3by1 {
    @include aspect-ratio(3, 1, true);
}
.ratio-3by2 {
    @include aspect-ratio(3, 2, true);
}
.ratio-3by4 {
    @include aspect-ratio(3, 4, true);
}
.ratio-3by5 {
    @include aspect-ratio(3, 5, true);
}
.ratio-3by6 {
    @include aspect-ratio(3, 6, true);
}
.ratio-3by7 {
    @include aspect-ratio(3, 7, true);
}
.ratio-3by8 {
    @include aspect-ratio(3, 8, true);
}
.ratio-3by9 {
    @include aspect-ratio(3, 9, true);
}

.ratio-4by1 {
    @include aspect-ratio(4, 1, true);
}
.ratio-4by2 {
    @include aspect-ratio(4, 2, true);
}
.ratio-4by3 {
    @include aspect-ratio(4, 3, true);
}
.ratio-4by5 {
    @include aspect-ratio(4, 5, true);
}
.ratio-4by6 {
    @include aspect-ratio(4, 6, true);
}
.ratio-4by7 {
    @include aspect-ratio(4, 7, true);
}
.ratio-4by8 {
    @include aspect-ratio(4, 8, true);
}
.ratio-4by9 {
    @include aspect-ratio(4, 9, true);
}

.ratio-5by1 {
    @include aspect-ratio(5, 1, true);
}
.ratio-5by2 {
    @include aspect-ratio(5, 2, true);
}
.ratio-5by3 {
    @include aspect-ratio(5, 3, true);
}
.ratio-5by4 {
    @include aspect-ratio(5, 4, true);
}
.ratio-5by6 {
    @include aspect-ratio(5, 6, true);
}
.ratio-5by7 {
    @include aspect-ratio(5, 7, true);
}
.ratio-5by8 {
    @include aspect-ratio(5, 8, true);
}
.ratio-5by9 {
    @include aspect-ratio(5, 9, true);
}

.ratio-6by1 {
    @include aspect-ratio(6, 1, true);
}
.ratio-6by2 {
    @include aspect-ratio(6, 2, true);
}
.ratio-6by3 {
    @include aspect-ratio(6, 3, true);
}
.ratio-6by4 {
    @include aspect-ratio(6, 4, true);
}
.ratio-6by5 {
    @include aspect-ratio(6, 5, true);
}
.ratio-6by7 {
    @include aspect-ratio(6, 7, true);
}
.ratio-6by8 {
    @include aspect-ratio(6, 8, true);
}
.ratio-6by9 {
    @include aspect-ratio(6, 9, true);
}

.ratio-7by1 {
    @include aspect-ratio(7, 1, true);
}
.ratio-7by2 {
    @include aspect-ratio(7, 2, true);
}
.ratio-7by3 {
    @include aspect-ratio(7, 3, true);
}
.ratio-7by4 {
    @include aspect-ratio(7, 4, true);
}
.ratio-7by5 {
    @include aspect-ratio(7, 5, true);
}
.ratio-7by6 {
    @include aspect-ratio(7, 6, true);
}
.ratio-7by8 {
    @include aspect-ratio(7, 8, true);
}
.ratio-7by9 {
    @include aspect-ratio(7, 9, true);
}

.ratio-8by1 {
    @include aspect-ratio(8, 1, true);
}
.ratio-8by2 {
    @include aspect-ratio(8, 2, true);
}
.ratio-8by3 {
    @include aspect-ratio(8, 3, true);
}
.ratio-8by4 {
    @include aspect-ratio(8, 4, true);
}
.ratio-8by5 {
    @include aspect-ratio(8, 5, true);
}
.ratio-8by6 {
    @include aspect-ratio(8, 6, true);
}
.ratio-8by7 {
    @include aspect-ratio(8, 7, true);
}
.ratio-8by9 {
    @include aspect-ratio(8, 9, true);
}

.ratio-9by1 {
    @include aspect-ratio(9, 1, true);
}
.ratio-9by2 {
    @include aspect-ratio(9, 2, true);
}
.ratio-9by3 {
    @include aspect-ratio(9, 3, true);
}
.ratio-9by4 {
    @include aspect-ratio(9, 4, true);
}
.ratio-9by5 {
    @include aspect-ratio(9, 5, true);
}
.ratio-9by6 {
    @include aspect-ratio(9, 6, true);
}
.ratio-9by7 {
    @include aspect-ratio(9, 7, true);
}
.ratio-9by8 {
    @include aspect-ratio(9, 8, true);
}

