.table {
    th {
        color: theme-color('dark');
    }

    th,
    td {
        padding: spacers(1) 0;
    }

    td {
        vertical-align: middle;
    }
}
