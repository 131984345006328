.dot {
    @include square(7px);

    border-radius: 50%;
    display: inline-block;
    background-color: theme-color('primary');
    vertical-align: middle;

    &.dot-lg {
        @include square(10px);
    }

    &.dot-danger {
        background-color: theme-color('danger');
    }

    &.dot-success {
        background-color: theme-color('success');
    }

    &.dot-warning {
        background-color: theme-color('warning');
    }
}

.bg-center-cover {
    @include center-cover();
}

.text-crop {
    @include text-crop(1);
}

.fixed {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 20;
}

@include media-breakpoint-down(xs) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
}

@include media-breakpoint-down(xs) {
    .w-xs-100 {
        width: 100% !important;
    }
}

.big {
    font-size: 120% !important;
}

.big-lg {
    font-size: 165% !important;
}

@include media-breakpoint-up(md) {
    .overflow-buttons {
        position: relative;

        > div {
            @include position(absolute, -46px, 0, auto, 0);
        }
    }
}

.word-break {
    word-break: break-word;
}

.text-xxl {
    font-size: 8rem;

    @include media-breakpoint-up(sm) {
        font-size: 10rem;
    }
}