.ui-autocomplete {
  top: 85px !important;
  height: 260px;
  overflow: auto;
  border-radius: .4rem !important;
  border-style: none !important;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, .5);

  .ui-menu-item {
    border-style: none !important;
    cursor: pointer !important;

    a.ui-state-focus {
      margin: 0 !important;
      background: none !important;
      display: block !important;
      color: black !important;
      padding: 1rem !important;
      padding-left: 1.5rem !important;
      font-weight: bold !important;
      background-color: #f4f4f4 !important;
      border-style: none !important;
      color: #24a47a !important;
    }
  }

  .ui-corner-all {
    margin: 0 !important;
    display: block !important;
    color: black !important;
    padding: 1rem !important;
    padding-left: 1.5rem !important;
    font-family: verdana !important;
    font-weight: bold !important;
    font-size: .8rem !important;
    background-color: white !important;
    border-style: none !important;
  }

  .ui-corner-all:hover {
    margin: 0 !important;
    display: block !important;
    color: black !important;
    padding: 1rem !important;
    padding-left: 1.5rem !important;
    font-weight: bold !important;
    background-color: #f4f4f4 !important;
    border-style: none !important;
    color: #24a47a !important;
  }
}