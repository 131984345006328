#checkout-steps .module h1,
#checkout-steps .module h2,
#checkout-steps .module h3,
#checkout-steps .module h4,
#checkout-steps .module h5 {
    color: black !important;
}

#checkout-steps .page-title {
    text-align: center;
}

#checkout-steps .fieldset label {
    color: black !important;
}

#checkout-steps .selector label {
    color: black !important;
}

#checkout-steps h3.invert {
    color: white;
}

/* NEW */

ol.opc {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: block;
      overflow: auto;
      position: relative;

      &.tab-section .buttons {
          background-color: white;
          padding: 1rem;
      }
    }

    .step {
        background-color: white;
        padding-left: 4rem;
        padding-right: 4rem;
        position: relative;

        @include media-breakpoint-down(sm) {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        @include media-breakpoint-down(xs) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
    }

    .opc-icon {
        position: absolute;
        left: 1.5rem;
        top: 1.5rem;
        z-index: 99;

        img {
            width: 42px;
        }
    }

    div.section {
        padding: 1rem;
    }

    .step-title {
        background-color: white;
        position: relative;
        margin: 0;
        padding: 1.5rem;
        padding-left: 5rem;
        padding-top: 2rem;
        float: none;

        h2 {
            font-size: 120%;
        }
    }
}

.step-link {
	text-align: right;
	padding-bottom: 0;

	div {
		display: inline-block;
		background: #fff;
		padding: 10px 15px;
		font-weight: bold;
		font-size: 16px;

		i {
			font-size: 25px;
			vertical-align: sub;
			margin-right: 5px;
		}
	}
}

#shopping-cart-form {
	.ion-trash-a {
		font-size: 30px;
		vertical-align: sub;
	}

	#applyDiscountButton {
		white-space: nowrap;
		line-height: 1.7rem;
	}

	.form-control {
		line-height: 1.7rem;
	}

	.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #cccccc;
	}

	.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #cccccc;
	}

	.form-control::-ms-input-placeholder { /* Microsoft Edge */
		color: #cccccc;
	}

	@include media-breakpoint-down(xs) {
		input[name="continueshopping"], .removeAllItems {
			width: 100%
		}
	}
}

/*Images*/
.icon-progress-checkout-1 {
	background: url('../images/icon-progress-checkout-1.png');
	background-size: 42px 42px;
	display: inline-block;
	width: 42px;
	height: 42px;

	&.inactive {
		background-image: url('../images/icon-progress-checkout-1-inactive.png');
	}
}

.icon-progress-checkout-2 {
	background: url('../images/icon-progress-checkout-2.png');
	background-size: 42px 42px;
	display: inline-block;
	width: 42px;
	height: 42px;

	&.inactive {
		background-image: url('../images/icon-progress-checkout-2-inactive.png');
	}
}

.icon-progress-checkout-3 {
	background: url('../images/icon-progress-checkout-3.png');
	background-size: 42px 42px;
	display: inline-block;
	width: 42px;
	height: 42px;

	&.inactive {
		background-image: url('../images/icon-progress-checkout-3-inactive.png');
	}
}

.icon-progress-checkout-4 {
	background: url('../images/icon-progress-checkout-4.png');
	background-size: 42px 42px;
	display: inline-block;
	width: 42px;
	height: 42px;

	&.inactive {
		background-image: url('../images/icon-progress-checkout-4-inactive.png');
	}
}

.icon-progress-checkout-5 {
	background: url('../images/icon-progress-checkout-5.png');
	background-size: 42px 42px;
	display: inline-block;
	width: 42px;
	height: 42px;

	&.inactive {
		background-image: url('../images/icon-progress-checkout-5-inactive.png');
	}
}

