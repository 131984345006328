.overlap-columns {
    @include media-breakpoint-up(lg) {
        .overlap-lg-left {
            left: $grid-gutter-width * 3 * -1;
        }

        .overlap-lg-right {
            right: $grid-gutter-width * 3 * -1;
        }
    }
}
