@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Light.eot');
	src: local('Futura PT Light'), local('FuturaPT-Light'), url('../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-Light.woff2') format('woff2'), url('../fonts/FuturaPT-Light.woff') format('woff'), url('../fonts/FuturaPT-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Book.eot');
	src: local('Futura PT Book'), local('FuturaPT-Book'), url('../fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-Book.woff2') format('woff2'), url('../fonts/FuturaPT-Book.woff') format('woff'), url('../fonts/FuturaPT-Book.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Medium.eot');
	src: local('Futura PT Medium'), local('FuturaPT-Medium'), url('../fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-Medium.woff2') format('woff2'), url('../fonts/FuturaPT-Medium.woff') format('woff'), url('../fonts/FuturaPT-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Demi.eot');
	src: local('Futura PT Demi'), local('FuturaPT-Demi'), url('../fonts/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-Demi.woff2') format('woff2'), url('../fonts/FuturaPT-Demi.woff') format('woff'), url('../fonts/FuturaPT-Demi.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Heavy.eot');
	src: local('Futura PT Heavy'), local('FuturaPT-Heavy'), url('../fonts/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-Heavy.woff2') format('woff2'), url('../fonts/FuturaPT-Heavy.woff') format('woff'), url('../fonts/FuturaPT-Heavy.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Bold.eot');
	src: local('Futura PT Bold'), local('FuturaPT-Bold'), url('../fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-Bold.woff2') format('woff2'), url('../fonts/FuturaPT-Bold.woff') format('woff'), url('../fonts/FuturaPT-Bold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-ExtraBold.eot');
	src: local('Futura PT Extra Bold'), local('FuturaPT-ExtraBold'), url('../fonts/FuturaPT-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-ExtraBold.woff2') format('woff2'), url('../fonts/FuturaPT-ExtraBold.woff') format('woff'), url('../fonts/FuturaPT-ExtraBold.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}




@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-LightObl.eot');
	src: local('Futura PT Light Oblique'), local('FuturaPT-LightObl'), url('../fonts/FuturaPT-LightObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-LightObl.woff2') format('woff2'), url('../fonts/FuturaPT-LightObl.woff') format('woff'), url('../fonts/FuturaPT-LightObl.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-BookObl.eot');
	src: local('Futura PT Book Oblique'), local('FuturaPT-BookObl'), url('../fonts/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-BookObl.woff2') format('woff2'), url('../fonts/FuturaPT-BookObl.woff') format('woff'), url('../fonts/FuturaPT-BookObl.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-MediumObl.eot');
	src: local('Futura PT Medium Oblique'), local('FuturaPT-MediumObl'), url('../fonts/FuturaPT-MediumObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-MediumObl.woff2') format('woff2'), url('../fonts/FuturaPT-MediumObl.woff') format('woff'), url('../fonts/FuturaPT-MediumObl.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-DemiObl.eot');
	src: local('Futura PT Demi Oblique'), local('FuturaPT-DemiObl'), url('../fonts/FuturaPT-DemiObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-DemiObl.woff2') format('woff2'), url('../fonts/FuturaPT-DemiObl.woff') format('woff'), url('../fonts/FuturaPT-DemiObl.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-HeavyObl.eot');
	src: local('Futura PT Heavy Oblique'), local('FuturaPT-HeavyObl'), url('../fonts/FuturaPT-HeavyObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-HeavyObl.woff2') format('woff2'), url('../fonts/FuturaPT-HeavyObl.woff') format('woff'), url('../fonts/FuturaPT-HeavyObl.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-BoldObl.eot');
	src: local('Futura PT Bold Oblique'), local('FuturaPT-BoldObl'), url('../fonts/FuturaPT-BoldObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-BoldObl.woff2') format('woff2'), url('../fonts/FuturaPT-BoldObl.woff') format('woff'), url('../fonts/FuturaPT-BoldObl.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-ExtraBoldObl.eot');
	src: local('Futura PT Extra Bold Oblique'), local('FuturaPT-ExtraBoldObl'), url('../fonts/FuturaPT-ExtraBoldObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPT-ExtraBoldObl.woff2') format('woff2'), url('../fonts/FuturaPT-ExtraBoldObl.woff') format('woff'), url('../fonts/FuturaPT-ExtraBoldObl.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT Cond';
	src: url('../fonts/FuturaPTCond-BoldObl.eot');
	src: local('Futura PT Cond Bold Oblique'), local('FuturaPTCond-BoldObl'), url('../fonts/FuturaPTCond-BoldObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-BoldObl.woff2') format('woff2'), url('../fonts/FuturaPTCond-BoldObl.woff') format('woff'), url('../fonts/FuturaPTCond-BoldObl.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT Cond Extra';
	src: url('../fonts/FuturaPTCond-ExtraBoldObl.eot');
	src: local('Futura PT Cond Extra Bold Oblique'), local('FuturaPTCond-ExtraBoldObl'), url('../fonts/FuturaPTCond-ExtraBoldObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-ExtraBoldObl.woff2') format('woff2'), url('../fonts/FuturaPTCond-ExtraBoldObl.woff') format('woff'), url('../fonts/FuturaPTCond-ExtraBoldObl.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT Cond Book';
	src: url('../fonts/FuturaPTCond-Book.eot');
	src: local('Futura PT Cond Book'), local('FuturaPTCond-Book'), url('../fonts/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-Book.woff2') format('woff2'), url('../fonts/FuturaPTCond-Book.woff') format('woff'), url('../fonts/FuturaPTCond-Book.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT Cond';
	src: url('../fonts/FuturaPTCond-Medium.eot');
	src: local('Futura PT Cond Medium'), local('FuturaPTCond-Medium'), url('../fonts/FuturaPTCond-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-Medium.woff2') format('woff2'), url('../fonts/FuturaPTCond-Medium.woff') format('woff'), url('../fonts/FuturaPTCond-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT Cond';
	src: url('../fonts/FuturaPTCond-Bold.eot');
	src: local('Futura PT Cond Bold'), local('FuturaPTCond-Bold'), url('../fonts/FuturaPTCond-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-Bold.woff2') format('woff2'), url('../fonts/FuturaPTCond-Bold.woff') format('woff'), url('../fonts/FuturaPTCond-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT Cond Extra';
	src: url('../fonts/FuturaPTCond-ExtraBold.eot');
	src: local('Futura PT Cond Extra Bold'), local('FuturaPTCond-ExtraBold'), url('../fonts/FuturaPTCond-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-ExtraBold.woff2') format('woff2'), url('../fonts/FuturaPTCond-ExtraBold.woff') format('woff'), url('../fonts/FuturaPTCond-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT Cond Book';
	src: url('../fonts/FuturaPTCond-BookObl.eot');
	src: local('Futura PT Cond Book Oblique'), local('FuturaPTCond-BookObl'), url('../fonts/FuturaPTCond-BookObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-BookObl.woff2') format('woff2'), url('../fonts/FuturaPTCond-BookObl.woff') format('woff'), url('../fonts/FuturaPTCond-BookObl.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}


@font-face {
	font-family: 'Futura PT Cond';
	src: url('../fonts/FuturaPTCond-MediumObl.eot');
	src: local('Futura PT Cond Medium Oblique'), local('FuturaPTCond-MediumObl'), url('../fonts/FuturaPTCond-MediumObl.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaPTCond-MediumObl.woff2') format('woff2'), url('../fonts/FuturaPTCond-MediumObl.woff') format('woff'), url('../fonts/FuturaPTCond-MediumObl.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}




body {
	font-family: 'Futura PT', Verdana, sans-serif;
} 