.product-carousel {
    .slick-list {
        margin: 0 spacers(2) * -1;

        @media (max-width: 1399px) {
            margin: 0 spacers(5);
            overflow: visible;
        }

        @include media-breakpoint-down(xs) {
            margin: 0 spacers(3);
        }
    }

    .slick-slide {
        margin: 0 spacers(2);
    }

    .slick-arrow {
        @include square(3.75rem);
        @include position(absolute, 50%, auto, auto, auto);

        transform: translate(0, -50%);
        background-color: $white;
        text-align: center;
        line-height: 1;
        display: block;
        padding: 0;
        cursor: pointer;
        border: none;
        outline: none;
        z-index: 2;
        font-size: 1.9rem;

        &:hover {
            background-color: theme-color('primary');
            color: $white;
        }

        &.slick-prev{
            left: -6.5rem;
        }

        &.slick-next{
            right: -6.5rem;
        }
    }
}
